import { useEffect, useState, MouseEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, capitalize, Switch, Tooltip, Typography } from "@mui/material";
import { useSearchParams, Outlet } from "react-router-dom";
import { ICourseConfigurationSectionRow, ICourseSection, ICourseSectionState } from "../../../../interfaces/configuration/course-category";
import { courseSectionService } from "../../../../services/configuration/course-catgory";
import useResource from "../../../../hooks/useResource";
import useSnackbar from "../../../../hooks/useSnackbar";
import useDebounce from "../../../../hooks/useDebounce";
import GetActions from "../../../../components/get-actions";
import CustomTypography from "../../../../components/mui/max-length-limit";
import Header from "../../../../components/header";
import CustomTable from "../../../../components/mui/table";
import WarningDialog from "../../../../components/mui/warning-dialog";
import { createIndex } from "../../../../utilities/helper";
import CourseConfigurationSectionFilters from "./filters";
import { IErrorResponse, IPagination } from "../../../../interfaces";
import backArrowRoundIcon from "../../../../assets/images/back-arrow-round.svg";


const CourseConfigurationSectionList = () => {
  let rows: ICourseConfigurationSectionRow[] = [];
  const { resourceAllocate } = useResource();
  const { snackbar } = useSnackbar();
  const { courseId } = useParams();
  const navigate = useNavigate();

  const {
    updateCourseSection,
    deleteCourseSection,
    getCourseSectionList,
    
  } = courseSectionService(); 

  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState<ICourseSectionState>({
    courseSectionConfiguration: "",
    pagination: { page: 1, limit: 20, totalPages: 1 },
    multiDeleteWarning: false,
    selectAll: [],
    filterDialog: { anchorEl: null, isOpen: false },
    filters: {
      search: "",
      _skillCategory: [],
      activeStatus: [],
    },
    deleteWarning: false,
    filterCount: 0,
    searching: "",});

  const columns = [
    {
      id: "id",
      label: "Id",
    },    
    {
      id: "name",
      label: "Name",
    },
    {
      id: "skillCategory",
      label: "Skill Category",
    },
    {
      id: "isActive",
      label: "Active",
    },
    {
      id: "action",
      label: "Action",
    }
  ];

  const searchRecord = useDebounce(state.filters.search, 1000);

  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  const getCourseSectionConfiguration = useQuery({
    queryKey: [
      "CourseConfigurationSectionList",
      state.pagination.page,
      state.searching,
      state.filters,
    ],
    queryFn: () =>
      getCourseSectionList({
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit,
        _course: courseId,
        ...state.filters,
      }),
  });  


  useEffect(() => {
    if (
      getCourseSectionConfiguration.data &&
      getCourseSectionConfiguration.data.data &&
      getCourseSectionConfiguration.data.data.length
    ) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getCourseSectionConfiguration.data.meta.page,
          totalPages: getCourseSectionConfiguration.data.meta.totalPages,
          totalRecords: getCourseSectionConfiguration.data.meta.totalRecords,
        },
      }));
    }
  }, [getCourseSectionConfiguration.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const _skillCategory: { key: string, value: string }[] = searchParams.get("_skillCategory") ? JSON.parse(String(searchParams.get("_skillCategory"))) : [];
    const activeStatus: { key: string, value: string }[] = searchParams.get("activeStatus") ? JSON.parse(String(searchParams.get("activeStatus"))) : [];

    filterCount += _skillCategory.length ? 1 : 0;
    filterCount += activeStatus.length ? 1 : 0;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        _skillCategory: _skillCategory.map(item =>  item.key),
        activeStatus: activeStatus.map(item => item.key),
      },
      filterCount,
    }));
  }, [searchParams]);

  const isActiveChange = async (event: ChangeEvent<HTMLInputElement>, checked: string, _id: string) => {
    try {
      const isActive = await updateCourseSection({ _id, isActive: checked });
      snackbar(isActive.message, "info");
      getCourseSectionConfiguration.refetch();
    } catch(error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  const handleDelete = (courseSectionConfiguration = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      courseSectionConfiguration,
    }));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteCourseSection({
        _id: state.courseSectionConfiguration,
      });
      snackbar(deleted.message, "info");
      handleDelete();
      getCourseSectionConfiguration.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        search: e.target.value,
      },
    }));

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };
  
  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const createRow = (
    index: number,
    courseSectionConfiguration: ICourseSection,
    pagination: IPagination
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: `manage/${courseSectionConfiguration._id}`,
                search: searchParams.toString(),
              }),
            disabled: !resourceAllocate("configuration-course-section.edit"),
          },
          {
            name: "Delete",
            method: () => handleDelete(courseSectionConfiguration?._id),
            disabled: !(resourceAllocate("configuration-course-section.remove"))
          },
        ]}
      />
    );   
    const skillCategory = <Tooltip title={courseSectionConfiguration?._skillCategory?.map(item => item.name).join(", ") || "N/A"}>
    <span>
      {courseSectionConfiguration && courseSectionConfiguration._skillCategory && courseSectionConfiguration._skillCategory.length > 0 ? (
        <>
          {capitalize(courseSectionConfiguration._skillCategory[0].name)}{" "}
          {courseSectionConfiguration._skillCategory.length > 1 && (
            <span style={{ color: "var(--primary-color)" }}>+{courseSectionConfiguration._skillCategory.length - 1}</span>
          )}
        </>
      ) : (
        "N/A"
      )}
    </span>
  </Tooltip>;
  

    return {
      id: createIndex(pagination, index),
      name: 
      <CustomTypography
          limit={50}
          label={courseSectionConfiguration?.name ? capitalize(courseSectionConfiguration?.name || "") : "N/A"}
          color="primary"
          onClick={
            (resourceAllocate("configuration-course-sub-section.read"))
              ? () => {
                  navigate({
                    pathname: `course-sub-section/${courseSectionConfiguration?._id}`,
                    search: searchParams.toString(),
                  });
                }
              : undefined
          }
        />,
      skillCategory: skillCategory, 
      createdBy: courseSectionConfiguration?.createdBy?.name || "N/A",
      isActive: <Switch
          disabled={!resourceAllocate("configuration-course-section.write")}
          onChange={(event ,checked) => isActiveChange(event, String(checked), courseSectionConfiguration._id)}
          checked={!!courseSectionConfiguration.isActive}
        />,
      action,
    };
  };

  useEffect(() => {
    if (getCourseSectionConfiguration.data && getCourseSectionConfiguration.data.data && getCourseSectionConfiguration.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [getCourseSectionConfiguration.data && getCourseSectionConfiguration.data.data]);
  
  if (
    getCourseSectionConfiguration.data &&
    getCourseSectionConfiguration.data.data &&
    getCourseSectionConfiguration.data.data.length
  ) {
    rows =
      getCourseSectionConfiguration.data.data.map((courseSectionConfiguration, i) =>
        createRow(i, courseSectionConfiguration, state.pagination)
      );
  }

  const handleClick = () => {
    navigate("/configurations/course");
  };

  return (
    <>      
      <Box className="header">
        <Box display="flex" gap={2}>
          <img src={backArrowRoundIcon} alt="Back" onClick={handleClick} />
          <Typography variant="h5">
            {capitalize(getCourseSectionConfiguration.data?.meta?.courseName|| "")}
          </Typography>
        </Box>
      </Box>

      <Header
        className="my-2"
        btnText="Add Course Section Configuration"
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        onBtnClick={
          resourceAllocate("configuration-course-section.write")
            ? () =>
                navigate({ pathname: "manage/new", search: searchParams.toString() })
            : undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <CourseConfigurationSectionFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnCloseFilter={closeFilter}
        />
      </Header>

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 250px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Course Section Configuration"
        description="Are you sure you want to delete this course section configuration?"
      />

      <Outlet context={{ reFetch: getCourseSectionConfiguration.refetch }} />
    </>
  );
};
export default CourseConfigurationSectionList;
