import { FC, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { SkillMatrixService } from "../../../../services/skill-matrix";
import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { IErrorResponse } from "../../../../interfaces";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import useSnackbar from "../../../../hooks/useSnackbar";
import { ISkillItemEdit, ISkillItemsFields } from "../../../../interfaces/skill-matrix/action";
import { SkillItemCategoryService } from "../../../../services/skill-matrix/skill-items-categories";
import { capitalize } from "../../../../utilities/helper";
import SearchSelect from "../../../../components/mui/search-select";
import CustomLabel from "../../../../components/mui/custom-label";
import { editSkillItemValidation } from "../../../../validations/skill-matrix/edit-skil-item";
interface IOutletProps {
    reFetch: () => void;
    refetchLable: () => void;
    refetchgetLable:() =>void;
    reFetchSkillItem:() => void;
    reFetchUserSkills: () => void;
    reFetchSkillItemData: () => void;
}

interface ISkillProps {
    labelId: string;
}
const EditLable: FC<ISkillProps> = ({labelId}) => {
    const [searchParam] = useSearchParams();  
    const { getSkillLable, updateSkillLable } = SkillMatrixService();
    const { snackbar } = useSnackbar();
    const outlet = useOutletContext<IOutletProps>();
    const hitQuery = (labelId === "new" || labelId === "view") ? false : true;
    const navigate = useNavigate();
    const getLable = useQuery({ queryKey: ["get-skill-lable"], queryFn: () => getSkillLable({ _id: labelId }), enabled: hitQuery });
    const { handleSubmit, control, setValue, getValues, trigger, formState: { errors } } = useForm<ISkillItemEdit>({
        resolver: joiResolver(editSkillItemValidation),
        defaultValues: {
            name: "",  
            _skillCategory: "",
            employee: 0
        }
    });

    useEffect(() => {
        if ( getLable.data?.data) {
            const skillItem = getLable.data.data;
                setValue("name", skillItem.name);
                setValue("_skillCategory", skillItem._skillCategory && skillItem._skillCategory._id || "");
                setValue("employee", skillItem.employeeCount && skillItem.employeeCount || 0);
            }    
        }
    , [labelId,getLable.data]);
    const onSubmit = async (data: ISkillItemEdit) => {
        try {
            const payload = { ...data, _id: labelId, _skillParam: getLable.data?.data?._skillParam };
            const update = await updateSkillLable(labelId ? labelId : "", payload);
            snackbar(update.message, "info");
            navigate({
                pathname: `/skill-matrix/manage/${getLable.data?.data?._skillParam}`,
                search: searchParam.toString()
            });  
            getLable.refetch();
            outlet?.reFetch && outlet.reFetch();
            outlet.reFetchSkillItemData && outlet.reFetchSkillItemData();
            outlet.reFetchUserSkills && outlet.reFetchUserSkills();     
            
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
            console.log(error);
        }

    };

    const onClose = () => {
        navigate({
            pathname: `/skill-matrix/manage/${getLable.data?.data?._skillParam}`,
            search: searchParam.toString()
        });
    };

    const { getSkillItemCategories } = SkillItemCategoryService();
    const skillItemCategories = useQuery({
        queryKey: ["all-skill-items-categories"],
        queryFn: () => getSkillItemCategories({
        pagination: false,
        _skillParameter: getLable.data?.data?._skillParam, 
        })
    });

    const fields: ISkillItemsFields[] = [
        {
            label: "Name",
            name: "name",
            type: "input",
            placeholder: "Enter Skill Name",
            required: true
        },
        {
            label: "Category",
            name: "_skillCategory",
            type: "select",
            required: true,
            options: skillItemCategories?.data?.data.map((i) => ({
                key: i._id,
                value: capitalize(i.name)
            })),
            displayFieldKey: "value",
            storeFieldKey: "key",
        },
        {
            label: "Employee",
            name: "employee",
            type: "input"
        },
    ];

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4} mt={1}>
                {
                    fields.map(field => {
                        if (field.type === "input") {  
                            return (<Grid key={field.label} item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={field.name}
                                    render={(prop) => <TextField
                                        label={<CustomLabel label={field.label} required={field?.required} />}
                                        className="disable-text"
                                        variant="outlined"
                                        disabled={!!(field.name === "employee")}
                                        size="small"
                                        placeholder={field.placeholder}
                                        error={!!errors[field.name]}
                                        helperText={errors[field.name]?.message}
                                        {...prop.field}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                                if (newValue.length <= 25) { 
                                                    setValue("name", newValue);
                                                    prop.field.onChange(e);
                                                }
                                            }}
                                    />}
                                />
                            </Grid>
                            );
                        }else {
                            return (<Grid key={field.label} item xs={12} md={6}>
                                <SearchSelect
                                    name={field.name}
                                    label={<CustomLabel label={field.label} required={field?.required} />}
                                    error={!!errors[field.name]}
                                    helperText={errors[field.name]?.message}
                                    options={field.options}
                                    disabled={field.disabled}
                                    displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                                    storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                                    displayUserName={field.displayUserName}
                                    capitalize={field.capitalize}
                                    keyUpperCase={field.keyUpperCase}
                                    trigger={trigger}
                                    setValue={setValue}
                                    getValues={getValues}
                                />
                                </Grid>
                                );
                        }
                    })
                }
            </Grid>
                <Box className="action-box">
                    <Divider sx={{ marginBottom: "20px" }} />
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </Box>
            </form>
        </Box>
    );
};  

export default EditLable;