export const CMS_ERROR_MESSAGES = {
    en: {
        select_all_checkboxes: "Please select all checkboxes",
        fill_basic_details: "Please add basic details first.",
        invalid_json: "Invalid JSON format. Please check your input for table data.",
        schedule_in_past: "Alternative cannot scheduled be in the past. Please select a current or future time",
        duplicate_slug: "Please enter a unique slug. This slug exists in any other glossary.",
        empty_comment_text: "Comment text cannot be empty",
        empty_reply_text: "Reply text cannot be empty",
    }
};