import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { capitalize, createIndex, formatDateTime } from "../../../utilities/helper";
import { IRole, IRoleRow, IRoleState } from "../../../interfaces";
import { IPagination } from "../../../interfaces";
import { RoleService } from "../../../services";
import Header from "../../../components/header";
import useSnackbar from "../../../hooks/useSnackbar";
import CustomTable from "../../../components/mui/table";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import useDebounce from "../../../hooks/useDebounce";
import useResource from "../../../hooks/useResource";

const Role = () => {
  let rows: IRoleRow[] = [];
  const { snackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getRoles, deleteRole } = RoleService();
  const { resourceAllocate } = useResource();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<IRoleState>({
    deleteWarning: false,
    _team: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1
    },
    filters: {
      search: ""
    }
  });

  const teams = useQuery({
    queryKey: ["allRoles", state.pagination.page, state.filters],
    queryFn: () => getRoles({
      pagination: true, limit: state.pagination.limit, page: state.pagination.page, ...state.filters
    })
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams(prev => ({
        ...prev,
        page: 1,
        search: searchRecord
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  useEffect(() => {
    if (teams.data?.data.length) {
      setState(prevState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: teams.data.meta.page,
          totalPages: teams.data.meta.totalPages,
          totalRecords: teams.data.meta.totalRecords
        }
      }));
    }
  }, [teams.data?.meta]);


  useEffect(() => {
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const search = searchParams.get("search") ? String(searchParams.get("search")) : "";

    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      },
      filters: {
        ...prevState.filters,
        search
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleDelete = (_team = "") => {
    setState(prevState => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _team
    }
    ));
  };

  useEffect(() => {
    if (teams.data && teams.data.data && teams.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [teams.data && teams.data.data]);

  const onDelete = async () => {
    try {
      const deleted = await deleteRole({ _id: state._team });
      snackbar(deleted.message, "info");
      handleDelete();
      teams.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      id: "id",
      label: "S No."
    },
    {
      id: "role_title",
      label: "Title"
    },
    {
      id: "department",
      label: "Department"
    },
    {
      id: "role_createdBy",
      label: "Created By"
    },
    {
      id: "role_createdAt",
      label: "Created At"
    },
    {
      id: "action",
      label: "Actions"
    },
  ];

  const createRow = (index: number, role: IRole, pagination: IPagination) => {
    const action = <GetActions
      icons={[
        { name: "Edit", method: () => navigate({ pathname: "" + role._id, search: searchParams.toString() }), disabled: !resourceAllocate("user-role.write")},
        { name: "Delete", method: () => handleDelete(role._id), disabled: !resourceAllocate("user-role.remove") || role.isDefault },
      ]}
    />;

    return {
      id: createIndex(pagination, index),
      role_title: capitalize(role.name),
      department: role._department && role?._department.name ? capitalize(role._department.name) : "",
      role_createdBy: role?.createdBy?.name && capitalize(role?.createdBy?.name),
      role_createdAt: formatDateTime(role?.createdAt),
      action,
    };
  };
  if (teams.data?.data.length) {
    rows = teams.data?.data.map((team, i) => createRow(i, team, state.pagination));
  }
  return (
    <>
      {/* Add Data  */}
      <Header
        className='my-2'
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        btnText="Add Role"
        onBtnClick={resourceAllocate("user-role.write") ? () => navigate("new"): undefined}
      />

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 248px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Role"
        description="Are you sure you want to delete this role?"
      />
      <Outlet context={{ reFetch: teams.refetch }} />
    </>
  );
};
export default Role;

