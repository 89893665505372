import "../../../../components/shared/filter/style.scss";
import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import { IBlogFilterState, IProjectData, IUser } from "../../../../interfaces";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { filterInitialState } from "../common/helper";
import { MenuItem } from "../../../../components/shared/filter";
import { useQuery } from "@tanstack/react-query";
import CategoryService from "../../../../services/content/category";
import SubCategoryService from "../../../../services/content/sub-category";
import plannerService from "../../../../services/planner";
import { useSelector } from "react-redux";
import { capitalize } from "../../../../utilities/helper";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const BlogFilters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const location = useLocation();
    let path = location.pathname;
    path = path.split("/")[1];
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getContentCategorys } = CategoryService();
    const { getContentSubCategorys } = SubCategoryService();
    const { getPlannerList} = plannerService();
    const statusList = [
        { value: "Draft", key: "DRAFT" },
        { value: "In Writing", key: "IN_WRITING" },
        { value: "In Review", key: "IN_REVIEW" },
        { value: "In Publishing", key: "IN_PUBLISHING" },
        { value: "Re-Assign", key: "REASSIGN" },
        { value: "Scheduled", key: "SCHEDULED"},
        { value: "Completed", key: "COMPLETED" },
    ];
    const types = [
        { value: "Alternative", key: "ALTERNATIVE" },
        { value: "Comparison", key: "COMPARISON" }
    ];
    const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);

    const writers = users.filter(user => user && user._role && ((user._role.name === "writer" && user._role.isDefault) || (user._role.name === "reviewer" && user._role.isDefault) || (user._role.name === "content admin" )));
    const reviewers = users.filter(user => user && user._role && ((user._role.name === "reviewer" && user._role.isDefault) || (user._role.name === "content admin" )));
    const designers = users.filter(user => user && user._role && user._role.name === "illustrator" && user._role.isDefault);
    const publishers = users.filter(user => user && user._role && user._role.name === "publisher" && user._role.isDefault);


    const productList = useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>((state) => state.cmsProduct.list) || [];
    const getCmsCategory = useQuery({ queryKey: ["allCmsCategories"], queryFn: () => getContentCategorys({}) });
    const getCmsSubCategory = useQuery({ queryKey: ["allCmsSubcategories"], queryFn: () => getContentSubCategorys({}) });
    const getCmsQuarter = useQuery({ queryKey: ["allCmsQuarter"], queryFn: () => getPlannerList({}) });
    
    const categoryList = getCmsCategory?.data?.data || [];
    const subCategoryList = getCmsSubCategory?.data?.data || [];
    const quarterList = getCmsQuarter?.data?.data || [];

    const [state, setState] = useState<IBlogFilterState>({ ...filterInitialState });

    useEffect(() => {
        resetFilter();
    }, []);

    useEffect(() => {
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
        const product: { key: string, value: string }[] = searchParams.get("product") ? JSON.parse(String(searchParams.get("product"))) : [];
        const subCategory: { key: string, value: string }[] = searchParams.get("subCategory") ? JSON.parse(String(searchParams.get("subCategory"))) : [];
        const quarter: { key: string, value: string }[] = searchParams.get("quarter") ? JSON.parse(String(searchParams.get("quarter"))) : [];
        const week: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("week") ? JSON.parse(String(searchParams.get("week"))) : [];
        const writers: { key: string, value: string }[] = searchParams.get("writers") ? JSON.parse(String(searchParams.get("writers"))) : [];
        const reviewers: { key: string, value: string }[] = searchParams.get("reviewers") ? JSON.parse(String(searchParams.get("reviewers"))) : [];
        const illustrators: { key: string, value: string }[] = searchParams.get("illustrators") ? JSON.parse(String(searchParams.get("illustrators"))) : [];
        const publishers: { key: string, value: string }[] = searchParams.get("publishers") ? JSON.parse(String(searchParams.get("publishers"))) : [];
        const type: { key: string, value: string }[] = searchParams.get("alternativeType") ? JSON.parse(String(searchParams.get("alternativeType"))) : [];

        setState(prevState => ({
            ...prevState,
            status,
            category,
            product,
            subCategory,
            quarter,
            week,
            writers,
            reviewers,
            illustrators,
            publishers,
            type
        }));
    }, [searchParams]);

    const handleDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
        const newDoj = e ? dayjs(e).toISOString() : "";
        let week: Array<{
            key: string;
            value: string;
            startDate: string,
            endDate: string
        }> = [];

        if (state.week.length && period === "start") {
            week = state.week.map(e => ({ ...e, startDate: newDoj, endDate: dayjs(newDoj).add(7, "day").toISOString(), value: "custom", key: "Custom" }));
        } else if (state.week.length && period === "end") {
            week = state.week.map(e => ({ ...e, endDate: dayjs(newDoj).add(7, "day").toISOString(), value: "custom", key: "Custom" }));
        } else if (!state.week.length && period === "start") {
            week = [{
                key: "custom",
                value: "custom",
                startDate: dayjs(e).startOf("day").toISOString(),
                endDate: dayjs(e).add(6, "day").endOf("day").toISOString()
            }];
        } else {
            week = [{
                key: "custom",
                value: "custom",
                startDate: new Date().toISOString(),
                endDate: newDoj
            }];
        }
      setState(prevState => ({
        ...prevState,
        week
      }));
    };

    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };


    const handleRightListItem = (name: "status" | "category" | "product" | "subCategory" | "quarter" | "writers" | "reviewers" | "illustrators" | "publishers" | "week" | "type", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];
        const isExist = state[name].find(ele => ele.key === key) ? true : false;
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }
        if(name === "product") {
            setState(prevState => ({
              ...prevState,
              category: [],
              subCategory: []
            }));
        }else if(name === "category") {
            setState(prevState => ({
                ...prevState,
                subCategory: []
            }));
        }
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name: "status" | "category" | "subCategory" | "product" | "quarter" | "writers" | "reviewers" | "illustrators" | "publishers" | "week" | "type", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };


    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            category: [],
            status: [],
            product: [],
            subCategory: [],
            quarter: [],
            week : [],
            writers: [],
            reviewers: [],
            illustrators: [],
            publishers: [],
            type: []
        });
    };

    const onApply = () => {
        searchParams.set("category", JSON.stringify(state.category));
        searchParams.set("status", JSON.stringify(state.status));
        searchParams.set("product", JSON.stringify(state.product));
        searchParams.set("subCategory", JSON.stringify(state.subCategory));
        searchParams.set("quarter", JSON.stringify(state.quarter));
        searchParams.set("week", JSON.stringify(state.week));
        searchParams.set("writers", JSON.stringify(state.writers));
        searchParams.set("reviewers", JSON.stringify(state.reviewers));
        searchParams.set("illustrators", JSON.stringify(state.illustrators));
        searchParams.set("publishers", JSON.stringify(state.publishers));
        searchParams.set("alternativeType", JSON.stringify(state.type));

        searchParams.set("page", "1");
        navigate({
            pathname: `/${path}`,
            search: searchParams.toString()
        });
        OnClose();
    };

    const onClose = () => {
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
        const product: { key: string, value: string }[] = searchParams.get("product") ? JSON.parse(String(searchParams.get("product"))) : [];
        const subCategory: { key: string, value: string }[] = searchParams.get("subCategory") ? JSON.parse(String(searchParams.get("subCategory"))) : [];
        const quarter: { key: string, value: string }[] = searchParams.get("quarter") ? JSON.parse(String(searchParams.get("quarter"))) : [];
        const week: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("week") ? JSON.parse(String(searchParams.get("week"))) : [];
        const writers: { key: string, value: string }[] = searchParams.get("writers") ? JSON.parse(String(searchParams.get("writers"))) : [];
        const reviewers: { key: string, value: string }[] = searchParams.get("reviewers") ? JSON.parse(String(searchParams.get("reviewers"))) : [];
        const illustrators: { key: string, value: string }[] = searchParams.get("illustrators") ? JSON.parse(String(searchParams.get("illustrators"))) : [];
        const publishers: { key: string, value: string }[] = searchParams.get("publishers") ? JSON.parse(String(searchParams.get("publishers"))) : [];
        const type: { key: string, value: string }[] = searchParams.get("alternativeType") ? JSON.parse(String(searchParams.get("alternativeType"))) : [];
        setState(prevState => ({
            ...prevState,
            status,
            category,
            product,
            subCategory,
            quarter,
            week,
            writers,
            reviewers,
            illustrators,
            publishers,
            type
        }));
        OnClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {(
                            state.status.length ||
                            state.subCategory.length ||
                            state.quarter.length ||
                            state.product.length ||
                            state.category.length ||
                            state.writers.length ||
                            state.reviewers.length ||
                            state.illustrators.length ||
                            state.publishers.length || 
                            state.week.length ||
                            state.type.length) 
                            ?
                            <>

                                {state.category.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("category", ele.key)} label={ele.value} variant="outlined" />)}
                                {state.status.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("status", ele.key)} label={ele.value} variant="outlined" />)}
                                {state.subCategory.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("subCategory", ele.key)} label={ele.value} variant="outlined" />)}
                                {state.product.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("product", ele.key)} label={ele.value} variant="outlined" />)}
                                {state.quarter.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("quarter", ele.key)} label={ele.key} variant="outlined" />)}
                                {state.writers.map(ele => <Chip key={capitalize(ele.key)} className="m-1" color="primary" onDelete={() => deleteChip("writers", ele.key)} label={ele.value} variant="outlined" />)}
                                {state.reviewers.map(ele => <Chip key={capitalize(ele.key)} className="m-1" color="primary" onDelete={() => deleteChip("reviewers", ele.key)} label={ele.value} variant="outlined" />)}
                                {state.illustrators.map(ele => <Chip key={capitalize(ele.key)} className="m-1" color="primary" onDelete={() => deleteChip("illustrators", ele.key)} label={ele.value} variant="outlined" />)}
                                {state.publishers.map(ele => <Chip key={capitalize(ele.key)} className="m-1" color="primary" onDelete={() => deleteChip("publishers", ele.key)} label={ele.value} variant="outlined" />)}
                                {state.type.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("type", ele.key)} label={ele.value} variant="outlined" />)}
                                {state.week.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("week", ele.key)} label={ele.value} variant="outlined" />)}
                            </>
                            :
                            <Box className="mt-2" display="flex" alignItems="center">
                                <FilterAltOffIcon />
                                <Typography className="ml-2">No filters are applied</Typography>
                            </Box>
                        }
                    </div>
                    <IconButton 
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">
                            {
                                path === "comparison" && <MenuItem
                                    index={10}
                                    label="Type"
                                    selectedMenu={state.selectedMenu === 10}
                                    onChange={handleLeftListItem}
                                    count={state.type}
                                />
                            }
                            <MenuItem
                                index={0}
                                label="Product"
                                selectedMenu={state.selectedMenu === 0}
                                onChange={handleLeftListItem}
                                count={state.product}
                            />
                            <MenuItem
                                index={1}
                                label="Status"
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                count={state.status}
                            />
                            { 
                                path !== "comparison" && path !== "glossary" && <MenuItem
                                    index={2}
                                    label="Category"
                                    selectedMenu={state.selectedMenu === 2}
                                    onChange={handleLeftListItem}
                                    count={state.category}
                                />
                            }
                            { 
                                path !== "comparison"  && path !== "glossary" && <MenuItem
                                    index={3}
                                    label="Subcategory"
                                    selectedMenu={state.selectedMenu === 3}
                                    onChange={handleLeftListItem}
                                    count={state.subCategory}
                                />
                            }
                            <MenuItem
                                index={4}
                                label="Quarter"
                                selectedMenu={state.selectedMenu === 4}
                                onChange={handleLeftListItem}
                                count={state.quarter}
                            />
                            <MenuItem
                                index={5}
                                label="Writer"
                                selectedMenu={state.selectedMenu === 5}
                                onChange={handleLeftListItem}
                                count={state.writers}
                            />
                             <MenuItem
                                index={6}
                                label="Reviewer"
                                selectedMenu={state.selectedMenu === 6}
                                onChange={handleLeftListItem}
                                count={state.reviewers}
                            />
                            <MenuItem
                                index={7}
                                label="Illustrator"
                                selectedMenu={state.selectedMenu === 7}
                                onChange={handleLeftListItem}
                                count={state.illustrators}
                            />
                            <MenuItem
                                index={8}
                                label="Publisher"
                                selectedMenu={state.selectedMenu === 8}
                                onChange={handleLeftListItem}
                                count={state.publishers}
                            />
                             <MenuItem
                                index={9}
                                label="Week"
                                selectedMenu={state.selectedMenu === 9}
                                onChange={handleLeftListItem}
                                count={state.week}
                            />
                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                productList.filter((data) => data.isActive).map((product) => <ListItemButton
                                key={product?._id}
                                selected={!!state.product.find(ele => ele.key === product._id)}
                                onClick={() => handleRightListItem("product", product._id, product.name)}
                                >
                                    <ListItemText primary={product.name} />
                                    <Checkbox edge="end" checked={!!state.product.find(ele => ele.key === product._id)} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 1 &&
                                statusList.map((data, index) =>
                                    <ListItemButton
                                        key={index}
                                        selected={!!state.status.find((ele) => ele.key === data.key)}
                                        onClick={() => handleRightListItem("status", data.key, data.value)}
                                    >
                                        <ListItemText primary={data.value} />
                                        <Checkbox edge="end" checked={!!state.status.find((ele) => ele.key === data.key)} />
                                    </ListItemButton>
                                )
                            }
                            {
                                state.selectedMenu === 2 &&
                                categoryList
                                  .filter(cat => 
                                    !state.product.length || state.product.some(product => product.key === (cat && cat._productId && cat._productId._id))
                                  ).map(category => <ListItemButton
                                    key={category?._id}
                                    selected={!!state.category.find(ele => ele.key === category._id)}
                                    onClick={() => handleRightListItem("category", category._id, category.name)}
                                >
                                    <ListItemText primary={category.name} />
                                    <Checkbox edge="end" checked={!!state.category.find(ele => ele.key === category._id)} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 3 &&
                                subCategoryList
                                .filter(cat => 
                                    !state.category.length || state.category.some(category => category.key === cat.categoryId._id)
                                  ).map((data) => <ListItemButton
                                    key={data?._id}
                                    selected={!!state.subCategory.find(ele => ele.key === data._id)}
                                    onClick={() => handleRightListItem("subCategory", data._id, data.name)}
                                >
                                    <ListItemText primary={data.name} />
                                    <Checkbox edge="end" checked={!!state.subCategory.find(ele => ele.key === data._id)} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 4 &&
                                quarterList.map((data) => <ListItemButton
                                    key={data?._id}
                                    selected={!!state.quarter.find(ele => ele.key === data.name)}
                                    onClick={() => handleRightListItem("quarter", data.name, data._id)}
                                >
                                    <ListItemText primary={data.name} />
                                    <Checkbox edge="end" checked={!!state.quarter.find(ele => ele.key === data.name)} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 5 &&
                                writers.map((data) => <ListItemButton
                                    key={data?._id}
                                    selected={!!state.writers.find(ele => ele.key === data._id)}
                                    onClick={() => handleRightListItem("writers", data._id, data.name)}
                                >
                                    <ListItemText primary={capitalize(data.name)} />
                                    <Checkbox edge="end" checked={!!state.writers.find(ele => ele.key === data._id)} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 6 &&
                                reviewers.map((data) => <ListItemButton
                                    key={data?._id}
                                    selected={!!state.reviewers.find(ele => ele.key === data._id)}
                                    onClick={() => handleRightListItem("reviewers", data._id, data.name)}
                                >
                                    <ListItemText primary={capitalize(data.name)} />
                                    <Checkbox edge="end" checked={!!state.reviewers.find(ele => ele.key === data._id)} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 7 &&
                                designers.map((data) => <ListItemButton
                                    key={data?._id}
                                    selected={!!state.illustrators.find(ele => ele.key === data._id)}
                                    onClick={() => handleRightListItem("illustrators", data._id, data.name)}
                                >
                                    <ListItemText primary={capitalize(data.name)} />
                                    <Checkbox edge="end" checked={!!state.illustrators.find(ele => ele.key === data._id)} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 8 &&
                                publishers.map((data) => <ListItemButton
                                    key={data?._id}
                                    selected={!!state.publishers.find(ele => ele.key === data._id)}
                                    onClick={() => handleRightListItem("publishers", data._id, data.name)}
                                >
                                    <ListItemText primary={capitalize(data.name)} />
                                    <Checkbox edge="end" checked={!!state.publishers.find(ele => ele.key === data._id)} />
                                </ListItemButton>)
                            }
                           
                           {
                                state.selectedMenu === 9 &&
                                <>
                                    <Box>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                value={state.week[0]?.startDate ? dayjs(state.week[0].startDate) : null}
                                                onChange={e => handleDate(e, "start")}
                                                label="Select Week"
                                                format="LL"
                                                shouldDisableDate={(date)=> date.day() !== 0}
                                            />
                                        </LocalizationProvider>
                                        <div className="mt-3" />
                                    </Box>
                                </>
                            }
                            {
                                state.selectedMenu === 10 &&
                                types.map((data, index) =>
                                    <ListItemButton
                                        key={index}
                                        selected={!!state.status.find((ele) => ele.key === data.key)}
                                        onClick={() => handleRightListItem("type", data.key, data.value)}
                                    >
                                        <ListItemText primary={data.value} />
                                        <Checkbox edge="end" checked={!!state.type.find((ele) => ele.key === data.key)} />
                                    </ListItemButton>
                                )
                            }
                        </List>
                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default BlogFilters;