import "./style.scss";
import { ChangeEvent, useState } from "react";
import Pagination from "@mui/material/Pagination";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  styled,
  TableCell,
  tableCellClasses,
  Box,
  Button,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
  useTheme
} from "@mui/material";
import ErrorMessage from "../../shared/error-message";


export interface IColumn {
  sortable?: boolean;
  id: string;
  label: string | JSX.Element;
  minWidth?: number;
  maxWidth?: number;
  whiteSpace?: "nowrap" | "normal";
  align?: "right";
  format?: (value: number) => string;
}

interface props<T> {
  columns: IColumn[];
  rows: T[];
  isDND?: boolean;
  draggableId?: string;
  onDrag?: (result: DropResult) => void;
  height?: number | string;
  maxHeight?: number | string;
  width?: number | string;
  errorMessage?: string;
  pagination?: {
    page: number;
    totalPages: number;
  };
  onSortChange?: (columnId: string, direction: "asc" | "desc" | "none") => void; 
  onPageChange?: (e: ChangeEvent<unknown>, page: number) => void;
  showPagination?: boolean;
  showLoadMore?: () => void;
  limitPerPage?: boolean;
  onLimitChange?: (e: SelectChangeEvent<string>) => void;
  limitValue?: string;
  showTotal?: boolean;
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    fontFamily: "var(--font-family)",
    fontWeight: 600,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "var(--font-family)",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomTable = <T,>({
  columns,
  rows,
  height,
  width,
  pagination,
  onSortChange,
  onPageChange,
  errorMessage,
  isDND,
  draggableId,
  onDrag,
  showPagination = true,
  showLoadMore,
  limitPerPage,
  onLimitChange,
  limitValue, 
  showTotal,
  maxHeight
}: props<T>) => {

const theme = useTheme(); 
const [sortState, setSortState] = useState<{ columnId: string | null; direction: "asc" | "desc" | "none" }>({
  columnId: null,
  direction: "none",
});

const handleSort = (key: string, direction: "asc" | "desc" | "none") => {
  if (sortState.columnId === key && sortState.direction === direction) {
    setSortState({ columnId: null, direction: "none" });
    if (onSortChange) {
      onSortChange(key, "none");
    }
  } else {
    setSortState({ columnId: key, direction });
    if (onSortChange) {
      onSortChange(key, direction);
    }
  }
};
  return(
  <>
    <TableContainer
      className="table-container"
      sx={{  height, width, maxHeight }}
    >
      <Table stickyHeader aria-label="sticky table" className="table">
        <TableHead className="table-head">
          <TableRow>
            {columns.map((column: IColumn, index: number) => (
               <StyledTableCell
               theme={theme}
               key={index}
               align={column.align}
               style={{
                 whiteSpace: "nowrap",
                 minWidth: column.minWidth,
                 maxWidth: column.maxWidth,
                 cursor: column.sortable ? "pointer" : "default",
               }}
             >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={column.align === "right" ? "flex-end" : "flex-start"}
                >
                  {column.label}
                  {column.sortable && (
                    <Box className="sortable-column">
                      <Box
                        component="span"
                        className={`sort-arrow asc ${sortState.columnId === column.id && sortState.direction === "desc" ? "active" : ""
                          }`}
                        onClick={() => handleSort(column.id, "desc")}
                      >
                        ▲
                      </Box>
                      <Box
                        component="span"
                        className={`sort-arrow desc ${sortState.columnId === column.id && sortState.direction === "asc" ? "active" : ""
                          }`}
                        onClick={() => handleSort(column.id, "asc")}
                      >
                        ▼
                      </Box>
                    </Box>
                  )}
                </Box>
             </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        {isDND ? (
          <DragDropContext onDragEnd={(e) => onDrag && onDrag(e)}>
            <Droppable droppableId={"table-drop"}>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {rows
                    // eslint-disable-next-line
                    .map((row: any, index: number) => (
                      <Draggable
                        key={index.toString()}
                        draggableId={String(index)}
                        index={index}
                      >
                        {(provided, snapshot) => {
                          const style = {
                            ...provided.draggableProps.style,
                            // backgroundColor: snapshot.isDragging ? "blue" : "white",
                            boxShadow: snapshot.isDragging
                              ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                              : "",
                          };
                          return (
                            <StyledTableRow
                              role="checkbox"
                              tabIndex={-1}
                              {...provided.draggableProps}
                              style={style}
                              ref={provided.innerRef}
                            >
                              {columns.map((column: IColumn, i: number) => {
                                const value = row[column.id];
                                return column.id === draggableId ? (
                                  <TableCell
                                    style={{
                                      whiteSpace: "nowrap",
                                      maxWidth: column.maxWidth,
                                    }}
                                    align={column.align}
                                    key={String(i)}
                                    {...provided.dragHandleProps}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    style={{
                                      whiteSpace: column.whiteSpace
                                        ? column.whiteSpace
                                        : "nowrap",
                                      maxWidth: column.maxWidth,
                                    }}
                                    align={column.align}
                                    key={String(i)}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </StyledTableRow>
                          );
                        }}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
        <TableBody>
          {rows
            // eslint-disable-next-line
            .map((row: any, index: number) => {
            const isLastRow = index === rows.length - 1;
            return (
              <StyledTableRow
                role="checkbox"
                tabIndex={-1}
                key={index}
              >
                {columns.map((column: IColumn, i: number) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      style={{
                        whiteSpace: column.whiteSpace
                          ? column.whiteSpace
                          : "nowrap",
                        maxWidth: column.maxWidth,
                        wordBreak: "break-all",
                        fontWeight: showTotal && isLastRow ? "bold" : "normal",
                      }}
                      key={i}
                      align={column.align}
                    >
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>

        )}
      </Table>
      {showLoadMore && (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{
              background: "#F2F2F2",
              color: "#333333",
              transition: "none",
              "&:hover": {
                backgroundColor: "inherit",
                color: "inherit",
              },
            }}
          >
            Load More
          </Button>
        </span>
      )}
      {!rows?.length && <ErrorMessage errorMessage={errorMessage} />}
    </TableContainer>
    {showPagination && rows.length > 0 && pagination?.page && (
      <Box className="pagination-box">
        <Box className="pages-box">
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            onChange={onPageChange}
            color="primary"
          />
        </Box>
        {limitPerPage &&
          <Box className="limit-box">
                <Box className="limit-box-select">
                  <Select
                      size="small"
                      variant="outlined"
                      onChange={onLimitChange}
                      fullWidth={true}
                      value={limitValue}
                  >
                      <MenuItem disabled value="none">Select</MenuItem>
                      <MenuItem key="10" value="10">10</MenuItem>
                      <MenuItem key="20" value="20">20</MenuItem>
                      <MenuItem key="50" value="50">50</MenuItem>
                      <MenuItem key="100" value="100">100</MenuItem>
                  </Select>
                </Box>
                <Box className="limit-box-text">
                  <Typography variant="body1">Entries per page</Typography>
                </Box>
          </Box>
        }
      </Box>
    )}
  </>
);
};

export default CustomTable;