import { Box, FormControl, MenuItem, Select, Switch, Tooltip, Typography } from "@mui/material";
import { useEffect, useState, MouseEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import {
  capitalize,
  createIndex,
  formatDateTime,
} from "../../../../utilities/helper";
import { IPagination } from "../../../../interfaces";
import Header from "../../../../components/header";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomTable from "../../../../components/mui/table";
import GetActions from "../../../../components/get-actions";
import WarningDialog from "../../../../components/mui/warning-dialog";
import useDebounce from "../../../../hooks/useDebounce";
import {
  ITestRow,
  ITestState,
} from "../../../../interfaces/configuration/tests";
import { TestsService } from "../../../../services/configuration/tests";
import CustomTypography from "../../../../components/mui/max-length-limit";
import useResource from "../../../../hooks/useResource";
import { ITest } from "../../../../interfaces/configuration/tests";
import Filters from "./filters";

interface outletProps {
  refetchTests: () => void;
}
const Tests = () => {
  let rows: ITestRow[] = [];
  const { snackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getTests, deleteTest,cloneTest, updateTestStatus } = TestsService();
  const navigate = useNavigate();
  const outlet = useOutletContext<outletProps>();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [state, setState] = useState<ITestState>({
    deleteWarning: false,
    cloneWarning: false,
    _test: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false 
    },
    filters: {
      search: "",
      status: [],
      createdAt: {
        startDate: "",
        endDate: ""
      }
    },
    filterCount: 0
  });
  const tests = useQuery({
    queryKey: ["allTests", state.pagination.page, state.filters],
    queryFn: () =>
      getTests({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page,
        search: state.filters.search,
        status: state.filters.status,
        startDate: state.filters.createdAt?.startDate,
        endDate: state.filters.createdAt?.endDate 
      }),
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    searchRecord.length
      ? setSearchParams((prev) => ({
          ...prev,
          page: 1,
          search: searchRecord,
        }))
      : (searchParams.delete("search"), setSearchParams(searchParams));
  }, [searchRecord]);

  useEffect(() => {
    tests.data &&
      tests.data.data &&
      tests.data.data.length &&
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: tests.data.meta.page,
          totalPages: tests.data.meta.totalPages,
          totalRecords: tests.data.meta.totalRecords,
        },
      }));
  }, [tests.data && tests.data.data && tests.data.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
    const date: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("date") ? JSON.parse(String(searchParams.get("date"))) : [];
    filterCount += status.length ? 1 : 0;
    filterCount += date.length ? 1 : 0;

    let createdAt: {
      startDate: string;
      endDate: string;
    } | undefined = undefined;
    if (date?.length) {
      createdAt = {
        startDate: date[0]?.startDate,
        endDate: date[0]?.endDate,
      };
    }

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        status: status.map(status => status.key),
        createdAt,
      },
      filterCount
    }));
  }, [searchParams]);


  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const handleDelete = (_test = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _test,
    }));
  };

  const handleClone = (_test = "") => {
    setState((prevState) => ({
      ...prevState,
      cloneWarning: !prevState.cloneWarning,
      _test,
    }));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteTest({ _id: state._test });
      snackbar(deleted.message, "info");
      handleDelete();
      tests.refetch();
      outlet.refetchTests && outlet.refetchTests();
    } catch (error) {
      snackbar("Internal Server Error", "error");
    }
  };

  const onClone = async () => {
    try {
      const data = await cloneTest({ _id: state._test });
      snackbar(data.message, "info");
      handleClone();
      tests.refetch();
    } catch (error) {
      snackbar("Internal Server Error", "error");
    }
  };
  const onStatusUpdate = async (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    id: string
  ) => {
    const status = checked ? "ARCHIVED" : "UNARCHIVED";
    try {
      const test = await updateTestStatus({ _id: id, status });
      snackbar(test.message, "info");
      tests.refetch();
    } catch (error) {
      console.log("Error in user status update", error);
    }
  };

  useEffect(() => {
    if (tests.data && tests.data.data && tests.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [tests.data && tests.data.data]);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) => setState(prevState => ({
    ...prevState,
    filterDialog: {
      ...prevState.filterDialog,
      anchorEl: e.currentTarget,
      isOpen: !state.filterDialog.isOpen
    }
  }));

  const closeFilter = () => {
    setState(prevState => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false
      }
    }));
  };

  const columns = [
    {
      id: "id",
      label: "S No.",
    },
    {
      id: "test_title",
      label: "Name",
    },
    {
      id: "test_description",
      label: "Description",
    },
    {
      id: "test_display_name",
      label: "Display Name"
    },
    {
      id: "test_no_of_ques",
      label: "Questions",
    },
    {
      id: "test_category",
      label: "Category",
    },
    {
      id: "test_duration",
      label: "Duration(mins)",
    },
    {
      id: "test_createdAt",
      label: "Created At",
    },
    {
      id: "test_createdBy",
      label: "Created By",
    },
    {
      id: "test_status",
      label: "Status",
    },
    {
      id: "test_archived",
      label: "Archived",
    },
    {
      id: "action",
      label: "Actions",
    },
  ];
  const createRow = (index: number, test: ITest, pagination: IPagination) => {
    const action = (
      <GetActions
        icons={[
          { name: "Copy", method: () => handleClone(test._id), disabled: !(resourceAllocate("test.write") && test.status!=="PENDING") },
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: "" + test._id,
                search: searchParams.toString(),
              }),
            disabled: resourceAllocate("test.write") && test.status !== "ARCHIVED" && test.status !== "PUBLISHED" ? false : true,
          },
        ]}
      />
    );


    const status =
      <FormControl
        sx={{ width: 150 }}
      >
        <Select
          size="small"
          name="status"
          value={test.status}
          disabled={true}
        >
          <MenuItem disabled value="none">Select</MenuItem>
          <MenuItem value="CREATED">Created</MenuItem>
          <MenuItem value="ARCHIVED">Archived</MenuItem>
          <MenuItem value="PENDING">Pending</MenuItem>
          <MenuItem value="PUBLISHED">Published</MenuItem>
        </Select>
      </FormControl>;
        const archived = 
          <Switch
            disabled={!resourceAllocate("test.write")}
            onChange={(event, checked) => onStatusUpdate(event, checked, test._id)}
            checked={test.status === "ARCHIVED" }
          />;

    const category =
      <Tooltip title={(test._categories || []).map(sub => sub.name).join(", ")}>
        <span>
          {test && test._categories && test._categories.length ? (
            <>
              {test._categories[0].name}{" "}
              {test._categories.length > 1 && (
                <span style={{ color: "var(--primary-color)" }}>+{test._categories.length - 1}</span>
              )}
            </>
          ) : (
            ""
          )}
        </span>
      </Tooltip>;

    return {
      id: createIndex(pagination, index),
      test_title: (
        <CustomTypography
          limit={50}
          label={test?.name ? test?.name : ""}
          color="primary"
          onClick={
            (resourceAllocate("test-category.read") || resourceAllocate("question.read") || resourceAllocate("test-response.read"))
              ? () => {
                  navigate({
                    pathname: `test-info/manage/${test?._id}`,
                    search: resourceAllocate("test-category.read")
                      ? "type=categories"
                      : resourceAllocate("question.read")
                      ? "type=questions"
                      : resourceAllocate("test-response.read")
                      ? "type=response"
                      : ""
                  });
                }
              : undefined
          }
        />
      ),
      test_description:
        <Tooltip title={test.description ?? ""}>
          <span>
            {test.description && test.description.length > 60
              ? `${test.description.slice(0, 60)}...`
              : test.description}
          </span>
        </Tooltip>,
      test_no_of_ques: test?.numberOfQuestions ? test?.numberOfQuestions : 0,
      test_display_name: test && test.displayName || "",
      test_category: category,
      test_status: status,
      test_archived: archived,
      test_duration: test?.duration,
      test_createdAt: formatDateTime(test?.createdAt),
      test_createdBy: capitalize(test?.createdBy.name),
      action,
    };
  };

  if (tests.data && tests.data.data && tests.data.data.length) {
    rows = ((tests.data && tests.data.data && tests.data.data) || []).map(
      (test, i) => createRow(i, test, state.pagination)
    );
  }

  return (
    <>
      <Typography variant="h6" sx={{ marginTop: "1rem" , fontWeight: "bold" }}>
        All MCQ Tests
      </Typography>
      <Typography className="sub-title" variant="caption" color = "#6D778F">
        Here is the list of all mcq tests.
      </Typography>
      <Header
        className="my-2"
        searchPlaceholder="Search by test name"
        onSearch={onSearch} 
        btnText="ADD NEW MCQ TEST"
        onBtnClick={
          resourceAllocate("test.write") ? () => navigate("new") : undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <Filters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
      </Header>
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 320px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Test"
        description="Are you sure you want to delete this test?"
      />
      <WarningDialog
        isOpen={state.cloneWarning}
        onClose={() => handleClone()}
        onConfirm={onClone}
        title="Clone Test"
        description="Are you sure you want to clone this test?"
      />
      <Outlet context={{ ...outlet, reFetch: tests.refetch }} />
    </>
  );
};
export default Tests;