import { IErrorResponse, IProjectData } from "../../../../interfaces";
import { Autocomplete, capitalize, Grid, MenuItem, TextField } from "@mui/material";
import { useParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomDialog from "../../../../components/mui/dialog";
import Select from "../../../../components/mui/select";
import { joiResolver } from "@hookform/resolvers/joi";
import CustomLabel from "../../../../components/mui/custom-label";
import { customUrlValidation } from "../../../../validations/sitemap";
import { CustomUrlService } from "../../../../services/sitemap/custom-url";
import { ICustomUrl, ICustomUrlField } from "../../../../interfaces/site-map/custom-urls";
import { useSelector } from "react-redux";
interface outletProps {
    reFetch: () => void;
    refetchCustomUrl: () => void;
}

const ManageCustomUrl = () => {
    const { id } = useParams();
    const { snackbar } = useSnackbar();
    const [searchParam] = useSearchParams();
    const outlet = useOutletContext<outletProps>();
    const hitQuery = (id === "new") ? false : true;
    const navigate = useNavigate();
    const { addCustomUrl, updateCustomUrl, getCustomUrl} = CustomUrlService();
    const customUrl = useQuery({ queryKey: [hitQuery], queryFn: () => getCustomUrl({ _id: id }), enabled: hitQuery });
    const { handleSubmit, control, setValue, getValues, formState: { errors } } = useForm<ICustomUrl>({
        resolver: joiResolver(customUrlValidation),
        defaultValues: {
            _product: "",
            slug: "",
            changeFrequency: "",
        }
    });

    const productList = useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>(
        (state) => state.cmsProduct.list
      ) || [];
    
    useEffect(() => {
        if (id !== "new" && customUrl.data) {
            const data = customUrl.data.data;
            setValue("slug", data.slug);
            setValue("priority", data.priority);
            setValue("changeFrequency", data.changeFrequency);
            setValue("_product", data._product._id);
        }
    }, [id, customUrl.data]);

    const onSubmit = async (data: ICustomUrl) => {
        try {
            if (id === "new") {
                const add = await addCustomUrl(data);
                snackbar(add.message, "info");
                navigate({
                    pathname: "/sitemap/custom-url",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchCustomUrl && outlet.refetchCustomUrl();
            } else {
                const payload = { ...data, _id: id};
                const update = await updateCustomUrl(payload);
                snackbar(update.message, "info");
                navigate({
                    pathname: "/sitemap/custom-url",
                    search: searchParam.toString()
                });
                outlet?.reFetch && outlet.reFetch();
                outlet?.refetchCustomUrl && outlet.refetchCustomUrl();
            }
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err.data.message, "warning");
        }
    };

    const onClose = () => {
        navigate({
            pathname: "/sitemap/custom-url",
            search: searchParam.toString()
        });
    };

    const fields: ICustomUrlField[] = [
        {
            label: "Product Name",
            name: "_product",
            type: "auto-complete",
            placeholder: "Select product name",
            required: true,
            children: {...productList.filter((data) => data.isActive).map((product) => 
                <MenuItem key={product._id} value={product._id} >{product.name}</MenuItem>
            )}
        },
        {
            type: "input",
            name: "slug",
            label: "Enter slug",
            required: true,
        },
        {
            label: "Priority",
            name: "priority",
            type: "select",
            placeholder: "Select priority",
            required: true,
            children: [
                <MenuItem key="0.1" value="0.1">0.1</MenuItem>,
                <MenuItem key="0.2" value="0.2">0.2</MenuItem>,
                <MenuItem key="0.3" value="0.3">0.3</MenuItem>,
                <MenuItem key="0.4" value="0.4">0.4</MenuItem>,
                <MenuItem key="0.5" value="0.5">0.5</MenuItem>,
                <MenuItem key="0.6" value="0.6">0.6</MenuItem>,
                <MenuItem key="0.7" value="0.7">0.7</MenuItem>,
                <MenuItem key="0.8" value="0.8">0.8</MenuItem>,
                <MenuItem key="0.9" value="0.9">0.9</MenuItem>,
                <MenuItem key="1" value="1">1</MenuItem>
            ]
        },
        {
            type: "select",
            name: "changeFrequency",
            label: "Select change frequency",
            required: true,
            children: [
                <MenuItem key="always" value="always">Always</MenuItem>,
                <MenuItem key="hourly" value="hourly">Hourly</MenuItem>,
                <MenuItem key="daily" value="daily">Daily</MenuItem>,
                <MenuItem key="weekly" value="weekly">Weekly</MenuItem>,
                <MenuItem key="yearly" value="yearly">Yearly</MenuItem>,
                <MenuItem key="never" value="never">Never</MenuItem>,
            ]
        },
    ];

    return (
            <CustomDialog
            
                title={id !== "new" ? "Edit Custom Url" : "Add Custom Url"}
                isOpen={!!id}
                onClose={onClose}
                confirmText={id !== "new" ? "Edit Custom Url" : "Add Custom Url"}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    {
                        fields.map(field => {
                            if (field.type === "input") {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                    <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => 
                                        <TextField
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            className="disable-text"
                                            variant={"outlined"}
                                            size={"small"}
                                            placeholder={field.placeholder}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            {...prop.field}
                                        />}
                                    />
                                </Grid>
                                );
                            }else if( field.type === "auto-complete"){
                                return (
                                    <Grid xs={12} md={6} key={field.label} item>
                                      <Controller
                                        control={control}
                                        name={field.name}
                                        render={(prop) => <Autocomplete
                                          className="disable-text"
                                          options={productList.map(
                                            (data) => data
                                          ) || []}
                                          clearIcon={null}
                                          getOptionLabel={(option) => capitalize(option.name)}
                                          renderInput={(params) => <TextField
                                            {...params}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            size={"small"}
                                            variant={"outlined"}
                                            label={<CustomLabel label="Product" required={field?.required} />}
                                            placeholder={field.placeholder}
                                          />
                                        }
                                          {...prop.field}
                                          value={productList.find(data => data._id === getValues(field.name)) || null}
                                          onChange={(e, value) => {
                                            setValue(field.name, value?._id || "");
                                          }}
                                          renderOption={(props, option) => (
                                            <li {...props} key={option._id}>
                                              {capitalize(option.name)}
                                            </li>
                                          )}
                                        />}
                                      />
                                    </Grid>
                                  );
                            }
                            else {
                                return (<Grid key={field.label} item xs={12} md={6}>
                                  <Select
                                    control={control}
                                    className="disable-text"
                                    name={field.name}
                                    label={<CustomLabel label={field.label} required={field?.required} />}
                                    size={"small"}
                                    variant={"outlined"}
                                    error={!!errors[field.name]}
                                    helperText={errors[field.name]?.message}
                                  >
                                    {field.children}
                                  </Select>
                                </Grid>
                                );
                              }

                        })
                    }
                </Grid>
            </CustomDialog>
    );

};

export default ManageCustomUrl;