import "./style.scss";
import backArrowRoundIcon from "../../../../../assets/images/back-arrow-round.svg";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { TabPanel } from "../../../../../components/shared/mui-tabs";
import TestCategories from "./testCategories";
import TestQuestions from "./testQuestions";
import TestResponse from "./testResponse";
import useResource from "../../../../../hooks/useResource";
import { TestsService } from "../../../../../services/configuration/tests";
import { useQuery } from "@tanstack/react-query";
import { capitalize } from "../../../../../utilities/helper";
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IState {
  redirectionLinks: string[];
}

const ManageTestInfo: FC = () => {
  const [value, setValue] = useState<number>(0);
  const { testId } = useParams();
  const navigate = useNavigate();
  const { getSingleTest } = TestsService();

  const testDetails = useQuery({ queryKey: ["test-details"], queryFn: () => getSingleTest({ _id: testId }) });
  const [searchParam, setSearchParam] = useSearchParams();
  const [testname, setTestName] = useState<string>("");
  const [testStatus, setTestStatus] = useState<string>("");
  const { resourceAllocate } = useResource();
  
  const [state, setState] = useState<IState>({
    redirectionLinks: [],
  });

  useEffect(() => {
    if(testDetails.data && testDetails.data.data ) {
      setTestName(
      testDetails.data.data?.name ?? "",
      );
      setTestStatus(
        testDetails.data.data?.status ?? "",
      );
    }
  }, [testDetails.isSuccess, testDetails.data?.data, testDetails.isError]);
  
  useEffect(() => {
    const redirectionLinks = [...state.redirectionLinks];

    if (!redirectionLinks.length) {
      if (resourceAllocate("test-category.read")) {
        redirectionLinks.push("categories");
      }
      if (resourceAllocate("question.read")) {
        redirectionLinks.push("questions");
      }
      if (resourceAllocate("test-response.read")) {
        redirectionLinks.push("response");
      }

      setState((prev) => ({
        ...prev,
        redirectionLinks,
      }));
    }

    const tab = searchParam.get("type") || "categories";
    setValue(redirectionLinks.indexOf(tab));
  }, [state.redirectionLinks, searchParam]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    searchParam.set("type", state.redirectionLinks[newValue]);
    searchParam.delete("date");
    searchParam.delete("status");
    searchParam.delete("category");
    searchParam.set("page", "1");
    setSearchParam(searchParam);
    setValue(newValue);
  };

  const handleClick = () => {
    navigate("/configurations/tests");
  };

  return (
    <div id="test-manage">
      <header className="header">
        <div className="title-box">
          <img src={backArrowRoundIcon} alt="Back" onClick={handleClick} />
          <Typography className="title" variant="h5">
            {capitalize(testname)}
          </Typography>
        </div>
      </header>
      <Box width="100%">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {state.redirectionLinks.map((link, index) => (
              <Tab
                key={index}
                className={index === 0 ? "tabs-space start-tab-space" : "tabs-space"}
                label={link.charAt(0).toUpperCase() + link.slice(1)}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        {state.redirectionLinks.map((link, index) => (
          <TabPanel key={index} value={value} index={index}>
            {link === "categories" && <TestCategories status={testStatus} />}
            {link === "questions" && <TestQuestions status={testStatus} />}
            {link === "response" && <TestResponse />}
          </TabPanel>
        ))}
      </Box>
    </div>
  );
};
export default ManageTestInfo;