import { Box, IconButton, Typography } from "@mui/material";
import Header from "../../components/header";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams, Outlet, useParams } from "react-router-dom";
import CustomTable from "../../components/mui/table";
import GetActions from "../../components/get-actions";
import WarningDialog from "../../components/mui/warning-dialog";
import useSnackbar from "../../hooks/useSnackbar";
import ImageViewer from "react-simple-image-viewer";

import useResource from "../../hooks/useResource";
import useDebounce from "../../hooks/useDebounce";
import { useQuery } from "@tanstack/react-query";
import { IColumn, IErrorResponse, IMediaImageData, IMediaImageRow, IMediaImageState, IPagination } from "../../interfaces";
import {
  createIndex,
} from "../../utilities/helper";

import { MediaImageService } from "../../services";
interface IImageViewer {
    open: boolean,
    index: number,
    images: string[],
  }

const MediaImageList = () => {
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const { getMediaImageList, deleteMediaImage } = MediaImageService();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams(); 
  const { resourceAllocate } = useResource();
  let rows: IMediaImageRow[] = [];
  const [state, setState] = useState<IMediaImageState>({
    deleteWarning: false,
    selectAll: [],
    _mediaImage: "",
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
      totalRecords: 0,
    },
    filters: {
      search: "",
      productId: [],
    },
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    filterCount: 0,
    multiDeleteWarning: false
  });

  const mediaImage = useQuery({
    queryKey: ["cms-media-image", state.pagination.page, state.filters],
    queryFn: () =>
      getMediaImageList({
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit,
        ...state.filters,
      }),
  });

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);
  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord?.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);
  useEffect(() => {
    if (mediaImage?.data?.data?.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: mediaImage.data.meta.page,
          totalPages: mediaImage.data.meta.totalPages,
          totalRecords: mediaImage.data.meta.totalRecords,
        },
      }));
      const images: string[] = [];
      mediaImage.data?.data?.map(ele => {
        images.push(ele.imageUrl);
      });

      setImageViewer(prev => ({
        ...prev,
        images
      }));
    }
  }, [mediaImage?.data?.meta]);


  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
      },
    }));
  }, [searchParams]);



  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };


  const handleDelete = ( _mediaImage= "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _mediaImage,
    }));
  };

  const columns: IColumn[] = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "blog_image",
      label: "Image",
    },
    {
      id: "image_name",
      label: "Name",
    },
    {
      id: "blog_count",
      label: "Blog Count",
    },
    {
      id: "glossary_count",
      label: "Glossary Count"
    },
    {
      id: "action",
      label: "Actions",
      align: "right"
    },
  ];
  const [imageViewer, setImageViewer] = useState<IImageViewer>({
    open: false,
    index: 0,
    images: [],
  });
  const handleImageViewer = (index?: number) => {
    if (!imageViewer.open) {
      setImageViewer({
        ...imageViewer,
        open: true,
        index: index ? index : 0,
      });
    } else {
      setImageViewer({
        ...imageViewer,
        open: false,
        index: 0,
      });
    }
  };

  const createRows = (
      media: IMediaImageData,
      index: number,
    pagination: IPagination
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () => {
              navigate({
                pathname: "" + media._id,
                search: searchParams.toString(),
              });
            },
            disabled: !resourceAllocate("cms-media-image.write"),
          },
          {
            name: "Delete",
            method: () => handleDelete(media._id),
            disabled: !resourceAllocate("cms-media-image.remove"),
          },
        ]}
      />
    );
 
    return {
        id: createIndex(pagination, index),
        blog_image: <IconButton onClick={() => handleImageViewer(index)}><img className="thumbnail" src={media?.imageUrl} alt="" /></IconButton>,
        image_name: media.name,
        blog_count: media.blogCount,
        glossary_count: media.glossaryCount,
        action,
    };
  };

  if (mediaImage.data?.data?.length) {
    rows = mediaImage.data?.data?.map((imageData: IMediaImageData, index: number) =>
      createRows(imageData, index, state.pagination)
    );
  }

  const onMultiDelete = async () => {
    try {
      const category = await deleteMediaImage({ ids: state.selectAll });
      snackbar(category.message, "info");
      mediaImage.refetch();
      setState(prevState => ({
        ...prevState,
        multiDeleteWarning: false,
        selectAll: []
      }));
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
      handleMultiDelete();
    }
  };

  const handleMultiDelete = () => setState(prevState => ({
    ...prevState,
    multiDeleteWarning: !prevState.multiDeleteWarning
  }));

  const onDelete = async () => {
    try {
      const deleted = await deleteMediaImage({ ids: [state._mediaImage] });
      snackbar(deleted.message, "info");
      handleDelete();
      mediaImage.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const openAddBox = () => {
    navigate({
      pathname: "/media-images/new",
      search: searchParams.toString(),
    });
  };

  return (
    <div>
      <Outlet context={{ ...Outlet, refetchMedia: mediaImage.refetch }} />
      <Header
        className="my-2"
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        btnText="Add Media Image"
        onBtnClick={resourceAllocate("cms-media-image.write") ? () => openAddBox() : undefined}
   />
      <Box display="flex" className="mb-2" justifyContent="flex-end" alignItems="center">
        <Box display="flex" alignItems="center">
          <Typography variant="body1">Total Images:</Typography>
          <Typography className="ml-3" variant="body1" >{mediaImage?.data?.meta?.totalRecords}</Typography>
        </Box>
      </Box>
      <Box id="custom-table" marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height= {id ? "calc(100vh - 390px)" : "calc(100vh - 232px)"}
          errorMessage="Add images to see the data here"
          pagination={{
            page: state.pagination.page,
            totalPages: state.pagination.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Media Image"
        description="Are you sure you want to delete this Media Image?"
      />

      {/* Multiple Delete  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMultiDelete()}
        onConfirm={onMultiDelete}
        title="Delete All Media Images"
        description={`Are you sure you want to delete ${state.selectAll.length} selected media${state.selectAll.length > 1 ? "images" : "image"}?`}
      />

      {imageViewer.open && (
        <>
          <ImageViewer
            src={imageViewer.images}
            currentIndex={imageViewer.index}
            disableScroll={true}
            closeOnClickOutside={false}
            onClose={handleImageViewer}
            backgroundStyle={
              {
                color: "white",
                zIndex: 5
              }
            }
          />
        </>
      )}
    </div>
  );
};
export default MediaImageList;
