import { IAlternativeImportData } from "../../../interfaces/alternative-comparison";
import KeywordService from "../../../services/content/keyword";
import ProjectsService from "../../../services/content/projects-products";
import plannerService from "../../../services/planner";


const useValidateAlternative = () => {
    const { getContentProjectPartial } = ProjectsService();
    const { getQuarterPartial } = plannerService();
    const {getContentKeywordPartial} = KeywordService();

    const validateAlternative = (alternatives: (string | number)[][]) => (
        new Promise((resolve) => {
            const mandatoryFields = ["type", "title", "description", "slug", "primaryKeyword", "secondaryKeywords", "_product", "_quarter", "leftBannerImage", "rightBannerImage"];
            const payload = [];
            const error = [];
            let row = 2;
    
            (async () => {
                for (const alternative of alternatives) {
                    if (!alternative?.length) {
                        ++row;
                        continue;
                    }
    
                    const data: IAlternativeImportData = {
                        type: "",
                        title: "",
                        description: "",
                        slug: "",
                        primaryKeyword: "",
                        secondaryKeywords: [],
                        _product: "",
                        _quarter: "",
                        leftBannerImage: "",
                        rightBannerImage: "",
                    };
                    const secKeywords = [];
                    const errMessage: Record<string, string> = {};
    
                    if (typeof alternative[0] === "string") {
                        data.type = alternative[0];
                    } else {
                        if (alternative[0] === undefined) {
                            errMessage["Alternative/Comaprison Type"] = "Alternative/Comaprison Type is required";
                        } else {
                            errMessage["Alternative/Comaprison Type"] = "Alternative/Comaprison type must be string";
                        }
                    }
    
                    if (typeof alternative[1] === "string") {
                        data.title = alternative[1];
                    } else {
                        if (alternative[1] === undefined) {
                            errMessage["Alternative/Comaprison Title"] = "Alternative/Comaprison Title is required";
                        } else {
                            errMessage["Alternative/Comaprison Title"] = "Alternative/Comaprison Title must be string";
                        }
                    }
    
                    if (typeof alternative[2] === "string") {
                        data.description = alternative[2];
                    } else {
                        if (alternative[2] === undefined) {
                            errMessage["Description"] = "Description is required";
                        }
                        else {
                            errMessage["Description"] = "Description must be string";
                        }
                    }
    
                    if (typeof alternative[3] === "string") {
                        data.slug = alternative[3];
                    } else {
                        if (alternative[3] === undefined) {
                            errMessage["Slug"] = "Slug is required";
                        }
                        else {
                            errMessage["Slug"] = "Slug must be string";
                        }
                    }
    
     
                    if (typeof alternative[4] === "string") {
                        try {
                            const project = await getContentProjectPartial({
                                search: alternative[4].trim(),
                            });
                            if (project.data?._id) {
                                data._product = project.data?._id;
                            } else {
                                errMessage["Product"] = "Please enter a valid product name";
                            }
                        } catch (error) {
                            errMessage["Product"] = "Please enter a valid product name";
                        }
                    } else {
                        if (alternative[4] !== undefined) {
                            errMessage["Product"] = "Please enter a valid product name";
                        }
                    }
    
                    if (typeof alternative[5] === "string") {
                        try {
                            const project = await getQuarterPartial({
                                search: alternative[5].trim(),
                                _product: data._product
                            });
                            if (project.data?._id) {
                                data._quarter = project.data?._id;
                            } else {
                                errMessage["Quarter"] = "Please enter a valid quarter name";
                            }
                        } catch (error) {
                            errMessage["Quarter"] = "Please enter a valid quarter name";
                        }
                    } else {
                        if (alternative[5] !== undefined) {
                            errMessage["Quarter"] = "Please enter a valid quarter name";
                        }
                    }
    
                    if (typeof alternative[6] === "string") {
                        data.leftBannerImage = alternative[6];
                    } else {
                        if (alternative[6] === undefined) {
                            errMessage["Left Banner Image"] = "Left Banner Image is required";
                        }
                        else {
                            errMessage["Left Banner Image"] = "Left Banner Image must be string";
                        }
                    }
    
                    if (typeof alternative[7] === "string") {
                        data.rightBannerImage = alternative[7];
                    } else {
                        if (alternative[7] === undefined) {
                            errMessage["Right Banner Image"] = "Right Banner Image is required";
                        }
                        else {
                            errMessage["Right Banner Image"] = "Right Banner Image must be string";
                        }
                    }
    
    
                    if (typeof alternative[8] === "string") {
                        try {
                            const keyword = await getContentKeywordPartial({
                                search: alternative[8].trim(),
                            });
                            if (keyword.data?._id) {
                                data.primaryKeyword = keyword.data?._id;
                            } else {
                                errMessage["Primary Keyword"] = "Please enter a valid primaryKeyword name";
                            }
                        } catch (error) {
                            errMessage["Primary Keyword"] = "Please enter a valid primaryKeyword name";
                        }
                    } else {
                        if (alternative[8] !== undefined) {
                            errMessage["Primary Keyword"] = "Please enter a valid primaryKeyword name";
                        }
                    }
    
                    if(typeof alternative[9] !== "string" && alternative[9] !== undefined){
                        errMessage["Secondary Keyword"] = "Please enter a valid secondary keyword name";
                    }else{
                        let ind = 9;
                        try {
                            while(ind < alternative.length){
                                if(alternative[ind]){
                                    const keyword = await getContentKeywordPartial({
                                        search: alternative[ind],
                                    });
                                    if (keyword.data?._id) {
                                        secKeywords.push( keyword.data?._id);
                                    } else {
                                        errMessage["Secondary Keyword"] = "Please enter a valid secondary keyword name";
                                    }
                                }
                                ind++;
                            }
                            data.secondaryKeywords = secKeywords;
                        } catch (error) {
                             errMessage["Secondary Keyword"] = "Please enter a valid secondary keyword name";
                        }
                    }
    
                    if (mandatoryFields.every((value) => Object.keys(data).includes(value))) {
                        if (Object.keys(errMessage).length) {
                            error.push({ ...errMessage, row });
                        } else {
                            payload.push(data);
                        }
                    } else {
                        if (Object.keys(errMessage).length) {
                            error.push({ ...errMessage, row });
                        }
                    }
                    ++row;
                }
    
                resolve({
                    data: payload,
                    errorMessage: error,
                });
            })();
        }));

    return { validateAlternative };
};
export default useValidateAlternative;
