import { IGlossaryImportData } from "../../../../interfaces/content-marketing/glossary";
import goalsService from "../../../../services/content/goals";
import KeywordService from "../../../../services/content/keyword";
import ProjectsService from "../../../../services/content/projects-products";
import plannerService from "../../../../services/planner";

const usevalidateGlossary = () => {
    const { getContentGoalPartial } = goalsService();
    const { getContentProjectPartial } = ProjectsService();
    const { getQuarterPartial } = plannerService();
    const {getContentKeywordPartial} = KeywordService();

    const validateGlossary = async (glossaries: (string | number)[][]) =>
        // eslint-disable-next-line
        new Promise(async (resolve) => {
            const mandatoryFields = ["title", "description", "slug", "goals","primaryKeyword","secondaryKeywords","_product","_quarter"];
            const payload = [];
            const error = [];
            let row = 2;

            for await (const glossary of glossaries) {
                if (!glossary?.length) {
                    ++row;
                    continue;
                }

                const data: IGlossaryImportData = {
                    title: "",
                    description: "",
                    slug: "",
                    goals: "",
                    primaryKeyword: "",
                    secondaryKeywords: [],
                    _product: "",
                    _quarter: ""
                };
                const secKeywords = [];
                const errMessage: Record<string, string> = {};

                if (typeof glossary[0] === "string") {
                    data.title = glossary[0];
                } else {
                    if (glossary[0] === undefined) {
                        errMessage["Glossary Title"] = "Glossary Title is required";
                    } else {
                        errMessage["Glossary Title"] = "Glossary Title must be string";
                    }
                }

                if (typeof glossary[1] === "string") {
                    data.description = glossary[1];
                } else {
                    if (glossary[1] === undefined) {
                        errMessage["Description"] = "Description is required";
                    }
                    else {
                        errMessage["Description"] = "Description must be string";
                    }
                }

                if (typeof glossary[2] === "string") {
                    data.slug = glossary[2];
                } else {
                    if (glossary[2] === undefined) {
                        errMessage["Slug"] = "Slug is required";
                    }
                    else {
                        errMessage["Slug"] = "Slug must be string";
                    }
                }

 
                if (typeof glossary[3] === "string") {
                    try {
                        const project = await getContentProjectPartial({
                            search: glossary[3].trim(),
                        });
                        if (project.data?._id) {
                            data._product = project.data?._id;
                        } else {
                            errMessage["Product"] = "Please enter a valid product name";
                        }
                    } catch (error) {
                        errMessage["Product"] = "Please enter a valid product name";
                    }
                } else {
                    if (glossary[3] !== undefined) {
                        errMessage["Product"] = "Please enter a valid product name";
                    }
                }

                if (typeof glossary[4] === "string") {
                    try {
                        const project = await getQuarterPartial({
                            search: glossary[4].trim(),
                            _product: data._product
                        });
                        if (project.data?._id) {
                            data._quarter = project.data?._id;
                        } else {
                            errMessage["Quarter"] = "Please enter a valid quarter name";
                        }
                    } catch (error) {
                        errMessage["Quarter"] = "Please enter a valid quarter name";
                    }
                } else {
                    if (glossary[4] !== undefined) {
                        errMessage["Quarter"] = "Please enter a valid quarter name";
                    }
                }

                if (typeof glossary[5] === "string") {
                    try {
                        const goal = await getContentGoalPartial({
                            search: glossary[5].trim(),
                        });
                        if (goal.data?._id) {
                            data.goals = goal.data?._id;
                        } else {
                            errMessage["Goal"] = "Please enter a valid goal name";
                        }
                    } catch (error) {
                        errMessage["Goal"] = "Please enter a valid goal name";
                    }
                } else {
                    if (glossary[5] !== undefined) {
                        errMessage["Goal"] = "Please enter a valid goal name";
                    }
                }


                if (typeof glossary[6] === "string") {
                    try {
                        const keyword = await getContentKeywordPartial({
                            search: glossary[6].trim(),
                        });
                        if (keyword.data?._id) {
                            data.primaryKeyword = keyword.data?._id;
                        } else {
                            errMessage["Primary Keyword"] = "Please enter a valid primaryKeyword name";
                        }
                    } catch (error) {
                        errMessage["Primary Keyword"] = "Please enter a valid primaryKeyword name";
                    }
                } else {
                    if (glossary[6] !== undefined) {
                        errMessage["Primary Keyword"] = "Please enter a valid primaryKeyword name";
                    }
                }

                if(typeof glossary[7] !== "string" && glossary[7] !== undefined){
                    errMessage["Secondary Keyword"] = "Please enter a valid secondary keyword name";
                }else{
                    let ind = 7;
                    try {
                        while(ind < glossary.length){
                            if(glossary[ind]){
                                const keyword = await getContentKeywordPartial({
                                    search: glossary[ind],
                                });
                                if (keyword.data?._id) {
                                    secKeywords.push( keyword.data?._id);
                                } else {
                                    errMessage["Secondary Keyword"] = "Please enter a valid secondaryKeyword name";
                                }
                            }
                            ind++;
                        }
                        data.secondaryKeywords = secKeywords;
                    } catch (error) {
                         errMessage["Secondary Keyword"] = "Please enter a valid secondary keyword name";
                    }
                }

                if (mandatoryFields.every((value) => Object.keys(data).includes(value))) {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    } else {
                        payload.push(data);
                    }
                } else {
                    if (Object.keys(errMessage).length) {
                        error.push({ ...errMessage, row });
                    }
                }
                ++row;
            }

            resolve({
                data: payload,
                errorMessage: error,
            });

        });

    return { validateGlossary };
};
export default usevalidateGlossary;
