import "../../blog/list/style.scss";
import {
  ChangeEvent,
  useEffect,
  MouseEvent,
  useState,
  SetStateAction,
} from "react";
import Header from "../../../../components/header";
import CustomTable from "../../../../components/mui/table";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import WarningDialog from "../../../../components/mui/warning-dialog";
import useDebounce from "../../../../hooks/useDebounce";
import useResource from "../../../../hooks/useResource";
import {
  IBlogRow,
  IErrorResponse,
  IPagination,
  IProjectData,
} from "../../../../interfaces";
import { useQuery } from "@tanstack/react-query";
import GetActions from "../../../../components/get-actions";
import { capitalize, createIndex } from "../../../../utilities/helper";
import useSnackbar from "../../../../hooks/useSnackbar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import BulkAssignment from "../bulk assignment";
import {
  handleSelect,
  handleSelectAll,
} from "../../../content-layout/common/helper";
import CustomTypography from "../../../../components/mui/max-length-limit";
import { useSelector } from "react-redux";
import { GlossaryService } from "../../../../services/glossary";
import { IGlossary, IGlossaryState } from "../../../../interfaces/content-marketing/glossary";
import BlogFilters from "../../blog/filters";

const GlossaryList = () => {
  const navigate = useNavigate();
  const { resourceAllocate } = useResource();
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { getGlossariesList, deleteGlossary, updateGlossaryStatus } = GlossaryService();
  const { snackbar } = useSnackbar();
  const [state, setState] = useState<IGlossaryState>({
    selectAll: [],
    deleteWarning: false,
    _glossary: "",
    filterDialog: {
      anchorEl: null,
      isOpen: false,
    },
    assignmentDialog: {
      anchorEl: null,
      isOpen: false,
    },
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    },
    filters: {
      search: "",
      category: [],
    },
    filterCount: 0,
    searching: "",
    _id: "",
  });

  const searchRecord = useDebounce(search, 1000);
  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  const productList =
    useSelector<{ cmsProduct: { list: IProjectData[] } }, IProjectData[]>(
      (state) => state.cmsProduct.list
    ) || [];

  const applyDefaultFilter = () => {
    const selectedFilterProductId =
      localStorage.getItem("selectedFilterProductId") || "";
    const selectedProduct = productList.find(
      (item) => item._id === selectedFilterProductId
    );
    const product = [];

    if (selectedProduct) {
      product.push({ key: selectedProduct._id, value: selectedProduct.name });
    } else {
      product.push({ key: "", value: "" });
    }
    if (product[0].value !== "") {
      searchParams.set("product", JSON.stringify(product));
    }
  };

  useEffect(() => {
    applyDefaultFilter();
  }, []);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const category: { key: string; value: string }[] = searchParams.get(
      "category"
    )
      ? JSON.parse(String(searchParams.get("category")))
      : [];
    const status: { key: string; value: string }[] = searchParams.get("status")
      ? JSON.parse(String(searchParams.get("status")))
      : [];
    const product: { key: string; value: string }[] = searchParams.get(
      "product"
    )
      ? JSON.parse(String(searchParams.get("product")))
      : [];
    const subCategory: { key: string; value: string }[] = searchParams.get(
      "subCategory"
    )
      ? JSON.parse(String(searchParams.get("subCategory")))
      : [];
    const quarter: { key: string; value: string }[] = searchParams.get(
      "quarter"
    )
      ? JSON.parse(String(searchParams.get("quarter")))
      : [];
    const date: {
      key: string;
      value: string;
      startDate: string;
      endDate: string;
    }[] = searchParams.get("week")
      ? JSON.parse(String(searchParams.get("week")))
      : [];
    const writers: { key: string; value: string }[] = searchParams.get(
      "writers"
    )
      ? JSON.parse(String(searchParams.get("writers")))
      : [];
    const reviewers: { key: string; value: string }[] = searchParams.get(
      "reviewers"
    )
      ? JSON.parse(String(searchParams.get("reviewers")))
      : [];
    const illustrators: { key: string; value: string }[] = searchParams.get(
      "illustrators"
    )
      ? JSON.parse(String(searchParams.get("illustrators")))
      : [];
    const publishers: { key: string; value: string }[] = searchParams.get(
      "publishers"
    )
      ? JSON.parse(String(searchParams.get("publishers")))
      : [];

    filterCount += category.length ? 1 : 0;
    filterCount += status.length ? 1 : 0;
    filterCount += product.length ? 1 : 0;
    filterCount += subCategory.length ? 1 : 0;
    filterCount += quarter.length ? 1 : 0;
    filterCount += date.length ? 1 : 0;
    filterCount += writers.length ? 1 : 0;
    filterCount += reviewers.length ? 1 : 0;
    filterCount += illustrators.length ? 1 : 0;
    filterCount += publishers.length ? 1 : 0;
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        categoryId: category.map((item) => item.key),
        status: status.map((item) => item.key),
        productId: product.map((item) => item.key),
        quarterId: quarter.map((item) => item.key),
        subCategoryId: subCategory.map((item) => item.key),
        startDate: date[0] && date[0].startDate,
        endDate: date[0] && date[0].endDate,
        writers: writers.map((item) => item.key),
        reviewers: reviewers.map((item) => item.key),
        illustrators: illustrators.map((item) => item.key),
        publishers: publishers.map((item) => item.key),
      },
      filterCount,
    }));
  }, [searchParams]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const handleDelete = (_id = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      _id,
    }));
  };

  const onDelete = async () => {
    try {
      const response = await deleteGlossary({ _id: [state._id] });
      snackbar(response?.message, "info");
      handleDelete();
      setState((prevState) => ({
        ...prevState,
        selectAll: prevState.selectAll.filter((ele) => ele !== state._id),
      }));
      glossaries.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };
  const handleStatus = async (
    event: SelectChangeEvent<string>,
    _id: string
  ) => {
    const { name, value } = event.target;

    try {
      const payload = {
        _id,
        [name]: value
      };
      const response = await updateGlossaryStatus(payload);
      snackbar(response.message, "info");
      glossaries.refetch();
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  const createRows = (
    glossary: IGlossary,
    index: number,
    selectedAll: string[],
    pagination: IPagination
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Delete",
            method: () => handleDelete(glossary._id),
            disabled: !resourceAllocate("cms-glossary.remove"),
          },
        ]}
      />
    );
    const status = (
      <FormControl sx={{ width: 150 }}>
        <Select
          size="small"
          name="status"
          onChange={(e) => handleStatus(e, glossary?._id)}
          value={glossary?.status}
          disabled={!resourceAllocate("cms-glossary-status.edit")}
        >
          <MenuItem disabled value="none">
            Select
          </MenuItem>
          <MenuItem value="DRAFT">Draft</MenuItem>
          <MenuItem value="IN_WRITING">In Writing</MenuItem>
          <MenuItem value="IN_REVIEW">In Review</MenuItem>
          <MenuItem value="REASSIGN">Re-assign</MenuItem>
          <MenuItem value="IN_PUBLISHING">In Publishing</MenuItem>
          <MenuItem value="SCHEDULED">Scheduled</MenuItem>
          <MenuItem value="COMPLETED">Completed</MenuItem>
        </Select>
      </FormControl>
    );

    const assignment = capitalize(
      (() => {
        switch (assignmentRole) {
          case "writer":
          case "reviewer":
          case "illustrator":
          case "publisher":
            return (
              (glossary &&
                glossary._assignment &&
                glossary._assignment[assignmentRole] &&
                glossary._assignment[assignmentRole]._user &&
                glossary._assignment[assignmentRole]._user.name) ||
              ""
            );
          default:
            return "";
        }
      })()
    );

    const title = (
      <CustomTypography
        limit={30}
        label={capitalize((glossary && glossary.title) || "")}
        onClick={
          resourceAllocate("cms-glossary.edit")
            ? () =>
                navigate({
                  pathname: `manage/${glossary._id}`,
                  search: resourceAllocate("cms-glossary-assignment.read")
                    ? "type=basic-detail&" + searchParams.toString()
                    : resourceAllocate("cms-glossary-image.read")
                    ? "type=glossary-image&" + searchParams.toString()
                    : resourceAllocate("cms-glossary-content.read")
                    ? "type=content&" + searchParams.toString()
                    : resourceAllocate("cms-glossary-score.read")
                    ? "type=score&" + searchParams.toString()
                    : "",
                })
            : undefined
        }
        color={resourceAllocate("cms-glossary.edit") ? "primary" : undefined}
      />
    );

    return {
      all: (
        <Checkbox
          onChange={(e) => handleSelect(e, glossary._id, state, setState)}
          checked={selectedAll.includes(glossary._id)}
        />
      ),
      id: createIndex(pagination, index),
      blog_title: title,
      quarter: glossary && glossary._quarter && glossary._quarter.name,
      product: glossary && glossary._product && glossary._product.name,
      assignments: assignment,
      status: status,
      action,
    };
  };

  const glossaries = useQuery({
    queryKey: [
      "glossaries",
      state.pagination.page,
      state.searching,
      state.filters,
      state.assignmentDialog.isOpen,
    ],
    queryFn: () =>
      getGlossariesList({
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit,
        populate: ["_quarter", "_product"],
        ...state.filters,
      }),
  });

  const onLimitChange = (event: SelectChangeEvent<string>) => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        limit: Number(event.target.value),
      },
    }));
  };

  useEffect(() => {
    searchParams.set("page", "1");
    setSearchParams(searchParams);
    glossaries.refetch();
  }, [state.pagination.limit]);

  useEffect(() => {
    if (glossaries.data && glossaries.data.data.length) {
      const data = glossaries && glossaries.data && glossaries.data.meta;
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: data.page,
          totalPages: data.totalPages,
          totalRecords: data.totalRecords,
        },
      }));
    }
  }, [glossaries && glossaries.data && glossaries.data.meta]);

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const openAssignmentForm = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      assignmentDialog: {
        ...prevState.assignmentDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.assignmentDialog.isOpen,
      },
    }));

  const closeAssignmentForm = () => {
    setState((prevState) => ({
      ...prevState,
      assignmentDialog: {
        ...prevState.assignmentDialog,
        isOpen: false,
      },
    }));
  };
  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  const [assignmentRole, setAssignmentRole] = useState("writer");
  const handleAssignmentRoleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setAssignmentRole(event.target.value);
  };
  const isChecked = !!(
    glossaries?.data?.data.length &&
    state.selectAll.length === glossaries?.data?.data.length
  );
  const isIndeterminateChecked =
    state.selectAll.length > 0 &&
    state.selectAll.length < Number(glossaries.data?.data.length);

  const columns = [
    {
      id: "all",
      label: (
        <Checkbox
          onChange={(e) =>
            handleSelectAll(
              e,
              glossaries?.data?.data ? glossaries?.data?.data : [],
              setState
            )
          }
          checked={isChecked}
          indeterminate={isIndeterminateChecked}
        />
      ),
    },
    {
      id: "blog_title",
      label: "Glossary Title",
    },
    {
      id: "quarter",
      label: "Quarter",
    },
    {
      id: "assignments",
      label: (
        <Select
          defaultValue="writer"
          size="small"
          onChange={handleAssignmentRoleChange}
          value={assignmentRole}
        >
          <MenuItem value="writer">Writer</MenuItem>
          <MenuItem value="reviewer">Reviewer</MenuItem>
          <MenuItem value="illustrator">Illustrator</MenuItem>
          <MenuItem value="publisher">Publisher</MenuItem>
        </Select>
      ),
    },
    {
      id: "product",
      label: "Product",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "action",
      label: "Action",
    },
  ];
  let rows: IBlogRow[] = [];
  if (glossaries?.data?.data.length) {
    rows = glossaries.data.data.map((data, index) =>
      createRows(data, index, state.selectAll, state.pagination)
    );
  }

  return (
    <div id="blog">
      <Typography variant="h6">All Glossaries</Typography>
      <Typography className="sub-title" variant="caption">
        Here you can manage all the glossaries.
      </Typography>
      <Header
        className="my-2"
        btnText="Add New Glossary"
        onBtnClick={
          resourceAllocate("cms-glossary.write")
            ? () =>
                navigate({
                  pathname: "manage/new",
                  search: "type=basic-detail",
                })
            : undefined
        }
        searchPlaceholder="Search by title"
        onSearch={onSearch}
        onFilter={openFilter}
        filterCount={state.filterCount}
        onImport={
          resourceAllocate("cms-glossary.write")
            ? () =>
                navigate({
                  pathname: "/glossary/import",
                  search: searchParams.toString(),
                })
            : undefined
        }
      >
        <BlogFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnClose={closeFilter}
        />
        {resourceAllocate("cms-glossary-assignment.write") && (
          <Button
            style={{
              backgroundColor: "rgb(231 231 231)",
              borderColor: "grey",
              boxShadow: "none",
            }}
            disabled={!state.selectAll.length}
            className="ml-2 h-100"
            onClick={openAssignmentForm}
          >
            <Typography
              variant="body2"
              style={{ color: state.selectAll.length ? "#000" : "grey" }}
            >
              Assign To
            </Typography>
            {state.assignmentDialog.isOpen ? (
              <ArrowDropUpIcon
                style={{ color: state.selectAll.length ? "#000" : "grey" }}
              />
            ) : (
              <ArrowDropDownIcon
                style={{ color: state.selectAll.length ? "#000" : "grey" }}
              />
            )}
          </Button>
        )}
        <BulkAssignment
          anchorEl={state.assignmentDialog.anchorEl}
          isOpen={state.assignmentDialog.isOpen}
          closeDialog={closeAssignmentForm}
          ids={state.selectAll}
          setState={setState}
        />
      </Header>

      <Box display="flex" className="mb-2" justifyContent="space-between">
        <Box>
          {state.selectAll.length ? (
            <Box>
              <Typography className="ml-2" variant="body1">
                {state.selectAll.length > 1
                  ? `${state.selectAll.length} entries selected`
                  : `${state.selectAll.length} entry selected`}
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="body1">Total Glossaries:</Typography>
          <Typography className="ml-3" variant="body1">
            {glossaries?.data?.meta?.totalRecords}
          </Typography>
        </Box>
      </Box>
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 290px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
          limitPerPage={true}
          onLimitChange={onLimitChange}
          limitValue={state.pagination.limit.toString()}
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Glossary"
        description="Are you sure you want to delete this glossary?"
      />

      <Outlet context={{ ...Outlet, reFetch: glossaries.refetch }} />
    </div>
  );
};

export default GlossaryList;
