import { OrganizationChart } from "primereact/organizationchart";
import { TreeNode } from "primereact/treenode";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "./style.scss";
import { Avatar, Box } from "@mui/material";
import { capitalize, getRandomColor } from "../../../utilities/helper";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { OrganisationDataService } from "../../../services/organisation-tree";
import { OrganisationData, Subordinate } from "../../../interfaces/organisation-tree";

interface NodeData {
  avatar: JSX.Element;
  name: string;
  email: string;
  designation: string;
  department: string;
}

interface EmployeeNode {
  expanded: boolean;
  type: string;
  className: string;
  style: Record<string, string>;
  data: NodeData;
  children: EmployeeNode[]; 
}

const OrganisationTree = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const { getOrganisationTreeData } = OrganisationDataService();

  const [zoom, setZoom] = useState(1); 

  const zoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2));
  };

  const zoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5));
  };
  const mapUserToNode = (employee: OrganisationData | Subordinate): EmployeeNode => {
    const randomBackgroundColor = getRandomColor();

    return {
      expanded: true,
      type: "person",
      className: "bg-indigo-500",
      style: { borderRadius: "12px" },
      data: {
        avatar: <Avatar sx={{ backgroundColor: randomBackgroundColor }}>{employee.name ? employee.name.charAt(0).toUpperCase() : ""}</Avatar>,
        name: capitalize(employee && employee.name || ""),
        email: employee && employee.email || "",
        designation: employee && employee.designation && employee.designation.name && capitalize(employee.designation.name) || "",
        department: employee && employee.department && employee.department.name && employee.department.name.toUpperCase() || "",
      },
      children: employee.subordinates?.map(mapUserToNode) || []
    };
  };

  const [data, setData] = useState<EmployeeNode[]>([
    {
      expanded: true,
      type: "",
      className: "",
      style: {},
      data: {
        avatar: <Avatar />,
        name: "",
        email: "",
        designation: "",
        department: "",
      },
      children: []
    }
  ]); // Add state for data
  const organisationData = useQuery({
    queryKey: ["organisation-tree-data"],

    queryFn: () =>
      getOrganisationTreeData(),
  });
 
  useEffect(() => {
    if (organisationData && organisationData.data && organisationData.data.data) {
      const newData = mapUserToNode(organisationData.data.data);
      setData([newData]);
    } else {
      setData([
        {
          expanded: true,
          type: "",
          className: "",
          style: {},
          data: {
            avatar: <Avatar />,
            name: "",
            email: "",
            designation: "",
            department: "",
          },
          children: []
        }
      ]);
    }
  }, [organisationData && organisationData.data && organisationData.data.data]);

  // Center the chart after the data has been set and the DOM is updated
  useEffect(() => {
    const centerDiv = divRef.current;
    
    if (centerDiv && data.length > 0) {
      // Delay until the DOM is fully rendered
   
        const fullWidth = centerDiv.scrollWidth;
        const centerPosition = fullWidth / 2;

        const container = centerDiv.parentElement;

        if (container) {
          const viewportCenter = container.clientWidth / 2;
          container.scrollTo({
            left: centerPosition - viewportCenter,
            top: 0,
            behavior: "auto",
          });
        }
      }
  }, [data, divRef.current]); 

  const nodeTemplate = (node: TreeNode) => (
    <div
      className="node-template-div"
    >
      <div>
        <div className="avatar-circle mb-2">
          {node.data.avatar}
        </div>
      </div>
      <div className="node-template-info">
        <div className="font-bold font-black">{node.data.name}</div>
        <div>{node.data.email}</div>
        <div>{node.data.designation}</div>
        <div>{node.data.department}</div>
      </div>
    </div>
  );
  
  return (
    <Box className = "org-chart-container">
      <div className = "org-chart-buttons">
        <button className = "zoom-button" onClick={zoomIn}>
          +
        </button>
        <button className = "zoom-button" onClick={zoomOut}>
          -
        </button>
      </div>
      <div className = "org-chart-div" style={{transform: `scale(${zoom})`, transformOrigin: "center"}} ref={divRef}>
         {data.length > 0  ? <OrganizationChart value={data} nodeTemplate={nodeTemplate} className="company-orgchart"/> : null}
      </div>
    </Box>
  );
};

export default OrganisationTree;