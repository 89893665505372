import { Box, Chip, FormHelperText, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { IErrorResponse, IAttachment, ITemplate } from "../../../../interfaces";
import { FC, KeyboardEvent, useEffect } from "react";
import TinyEditor from "../../../../components/text-editor";
import { joiResolver } from "@hookform/resolvers/joi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useSnackbar from "../../../../hooks/useSnackbar";
import { validateEmail } from "../../../../validations/shared";
import useUser from "../../../../hooks/useUser";
import { capitalize, replaceHtmlKeyword } from "../../../../utilities/helper";
import EmailSuggestion from "../../../../components/mui/email-suggestion";
import CustomLabel from "../../../../components/mui/custom-label";
import SearchSelect from "../../../../components/mui/search-select";
import GetActions from "../../../../components/get-actions";
import CustomTable from "../../../../components/mui/table";
import CustomDialog from "../../../../components/mui/dialog";
import { useQuery } from "@tanstack/react-query";
import { ResourceRequestedServices } from "../../../../services/resource-request";
import { resourceMailValidation } from "../../../../validations/resource-request/mail";
import { IResourceeMailField, IResourceMail } from "../../../../interfaces/resource-request/mail";
import { useSelector } from "react-redux";

const RequestMail: FC = () => {
    const { getRequestedResource, sendRequestedResourceEmail } = ResourceRequestedServices();
    const navigate = useNavigate();
    const { user } = useUser();
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const hitQuery = !!id;
    const requestedResource = useQuery({ queryKey: [hitQuery], queryFn: () => getRequestedResource({ _id: id }), enabled: hitQuery });

    let [...templates] = useSelector<{ template: { list: ITemplate[] } }, ITemplate[]>(state => state.template.list) || [];
    templates = templates.filter(template => template.type === "email" && template.tag === "resource_request");

    const { snackbar } = useSnackbar();
    const { control, getValues, setValue, resetField, trigger, watch, handleSubmit, formState: { errors } } = useForm<IResourceMail>({
        resolver: joiResolver(resourceMailValidation),
        defaultValues: {
            to: "",
            template: "",
            ccText: "",
            bccText: "",
            subject: "",
            content: "",
            templateAttachments:[]
        }
    });

    const keywords = {
        engagementType: requestedResource.data && requestedResource.data.data ? capitalize(requestedResource.data.data.engagementType || "") : "",
        requestType: requestedResource.data && requestedResource.data.data ? capitalize(requestedResource.data.data.requestType || "") : "",
        jobTitle: requestedResource.data && requestedResource.data.data ? capitalize(requestedResource.data.data.jobTitle?.name || "") : "",
        skillSetRequired: requestedResource.data && requestedResource.data.data ? requestedResource.data.data.skillsetRequired : [],
        hardwareName: requestedResource.data && requestedResource.data.data ? requestedResource.data.data.hardwareDetails?.name : "",
        status: requestedResource.data && requestedResource.data.data ? capitalize(requestedResource.data.data.status || "") : "",
        softwarexName: requestedResource.data && requestedResource.data.data ? requestedResource.data.data.softwareDetails?.name : "",
        workLocation: requestedResource.data && requestedResource.data.data ? capitalize(requestedResource.data.data.workLocation || "") : ""
    } as { [key: string]: string | string[] | number} ;
    const attachmentColumns = [
        {
            id: "id",
            label: "S No."
        },
        {
            id: "file_name",
            label: "File Name"
        },
        {
            id: "file_size",
            label: "File Type"
        },
        {
            id: "action",
            label: "Actions"
        }
    ];

    const generateAttachmentRows = (attachments: IAttachment[]) => 
    attachments.map((attachment, i) => {
          const action = (
            <GetActions
              icons={[
                {
                  name: "Preview",
                  method: () => window.open(`${process.env.REACT_APP_S3_BASE_URL}${attachment.path}`, "_blank"),
                },
              ]}
            />
          );
      
          return {
            id: i + 1,
            file_name: attachment.filename.length > 50 ? `${attachment.filename.substring(0, 50)}...` : attachment.filename,
            file_size: `${(attachment.sizeInBytes / 1024 / 1024).toFixed(2)} MB`,
            action,
          };
        });

    useEffect(() => {
        setValue("to", requestedResource && requestedResource.data && requestedResource.data.data && requestedResource.data.data.requestedBy && requestedResource.data.data.requestedBy.email || "");
    }, [requestedResource && requestedResource.data && requestedResource.data.data]);
        
    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "template") {
                const template = templates.find(template => template._id === value.template);
                setValue("subject", String(template?.email.subject));
                setValue("content", String(template?.email.content));
                setValue("templateAttachments", template?.email.attachments || []);
                trigger("content");
            } else if (name && ["to", "bccText", "ccText"].includes(name)) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (user) {
            const cc: string[] = getValues("cc") || [];
            cc.push(user?.email);
            setValue("cc", [...new Set(cc)]);
            trigger("cc");
        }
    }, [user]);


    const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
        let payload: string[] = [];

        if (key === "ccText" || key === "bccText") {
            const err = validateEmail(getValues(key));
            if (err.error) {
                return;
            }

            const enteredEmail = getValues(key) ? String(getValues(key)) : "";

            if (enteredEmail.trim() !== "" && e.key === "Enter") {
                if (key === "ccText") {
                    const prev = getValues("cc") ? getValues("cc") : [];
                    payload = [...prev, enteredEmail];
                    setValue("cc", [...new Set(payload)]);
                    resetField(key);
                } else if (key === "bccText") {
                    const prev = getValues("bcc") ? getValues("bcc") : [];
                    payload = [...prev, enteredEmail];
                    setValue("bcc", [...new Set(payload)]);
                    resetField(key);
                }
            }
        }
    };

    const handleEmailSelect = (email: string, name: string) => {
        let payload: string[] = [];
        if (name === "to") {
            setValue("to", email);
        } else if (["ccText", "bccText"].includes(name)) {
            if (name === "ccText") {
                const prev = getValues("cc") ? getValues("cc") : [];
                payload = [...prev, email];
                setValue("cc", [...new Set(payload)]);
                resetField(name);

            } else if (name === "bccText") {
                const prev = getValues("bcc") ? getValues("bcc") : [];
                payload = [...prev, email];
                setValue("bcc", [...new Set(payload)]);
                resetField(name);
            }
        }
    };

    const removeEmail = (key: string, value: string) => {
        if (key === "ccText") {
            let payload = getValues("cc");
            payload = payload.filter(email => email !== value);
            setValue("cc", payload);
            trigger("cc");
        } else if (key === "bccText") {
            let payload = getValues("bcc");
            payload = payload.filter(email => email !== value);
            setValue("bcc", payload);
            trigger("bcc");
        }
    };

    const onSubmit = async (data: IResourceMail) => {
        const payload = {
            ...data,
            subject: replaceHtmlKeyword(data.subject, keywords),
            content: replaceHtmlKeyword(data.content, keywords)
        };
        delete payload.ccText;
        delete payload.bccText;
        delete payload.template;
        try {
            const emailSent = await sendRequestedResourceEmail({
                _resource: id,
                ...payload,
                to: [payload.to]
            });
            navigate({ pathname: "/resource-requested/requests", search: searchParams.toString() });

            snackbar(emailSent.message,"info");
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "warning");
        }
    };

    const fields: IResourceeMailField[] = [
        {
            type: "input",
            name: "to",
            label: "To",
            required: true
        },
        {
            type: "select",
            name: "template",
            label: "Template",
            options: templates,
            displayFieldKey: "title",
            storeFieldKey: "_id"
        },
        {
            type: "input",
            name: "ccText",
            label: "CC",
            placeholder: "Type email address and press enter"
        },
        {
            type: "input",
            name: "bccText",
            label: "BCC",
            placeholder: "Type email address and press enter"
        },
        {
            type: "input",
            name: "subject",
            label: "Subject",
            placeholder: "Type subject here",
            width: 12,
            required: true
        },
        {
            type: "text-editor",
            name: "content",
            label: "Content",
            required: true
        },
    ];
    const onCopy = (url:string,label:string) => {
        navigator.clipboard.writeText(url);
        snackbar(`${capitalize(label)} ID copied to clipboard`, "info");
      };

    const handleKeyDown = (event : React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
        event.preventDefault();
    }
    };

    return (
      <div className="email-dialog">
      <CustomDialog
      title={"Send Mail"}
      isOpen={id ? true : false}
      confirmText={"Send"}
      cancelText={"Cancel"}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => {
        navigate({ pathname: "/resource-requested/requests", search: searchParams.toString() });
    }}
  >
                    <Grid container spacing={4} onKeyDown={handleKeyDown}>
                        {
                            fields.map(field => {
                                if (field.type === "input" && field.name === "to" || field.name === "ccText" || field.name === "bccText") {
                                    return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                        <EmailSuggestion
                                            control={control}
                                            label={<CustomLabel label={field.label} required={field && field.required} />}
                                            name={field.name}
                                            value={getValues(field.name)}
                                            placeholder={field.placeholder}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            onEmailSelect={handleEmailSelect}
                                            onKeyUp={addEmail}
                                        />
                                        {
                                            <Box>
                                                {
                                                    field.name === "ccText" && getValues("cc") &&
                                                    getValues("cc").map(email => <Chip
                                                        key={email}
                                                        label={email}
                                                        onDelete={() => removeEmail(field.name, email)}
                                                        color="primary"
                                                        variant="outlined"
                                                        onClick = {() => onCopy(email,"email")}
                                                        className="mt-2"
                                                    />)
                                                    ||
                                                    field.name === "bccText" && getValues("bcc") &&
                                                    getValues("bcc").map(email => <Chip
                                                        key={email}
                                                        label={email}
                                                        onDelete={() => removeEmail(field.name, email)}
                                                        color="primary"
                                                        variant="outlined"
                                                        onClick = {() => onCopy(email,"email")}
                                                        className="mt-2"
                                                    />)
                                                }
                                            </Box>
                                        }
                                    </Grid>
                                    );
                                } else if (field.type === "input") {
                                    return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                        <Controller
                                            control={control}
                                            name={field.name}
                                            render={(prop) => <TextField
                                                label={<CustomLabel label={field.label} required={field && field.required} />}
                                                className="disable-text"
                                                variant={"outlined"}
                                                size={"small"}
                                                placeholder={field.placeholder}
                                                error={!!errors[field.name]}
                                                helperText={errors[field.name]?.message}
                                                {...prop.field}

                                            />}
                                        />
                                    </Grid>
                                    );
                                } else if (field.type === "text-editor") {
                                    return (<Grid key={field.label} item xs={12}>
                                        <Box className={errors[field.name] ? "tiny-error" : ""}>
                                            <TinyEditor value={getValues("content")} onChange={(e: string) => setValue("content", e)} height="375px" />
                                            {errors[field.name] && <FormHelperText error={!!errors[field.name]}>{errors[field.name]?.message}</FormHelperText>}
                                        </Box>
                                    </Grid>
                                    );
                                }
                                else {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <SearchSelect
                                            name={field.name}
                                            label={<CustomLabel label={field.label} required={field && field.required} />}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            options={field.options}
                                            displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                                            storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                                            displayUserName={field.displayUserName}
                                            capitalize={field.capitalize}
                                            trigger={trigger}
                                            setValue={setValue}
                                            getValues={getValues}
                                        />
                                    </Grid>
                                    );
                                }
                            })
                        }
                    </Grid>
                    {
                        (getValues("templateAttachments") && getValues("templateAttachments")?.length) ? (
                            <Grid item xs={12} className="mt-5">
                                <CustomTable
                                    columns={attachmentColumns}
                                    rows={generateAttachmentRows(getValues("templateAttachments"))}
                                />
                            </Grid>
                        ) : null
                    }
        </CustomDialog>
        </div>
    );
};

export default RequestMail;