import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { OfferedService } from "../../../../services";
import { capitalize } from "../../../../utilities/helper";
import { validateEmail } from "../../../../validations/shared";
import { offeredPreOnBoardValidation } from "../../../../validations";
import { FC, KeyboardEvent, useEffect } from "react";
import { IOfferedPreOnBoard } from "../../../../interfaces/offered/action/";
import { IOfferedPreOnBoardField, IJobPosition, ICandidateDetails, IErrorResponse, ITeam } from "../../../../interfaces";
import { Box, Button, Chip, Divider, TextField, Grid, MenuItem } from "@mui/material";
import useUser from "../../../../hooks/useUser";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import EmailSuggestion from "../../../../components/mui/email-suggestion";
import SearchSelect from "../../../../components/mui/search-select";
import CustomLabel from "../../../../components/mui/custom-label";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
interface props {
    candidate: ICandidateDetails | undefined;
    candidatesRefetch: () => void;

}

const PreOnBoard: FC<props> = ({ candidate, candidatesRefetch }) => {
    const [searchParam] = useSearchParams();
    const { user } = useUser();
    const { preOnBoard } = OfferedService();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const designations = useSelector<{ jobPosition: { list: IJobPosition[] } }, IJobPosition[]>(state => state.jobPosition.list);

    const { control, getValues, setValue, watch, resetField, trigger, handleSubmit, reset, formState: { errors } } = useForm<IOfferedPreOnBoard>({
        resolver: joiResolver(offeredPreOnBoardValidation),
        defaultValues: {
            to: "",
            name: "",
            reportingManager: "",
            jobPosition: "",
            jobType: "general",
            ccText: "",
            _department: "",
            date: "",
        }
    });
    const teams = useSelector<{ team: { list: ITeam[] } }, ITeam[]>(
        (state) => state.team.list
    );

    const getDesignationOptions = () => 
        designations.filter(title => title && title._department && title._department._id === getValues("_department"));

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name && ["to", "ccText"].includes(name)) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (candidate) {
            setValue("to", candidate.email);
            setValue("name", capitalize(candidate.name));
        }
        if (user) {
            const cc: string[] = getValues("cc") || [];
            cc.push(user?.email);
            setValue("cc", [...new Set(cc)]);
            trigger("cc");
        }
    }, [candidate, user]);

    const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
        let payload: string[] = [];

        if (key === "ccText") {
            const err = validateEmail(getValues(key));
            if (err.error) {
                return;
            }

            const enteredEmail = getValues(key) ? String(getValues(key)) : "";

            if (enteredEmail.trim() !== "" && e.key === "Enter") {
                if (key === "ccText") {
                    const prev = getValues("cc") ? getValues("cc") : [];
                    payload = [...prev, enteredEmail];
                    setValue("cc", [...new Set(payload)]);
                    resetField(key);
                }
            }
        }
    };

    const handleEmailSelect = (email: string, name: string) => {
        let payload: string[] = [];
        if (name === "to") {
            setValue("to", email);
        } else if (["ccText", "bccText"].includes(name)) {
            if (name === "ccText") {
                const prev = getValues("cc") ? getValues("cc") : [];
                payload = [...prev, email];
                setValue("cc", [...new Set(payload)]);
                resetField(name);

            }
        }
    };

    const removeEmail = (key: string, value: string) => {
        if (key === "ccText") {
            let payload = getValues("cc");
            payload = payload.filter(email => email !== value);
            setValue("cc", payload);
            trigger("cc");
        }
    };

    const onSubmit = async (data: IOfferedPreOnBoard) => {
        try {
            let company = "";
            if (candidate?.typeOfLead === "FULL-TIME") {
                company = "Relinns Technologies Pvt. Ltd";
            } else {
                company = "Relinns Technologies";
            }
            const payload = {
                ...data,
                company,
                jobId: candidate?.jobId._id,
                _lead: candidate?._id,
                typeOfLead: candidate?.typeOfLead,
            };
            delete payload.ccText;
            const preOnBoardSent = await preOnBoard({
                ...payload,
                to: [payload.to],
            });
            navigate({
                pathname: "/offered",
                search: searchParam.toString()
            });
            snackbar(preOnBoardSent.message, "info");
            candidatesRefetch();
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "warning");
        }

    };

    const fields: IOfferedPreOnBoardField[] = [
        {
            type: "input",
            name: "to",
            label: "To*",
            placeholder: "Type candidate email here"
        },
        {
            type: "input",
            name: "ccText",
            label: "CC",
            placeholder: "Type email address and press enter"
        },
        {
            type: "input",
            name: "name",
            label: "Name*",
            placeholder: "Type candidate name here"
        },
        {
            type: "input",
            name: "reportingManager",
            label: "Reporting Manager*",
            placeholder: "Type reporting manager name here"
        },
        {
          type: "date",
          name: "date",
          label: "Date of joining*"
        },
        {
            label: "Department",
            name: "_department",
            type: "searchSelect",
            required: true,
            options: teams.map((i) => ({
                key: i._id,
                value: capitalize(i.name)
            })),
            displayFieldKey: "value",
            storeFieldKey: "key",
        },
        {
            label: "Designation",
            name: "jobPosition",
            type: "searchSelect",
            options: getDesignationOptions().map((i) => ({
                key: i._id,
                value: capitalize(i.name)
            })),
            displayFieldKey: "value",
            storeFieldKey: "value",
            disabled: !!watch("_department"),
            required: true,
          },
        {
            type: "select",
            name: "jobType",
            label: "Engagement Type",
            children: [
                <MenuItem key={"general"} value="general">General</MenuItem>,
                <MenuItem key={"content or consultant"} value="content or consultant">Content or Consultant</MenuItem>,
            ]
        },
    ];

    const onCopy = (url:string,label:string) => {
        navigator.clipboard.writeText(url);
        snackbar(`${capitalize(label)} ID copied to clipboard`, "info");
    };

    const selectDate = (value: Dayjs | null, name: string) => {
      const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : "";
      const keyExist = name === "date";
      if (keyExist) {
          setValue(name, date);
          trigger(name);
      }
    };

    return (
        <Box paddingTop="10px">
            <Box sx={{ height: "37vh", overflow: "auto", padding: "10px 4px 0px" }}>
                <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => e.key === "Enter" && e.preventDefault()}>
                    <Grid container spacing={4}>
                        {
                            fields.map(field => {
                                if (field.type === "input" && field.name === "to" || field.name === "ccText") {
                                    return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                        <EmailSuggestion
                                            control={control}
                                            label={field.label}
                                            name={field.name}
                                            value={getValues(field.name)}
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            onEmailSelect={handleEmailSelect}
                                            onKeyUp={addEmail}
                                        />
                                        {
                                            <Box>
                                                {
                                                    field.name === "ccText" && getValues("cc") &&
                                                    getValues("cc").map(email => <Chip
                                                        key={email}
                                                        label={email}
                                                        onDelete={() => removeEmail(field.name, email)}
                                                        color="primary"
                                                        variant="outlined"
                                                        sx={{ margin: "5px" }}
                                                        onClick = { () => onCopy(email,"email")}
                                                    />)
                                                }
                                            </Box>
                                        }
                                    </Grid>
                                    );
                                } else if (field.type === "input") {
                                    return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                        <Controller
                                            control={control}
                                            name={field.name}
                                            render={(prop) => <TextField
                                                label={field.label}
                                                className="disable-text"
                                                variant={"outlined"}
                                                size={"small"}
                                                placeholder={field.placeholder}
                                                error={!!errors[field.name]}
                                                helperText={errors[field.name]?.message}
                                                {...prop.field}

                                            />}
                                        />
                                    </Grid>
                                    );
                                }
                                else if (field.type === "searchSelect") {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <SearchSelect
                                            name={field.name}
                                            label={<CustomLabel label={field.label} required={field?.required} />}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                            options={field.options}
                                            displayFieldKey={field.displayFieldKey ? field.displayFieldKey : ""}
                                            storeFieldKey={field.storeFieldKey ? field.storeFieldKey : ""}
                                            displayUserName={field.displayUserName}
                                            capitalize={field.capitalize}
                                            trigger={trigger}
                                            setValue={setValue}
                                            getValues={getValues}
                                        />
                                    </Grid>
                                );}
                                else if (field.type === "date") {
                                  return (<Grid key={field.label} item xs={12} md={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <MobileDatePicker
                                        label={<CustomLabel label={field.label} required={field?.required} />}
                                        onChange={(e) => selectDate(e, field.name)}
                                        value={dayjs(getValues(field.name))}
                                        slotProps={{
                                          textField: {
                                            error: errors[field.name] ? true : false,
                                            helperText: errors[field.name]?.message
                                          }
                                        }}
                                        format="LL"
                                      />
                                    </LocalizationProvider>

                                  </Grid>
                                  );
                                }
                                else {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <Select
                                            control={control}
                                            className="disable-text"
                                            name={field.name}
                                            label={field.label}
                                            size={"small"}
                                            variant={"outlined"}
                                            error={!!errors[field.name]}
                                            helperText={errors[field.name]?.message}
                                        >
                                            {field.children}
                                        </Select>
                                    </Grid>
                                    );
                                }
                            })
                        }
                    </Grid>
                    <Box className="action-box">
                        <Divider sx={{ marginBottom: "16px" }} />
                        <Button variant="outlined" onClick={() => reset()}>clear</Button>
                        <Button type="submit">Send</Button>
                    </Box>
                </form>
            </Box>
        </Box >
    );
};

export default PreOnBoard;