import {  IErrorResponse} from "../../../../../interfaces";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import useSnackbar from "../../../../../hooks/useSnackbar";
import CustomDialog from "../../../../../components/mui/dialog";
import { Box, Checkbox, Divider, ListItem, ListItemText } from "@mui/material";
import { blogService } from "../../../../../services/blog";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { CMS_ERROR_MESSAGES } from "../../../../../utilities/messages";

export interface IChecklist {
    check: string
}

const ChecklistPublish = () => {
    const { id } = useParams();
    const [searchParam] = useSearchParams();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const { publishBlog, getCheckListData, saveChecks } = blogService();
    const [checked, setChecked] = useState(false);
    const { handleSubmit } = useForm<IChecklist>();
    const payload = {
        blogId: id,
        data: selectedIds,
        type: "PUBLISHER"
    };
    
    
    const checkListData = useQuery({
        queryFn: () => getCheckListData({type: "PUBLISHER"})
    });

    const checkItems = Array.isArray(checkListData?.data?.data) ? checkListData?.data?.data
    .filter((check) => check.type === "PUBLISHER") : [];
    const onSubmit = async () => {
        if((checkItems && selectedIds.length !== checkItems.length) || checked === false){
            snackbar(CMS_ERROR_MESSAGES.en.select_all_checkboxes, "warning");
            return;
        }
        payload.data = selectedIds;
        try{
            await saveChecks(payload);
        }catch(error){
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
        }
        try {
            const response = await publishBlog({ blogId: id });
            snackbar(response.message, "info");
            navigate("/blog");
        
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "error");
            console.log("Error in publishing Blog", error);
        }
    };

    const onClose = () => {
        navigate({
            pathname: `/blog/manage/${id}`,
            search: searchParam.toString()
        });
    };


    const handleCheckboxChange = (id : string) => {
        setSelectedIds(prevData => {
            const index = prevData.indexOf(id);   
            if (index === -1) {
              return [...prevData, id];
            } else {
              return prevData.filter(itemId => itemId !== id);
            }
          });
    };

    return (
        <>
            <Box>
                <CustomDialog
                    size="sm"
                    title={"Submit Manuscript"}
                    isOpen={id ? true : false}
                    onClose={onClose}
                    onSubmit={handleSubmit(onSubmit)}
                    confirmText="Submit"
                    cancelText="Discard"
                >
                    <b>Checklist:</b>
                    {
                         checkItems && checkItems
                            .map((checkList) => <ListItem key = "check-list" style={{marginTop:"-10px"}}>
                                        <Checkbox edge="end" 
                                            onChange={() => handleCheckboxChange(checkList._id)}/>
                                        <ListItemText style={{marginLeft:"12px", textDecoration: selectedIds.includes(checkList._id) ? "line-through" : "none"}} primary={checkList.data} />
                                    </ListItem>)
                    }
                    <Divider orientation="horizontal"/>
                    <ListItem key = "check-list">
                        <Checkbox edge="end" 
                        onChange={() => setChecked(!checked)}/> 
                        <ListItemText style={{marginLeft:"12px"}} primary={"*I have read the checklist and agree that this has been followed properly"} />
                    </ListItem>
                </CustomDialog>
            </Box>
        </>
    );

};

export default ChecklistPublish;