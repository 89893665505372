import { useState, useEffect, ChangeEvent } from "react";
import { capitalize, createIndex } from "../../../utilities/helper";
import { Box} from "@mui/material";
import useSnackbar from "../../../hooks/useSnackbar";
import { Outlet, useSearchParams } from "react-router-dom";
import useResource from "../../../hooks/useResource";
import CustomTable from "../../../components/mui/table";
import { useQuery } from "@tanstack/react-query";
import { SitemapService } from "../../../services/sitemap";
import GetActions from "../../../components/get-actions";
import { IErrorResponse, IPagination } from "../../../interfaces";
import { ISitemapData, ISitemapRow, ISitemapState } from "../../../interfaces/site-map/sitemap";

const Sitemap = () => {
  let rows: ISitemapRow[] = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const { snackbar } = useSnackbar();
  const { resourceAllocate } = useResource();
  const { getSitemaps, generateSitemap } = SitemapService();
  const [state, setState] = useState<ISitemapState>({
    pagination: {
      page: 1,
      limit: 20,
      totalPages: 1,
    }
  });

  const sitemaps = useQuery({
    queryKey: ["all-sitemaps", state.pagination.page],
    queryFn: () =>
        getSitemaps({
        pagination: true,
        limit: state.pagination.limit,
        page: state.pagination.page
      }),
  });

  useEffect(() => {
    if (sitemaps.data?.data.length) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: sitemaps.data.meta.page,
          totalPages: sitemaps.data.meta.totalPages,
          totalRecords: sitemaps.data.meta.totalRecords,
        },
      }));
    }
  }, [sitemaps.data?.meta]);

  useEffect(() => {
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      }
    }));
  }, [searchParams]);

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const columns = [
    {
      id: "id",
      label: "S. No.",
    },
    {
      id: "project",
      label: "Project",
    },
    {
      id: "urlCount",
      label: "Url Count",
    },
    {
      id: "updatedAt",
      label: "Updated At",
    },
    {
      id: "action",
      label: "Actions",
    }
  ];

  const viewSiteMap = (product: string) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/config/public/sitemap?_product=${product}`;
      window.open(url, "_blank");
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
    }
  };

  const handleGenerateSitemap = async (_product: string) => {
  try {
    const response = await generateSitemap({_product});
    snackbar(response.message, "info");
    sitemaps.refetch();
  } catch (error) {
    const err = error as IErrorResponse;
    snackbar(err?.data?.message, "error");
  }
};


  const createRow = (index: number, sitemap: ISitemapData, pagination: IPagination) => {
    const action = 
      <GetActions
        icons={[
          {
            name: "Generate",
            method: () => {
              handleGenerateSitemap(sitemap && sitemap._product && sitemap._product._id || "");
            },
            disabled: !resourceAllocate("cms-sitemap.read"),
          },
          {
            name: "View",
            method: () => {
                viewSiteMap(sitemap && sitemap._product && sitemap._product._id || "");
            },
            disabled: !resourceAllocate("cms-sitemap.read"),
          }
        ]}
      />;

    return {
      id: createIndex(pagination, index),
      project: sitemap._product && capitalize(sitemap._product.name) || "",
      urlCount: (sitemap.urlCount).toString(),
      updatedAt: sitemap.updatedAt && sitemap.updatedAt.toString() || "",
      action,
    };
  };

  if (sitemaps && sitemaps.data && sitemaps.data.data && sitemaps.data.data.length) {
    rows = sitemaps.data.data.map((sitemap, i) =>
      createRow(i, sitemap, state.pagination)
    );
  }

  return (
    <>
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 180px)"
          errorMessage="Add sitemaps to see the data here"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>
      <Outlet />
    </>
  );
};

export default Sitemap;