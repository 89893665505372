import { useEffect, useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { ChangeEvent } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Switch, Tooltip } from "@mui/material";
import { useSearchParams, Outlet } from "react-router-dom";
import useSnackbar from "../../../hooks/useSnackbar";
import useResource from "../../../hooks/useResource";
import useDebounce from "../../../hooks/useDebounce";
import Header from "../../../components/header";
import CustomTable from "../../../components/mui/table";
import { ICourseConfiguration, ICourseConfigurationRow, ICourseConfigurationState, IErrorResponse, IPagination } from "../../../interfaces";
import { capitalize, createIndex } from "../../../utilities/helper";
import GetActions from "../../../components/get-actions";
import WarningDialog from "../../../components/mui/warning-dialog";
import { courseConfigurationService } from "../../../services/configuration/course";
import CustomTypography from "../../../components/mui/max-length-limit";
import { ISkill } from "../../../interfaces/skill-matrix/add-skill-matrix";
import CourseConfigurationFilters from "./filters";


const CourseConfigurationList = () => {
  let rows: ICourseConfigurationRow[] = [];
  const { resourceAllocate } = useResource();
  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    deleteCourseConfiguration,
    getcourseConfigurationList,
    updateCourseConfiguration
  } = courseConfigurationService();

  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState<ICourseConfigurationState>({
    courseConfiguration: "",
    pagination: { page: 1, limit: 20, totalPages: 1 },
    multiDeleteWarning: false,
    selectAll: [],
    filterDialog: { anchorEl: null, isOpen: false },
    filters: {
      search: "",
      _department: [],
      _skillParam: [],
      activeStatus: [],
    },
    deleteWarning: false,
    filterCount: 0,
    searching: "",});

  const columns = [
    {
      id: "id",
      label: "S No.",
    },    
    {
      id: "name",
      label: "Name",
    },
    {
      id: "_skillParameter",
      label: "Skill Parameter",
    },
    {
      id: "_department",
      label: "Department",
    },
    {
      id: "isActive",
      label: "Active",
    },
    {
      id: "action",
      label: "Action",
    }
  ];

  const searchRecord = useDebounce(state.filters.search, 1000);

  useEffect(() => {
    if (searchRecord.length) {
      setSearchParams((prev) => ({
        ...prev,
        page: 1,
        search: searchRecord,
      }));
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  }, [searchRecord]);

  const getCourseConfiguration = useQuery({
    queryKey: [
      "CourseConfigurationList",
      state.pagination.page,
      state.searching,
      state.filters,
    ],
    queryFn: () =>
      getcourseConfigurationList({
        pagination: true,
        page: state.pagination.page,
        limit: state.pagination.limit,
        ...state.filters,
      }),
  });

  useEffect(() => {
    if (
      getCourseConfiguration.data &&
      getCourseConfiguration.data.data &&
      getCourseConfiguration.data.data.length
    ) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: getCourseConfiguration.data.meta.page,
          totalPages: getCourseConfiguration.data.meta.totalPages,
          totalRecords: getCourseConfiguration.data.meta.totalRecords,
        },
      }));
    }
  }, [getCourseConfiguration.data?.meta]);

  useEffect(() => {
    let filterCount = 0;
    const page = searchParams.get("page")
      ? Number(searchParams.get("page"))
      : 1;
    const search = searchParams.get("search")
      ? String(searchParams.get("search"))
      : "";
    const _department: { key: string, value: string }[] = searchParams.get("_department") ? JSON.parse(String(searchParams.get("_department"))) : [];
    const _skillParam: { key: string, value: string }[] = searchParams.get("_skillParam") ? JSON.parse(String(searchParams.get("_skillParam"))) : [];
    const activeStatus: { key: string, value: string }[] = searchParams.get("activeStatus") ? JSON.parse(String(searchParams.get("activeStatus"))) : [];
  
    filterCount += _department.length ? 1 : 0;
    filterCount += _skillParam.length ? 1 : 0;
    filterCount += activeStatus.length ? 1 : 0;

    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page,
      },
      filters: {
        ...prevState.filters,
        search,
        _department: _department.map(item =>  item.key),
        _skillParam: _skillParam.map(item => item.key),
        activeStatus: activeStatus.map( item => item.key),
      },
      filterCount,
    }));
  }, [searchParams]);

  const isActiveChange = async (event: ChangeEvent<HTMLInputElement>, checked: string, _id: string) => {
    try {
      const isActive = await updateCourseConfiguration({ _id, isActive: checked });
      snackbar(isActive.message, "info");
      getCourseConfiguration.refetch();
    } catch(error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "error");
    }
  };

  const handleDelete = (courseConfiguration = "") => {
    setState((prevState) => ({
      ...prevState,
      deleteWarning: !prevState.deleteWarning,
      courseConfiguration,
    }));
  };

  const onDelete = async () => {
    try {
      const deleted = await deleteCourseConfiguration({
        _id: state.courseConfiguration,
      });
      snackbar(deleted.message, "info");
      handleDelete();
      getCourseConfiguration.refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) =>
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        search: e.target.value,
      },
    }));

  const openFilter = (e: MouseEvent<HTMLButtonElement>) =>
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        anchorEl: e.currentTarget,
        isOpen: !state.filterDialog.isOpen,
      },
    }));

  const closeFilter = () => {
    setState((prevState) => ({
      ...prevState,
      filterDialog: {
        ...prevState.filterDialog,
        isOpen: false,
      },
    }));
  };

  useEffect(() => {
    if (getCourseConfiguration.data && getCourseConfiguration.data.data && getCourseConfiguration.data.data.length === 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: prevState.pagination.page !== 1 ? prevState.pagination.page - 1 : 1,
          totalPages: 1,
          totalRecords: 0,
        },
      }));
    }
  }, [getCourseConfiguration.data && getCourseConfiguration.data.data]);
  
  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const createRow = (
    index: number,
    courseConfiguration: ICourseConfiguration,
    pagination: IPagination
  ) => {
    const action = (
      <GetActions
        icons={[
          {
            name: "Edit",
            method: () =>
              navigate({
                pathname: `manage/${courseConfiguration._id}`,
                search: searchParams.toString(),
              }),
            disabled: !resourceAllocate("configuration-course.edit"),
          },
          {
            name: "Delete",
            method: () => handleDelete(courseConfiguration?._id),
            disabled: !(resourceAllocate("configuration-course.remove") || resourceAllocate("course-assignment.removeAssigned"))
          },
        ]}
      />
    );  
    const skillParameter = <Tooltip title={courseConfiguration?._skillParameter?.map(param => param.name).join(", ") || "N/A"}>
    <span>
      {courseConfiguration && courseConfiguration._skillParameter && courseConfiguration._skillParameter.length > 0 ? (
        <>
          {capitalize((courseConfiguration._skillParameter[0] as ISkill).name)}{" "}
          {courseConfiguration._skillParameter.length > 1 && (
            <span style={{ color: "var(--primary-color)" }}>+{courseConfiguration._skillParameter.length - 1}</span>
          )}
        </>
      ) : (
        "N/A"
      )}
    </span>
  </Tooltip>;
    

    return {
      id: createIndex(pagination, index),
      name: 
      <CustomTypography
          limit={50}
          label={courseConfiguration?.name ? capitalize(courseConfiguration?.name || "") : "N/A"}
          color="primary"
          onClick={
            (resourceAllocate("configuration-course-section.read"))
              ? () => {
                navigate(`course-section/${courseConfiguration._id}`);
                }
              : undefined
          }
        />,
      _skillParameter: skillParameter,
      _department: capitalize(courseConfiguration?._department?.name) || "N/A",
      createdBy: courseConfiguration?.createdBy?.name || "N/A",
      isActive: <Switch
          disabled={!resourceAllocate("configuration-course.write")}
          onChange={(event ,checked) => isActiveChange(event, String(checked), courseConfiguration._id)}
          checked={!!courseConfiguration.isActive}
        />,
      action,
    };
  };
  
  if (
    getCourseConfiguration.data &&
    getCourseConfiguration.data.data &&
    getCourseConfiguration.data.data.length
  ) {
    rows =
      getCourseConfiguration.data.data.map((courseConfiguration, i) =>
        createRow(i, courseConfiguration, state.pagination)
      );
  }

  return (
    <>
      <Header
        className="my-2"
        btnText="Add Course Configuration"
        searchPlaceholder="Search by course name"
        onSearch={onSearch}
        onBtnClick={
          resourceAllocate("configuration-course.write")
            ? () =>
                navigate({ pathname: "manage/new", search: searchParams.toString() })
            : undefined
        }
        onFilter={openFilter}
        filterCount={state.filterCount}
      >
        <CourseConfigurationFilters
          anchorEl={state.filterDialog.anchorEl}
          isOpen={state.filterDialog.isOpen}
          OnCloseFilter={closeFilter}
        />
      </Header>

      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={rows}
          height="calc(100vh - 250px)"
          pagination={state.pagination}
          onPageChange={onPageChange}
        />
      </Box>

      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete Course Configuration"
        description="Are you sure you want to delete this course configuration?"
      />

      <Outlet context={{ reFetch: getCourseConfiguration.refetch }} />
    </>
  );
};
export default CourseConfigurationList;
