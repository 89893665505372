import { Box, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import TimelineIcon from "@mui/icons-material/Timeline";
import dayjs from "dayjs";
import { UsersService } from "../../../../../services";
import { useQuery } from "@tanstack/react-query";
import { IUserActivity } from "../../../../../interfaces";
import { formatDate } from "../../../../../utilities/helper";
import { FC } from "react";

interface ISkillProps {
  _skillParameter?: string;
  _skillCategory?: string;
  _skillItem?: string;
}

const UserTimeline: FC<ISkillProps> = ({ 
  _skillParameter, 
  _skillCategory, 
  _skillItem 
}) => {
  const { id } = useParams();
  const theme = useTheme();

  const { getUserTimeline } = UsersService();


  const userTimeline = useQuery({
    queryKey: ["user-timeline-skill-pari"],
    queryFn: () => getUserTimeline({ 
      ...(id && !_skillParameter && !_skillCategory && !_skillItem && { _user: id }),
      ...(_skillCategory && { _skillCategory }),
      ...(_skillParameter && { _skillParameter }),
      ...(_skillItem && { _skillItem })
    })
  });
  const groupByDate = (array: IUserActivity[] | undefined) =>
    array && array.reduce((acc: { [key: string]: IUserActivity[] }, obj) => {
      const istDate = new Date(obj.createdAt);
      const dateKey = formatDate(istDate);
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(obj);
      return acc;
    }, {});

  const activities = groupByDate(userTimeline?.data?.data);
  const dateIsSame = (date1: Date | string, date2: Date | string) => dayjs(date1).startOf("day").isSame(dayjs(date2).startOf("day"));

  return (
    <Box paddingTop="10px">
      <Box height="56vh" overflow="auto" paddingTop="10px">

        <div id="timeline" style={{ height: "calc(100% - 50px)", overflow: "scroll" }}>
          {activities && Object.keys(activities).length ? (
            Object.entries(activities).reverse().map(([date, activityArray], index) => (
              <Box key={index} display="flex">
                <Box
                  sx={{ backgroundColor: dateIsSame(date, new Date()) ? theme.palette.primary.main : theme.palette.grey[200] }}
                  className= {dateIsSame(date, new Date()) ? "text-white" : undefined}
                >
                  <Typography
                    className="center h-100"
                    sx={{ width: "200px" }}
                    variant="h5"
                  >
                    {formatDate(date)}
                  </Typography>
                </Box>
                <div className="timelineBox w-100">
                  {[...activityArray].reverse().map((activity, idx) => (
                    <div
                      key={idx}
                      className="timelineDescBox py-3 pl-5"
                    >
                      <Typography className="time" sx={{ width: "150px" }} variant="body1">
                        {dayjs(activity.createdAt).format("LT")}
                      </Typography>
                      <Typography className="desc" variant="body2">
                        {activity.message}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Box>
            ))
          ) : (
            <Box className="no-data center" height={"calc(100% - 30px)"}>
              <TimelineIcon fontSize="large" className="error-icon" />
              <Typography className="noData-title">No data to show!</Typography>
              <Typography className="noData-sub-title">
                Make an action to see the timeline here
              </Typography>
            </Box>
          )}
        </div>
        <Box paddingTop={3} className="center" justifyContent="start">
          <Box className="center" justifyContent="start">
            <div className="identify-box primary-bg-color" />
            <Typography className="ml-2" variant="body1">
              Today
            </Typography>
          </Box>
          <Box className="center" justifyContent="start" marginLeft="30px">
            <div className="identify-box grey-color" />
            <Typography className="ml-2" variant="body1">
              Earlier Dates
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserTimeline;
