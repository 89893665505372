import { FC, useEffect, useState } from "react";
import { capitalize, checkTimePeriod } from "../../../../utilities/helper";
import { MenuItem } from "../../../../components/shared/filter";
import { useNavigate, useSearchParams } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { ISkillDevelopmentFilter } from "../../../../interfaces/skill-development";
import { useQuery } from "@tanstack/react-query";
import { courseConfigurationService } from "../../../../services/configuration/course";

interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnCloseFilter: () => void;
}

const SkillDevelopmentFilter: FC<props> = ({ anchorEl, isOpen, OnCloseFilter }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getPartialcourseConfiguration } = courseConfigurationService();
    const coursePartial  = useQuery({
      queryKey: ["partial-course-configuration"],
      queryFn: () => getPartialcourseConfiguration(),
    });
    const [state, setState] = useState<ISkillDevelopmentFilter>({
        selectedMenu: 0,
        courseStartDate: [],
        completionDate: [],
        courseEndDate: [],
        course: [],
        status: [],
    });

    useEffect(() => {
        const course: { key: string, value: string }[] = searchParams.get("course") ? JSON.parse(String(searchParams.get("course"))) : [];
        const status: { key: string, value: string }[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];
        const courseStartDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("courseStartDate") ? JSON.parse(String(searchParams.get("courseStartDate"))) : [];
        const completionDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("completionDate") ? JSON.parse(String(searchParams.get("completionDate"))) : [];
        const courseEndDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("courseEndDate") ? JSON.parse(String(searchParams.get("courseEndDate"))) : [];
        setState(prevState => ({
            ...prevState,
            course,
            status,
            courseStartDate,
            courseEndDate,
            completionDate
        }));
    }, [searchParams]);

    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };
    const statuses = [
        { key: "ASSIGNED", name: "ASSIGNED" },
        { key: "COMPLETED", name: "COMPLETED" },
        { key: "RE-ASSIGNED", name: "RE-ASSIGNED" },
        { key: "CANCELLED", name: "CANCELLED" },
    ];

    const handleRightListItem = (name: "status" | "course" , key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];
        const isExist = !!state[name].find(ele => ele.key === key);
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }

        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };


    const handleDateListCompletion = (name: "completionDate", key: string, value: string) => {
        const completionDate = checkTimePeriod(key);
    
        const payload: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [{
          key,
          value,
          startDate: completionDate.startDate,
          endDate: completionDate.endDate,
        }];
    
        setState(prevState => ({
          ...prevState,
          [name]: payload
        }));
      };
    
      const handleDateCompletion = (e: dayjs.Dayjs | null, period: "start" | "end") => {
        const newDoj = e ? dayjs(e).toISOString() : "";
        let completionDate: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [];
    
        if (state.completionDate.length && period === "start") {
            completionDate = state.completionDate.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
        } else if (state.completionDate.length && period === "end") {
            completionDate = state.completionDate.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
        } else if (!state.completionDate.length && period === "start") {
          const currentDate = new Date();
          currentDate.setHours(23,59,59);
          completionDate = [{
            key: "custom",
            value: "custom", 
            startDate:  dayjs(e).startOf("day").toISOString(),
            endDate: currentDate.toISOString()
          }];
        } else {
          const currentDate = new Date();
          currentDate.setHours(0,0,0,0);
          completionDate = [{
            key: "custom",
            value: "custom",
            startDate: currentDate.toISOString(),
            endDate: dayjs(e).endOf("day").toISOString(),
          }];
        }
    
        setState(prevState => ({
          ...prevState,
          completionDate
        }));
      };

      const handleDateListEnd = (name: "courseEndDate", key: string, value: string) => {
        const courseEndDate = checkTimePeriod(key);
    
        const payload: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [{
          key,
          value,
          startDate: courseEndDate.startDate,
          endDate: courseEndDate.endDate,
        }];
    
        setState(prevState => ({
          ...prevState,
          [name]: payload
        }));
      };
    
      const handleEndDate = (e: dayjs.Dayjs | null, period: "start" | "end") => {
        const newDoj = e ? dayjs(e).toISOString() : "";
        let courseEndDate: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [];
    
        if (state.courseEndDate.length && period === "start") {
          courseEndDate = state.courseEndDate.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
        } else if (state.courseEndDate.length && period === "end") {
          courseEndDate = state.courseEndDate.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
        } else if (!state.courseEndDate.length && period === "start") {
          const currentDate = new Date();
          currentDate.setHours(23,59,59);
          courseEndDate = [{
            key: "custom",
            value: "custom", 
            startDate:  dayjs(e).startOf("day").toISOString(),
            endDate: currentDate.toISOString()
          }];
        } else {
          const currentDate = new Date();
          currentDate.setHours(0,0,0,0);
          courseEndDate = [{
            key: "custom",
            value: "custom",
            startDate: currentDate.toISOString(),
            endDate: dayjs(e).endOf("day").toISOString(),
          }];
        }
    
        setState(prevState => ({
          ...prevState,
          courseEndDate
        }));
      };

      const handleDateListItem = (name: "courseStartDate", key: string, value: string) => {
        const courseStartDate = checkTimePeriod(key);
    
        const payload: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [{
          key,
          value,
          startDate: courseStartDate.startDate,
          endDate: courseStartDate.endDate,
        }];
    
        setState(prevState => ({
          ...prevState,
          [name]: payload
        }));
      };
    
      const handleDateSubmission = (e: dayjs.Dayjs | null, period: "start" | "end") => {
        const newDoj = e ? dayjs(e).toISOString() : "";
        let courseStartDate: Array<{
          key: string;
          value: string;
          startDate: string,
          endDate: string,
        }> = [];
    
        if (state.courseStartDate.length && period === "start") {
          courseStartDate = state.courseStartDate.map(e => ({ ...e, startDate: newDoj, value: "custom", key: "Custom" }));
        } else if (state.courseStartDate.length && period === "end") {
          courseStartDate = state.courseStartDate.map(e => ({ ...e, endDate: newDoj, value: "custom", key: "Custom" }));
        } else if (!state.courseStartDate.length && period === "start") {
          const currentDate = new Date();
          currentDate.setHours(23,59,59);
          courseStartDate = [{
            key: "custom",
            value: "custom", 
            startDate:  dayjs(e).startOf("day").toISOString(),
            endDate: currentDate.toISOString()
          }];
        } else {
          const currentDate = new Date();
          currentDate.setHours(0,0,0,0);
          courseStartDate = [{
            key: "custom",
            value: "custom",
            startDate: currentDate.toISOString(),
            endDate: dayjs(e).endOf("day").toISOString(),
          }];
        }
    
        setState(prevState => ({
          ...prevState,
          courseStartDate
        }));
      };

    const deleteChip = (name: "courseStartDate" | "completionDate" | "courseEndDate" |"course" | "status", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];
        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            course: [],
            status: [],
            courseStartDate: [],
            courseEndDate: [],
            completionDate: []
        });
    };

    const onApply = () => {
        searchParams.set("courseStartDate", JSON.stringify(state.courseStartDate));
        searchParams.set("completionDate", JSON.stringify(state.completionDate));
        searchParams.set("courseEndDate", JSON.stringify(state.courseEndDate));
        searchParams.set("course", JSON.stringify(state.course));
        searchParams.set("status", JSON.stringify(state.status));

        searchParams.set("page", "1");
        navigate({
            pathname: "/skill-development/assigned-course-list",
            search: searchParams.toString()
        });
        OnCloseFilter();
    };

    const onClose = () => {
        const courseStartDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("courseStartDate") ? JSON.parse(String(searchParams.get("courseStartDate"))) : [];
        const completionDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("completionDate") ? JSON.parse(String(searchParams.get("completionDate"))) : [];
        const courseEndDate: { key: string, value: string, startDate: string, endDate: string }[] = searchParams.get("courseEndDate") ? JSON.parse(String(searchParams.get("courseEndDate"))) : [];
        const status: { key: string, value: string}[] = searchParams.get("status") ? JSON.parse(String(searchParams.get("status"))) : [];

        setState(prevState => ({
            ...prevState,
            courseStartDate,
            completionDate,
            courseEndDate,
            status
        }));
        OnCloseFilter();
    };

    return (
      <>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={onClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Box id="filters-container">
            <Box
              className="center mb-3"
              justifyContent="space-between"
              alignItems="start"
            >
              <div className="active-filter mb-1">
                {state.courseStartDate.length ||
                state.completionDate.length ||
                state.course.length ||
                state.status.length ||
                state.courseEndDate.length ? (
                  <>
                    {state.courseStartDate.map((ele) => (
                      <Chip
                        key={ele.key}
                        className="m-1"
                        icon={<CalendarMonthIcon />}
                        color="primary"
                        onDelete={() => deleteChip("courseStartDate", ele.key)}
                        label={ele.value}
                        variant="outlined"
                      />
                    ))}
                    {state.completionDate.map((ele) => (
                      <Chip
                        key={ele.key}
                        className="m-1"
                        icon={<CalendarMonthIcon />}
                        color="primary"
                        onDelete={() => deleteChip("completionDate", ele.key)}
                        label={ele.value}
                        variant="outlined"
                      />
                    ))}
                    {state.courseEndDate.map((ele) => (
                      <Chip
                        key={ele.key}
                        className="m-1"
                        icon={<CalendarMonthIcon />}
                        color="primary"
                        onDelete={() => deleteChip("courseEndDate", ele.key)}
                        label={ele.value}
                        variant="outlined"
                      />
                    ))}                    
                    {state.course.map((ele) => (
                      <Chip
                        key={ele.key}
                        className="m-1"
                        color="primary"
                        onDelete={() => deleteChip("course", ele.key)}
                        label={ele.value}
                        variant="outlined"
                      />
                    ))}                    
                    {state.status.map((ele) => (
                      <Chip
                        key={ele.key}
                        className="m-1"
                        color="primary"
                        onDelete={() => deleteChip("status", ele.key)}
                        label={ele.value}
                        variant="outlined"
                      />
                    ))}
                  </>
                ) : (
                  <Box className="mt-2" display="flex" alignItems="center">
                    <FilterAltOffIcon />
                    <Typography className="ml-2">
                      No filters are applied
                    </Typography>
                  </Box>
                )}
              </div>
              <IconButton
                onClick={OnCloseFilter}
                style={{ marginRight: "-10px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Grid className="filter-box" container>
              <Grid id="left" item xs={5}>
                <List component="nav">
                  <MenuItem
                    index={0}
                    label="Start Date"
                    selectedMenu={state.selectedMenu === 0}
                    onChange={handleLeftListItem}
                    count={state.courseStartDate}
                  />

                  <MenuItem
                    index={1}
                    label="Completion Date"
                    selectedMenu={state.selectedMenu === 1}
                    onChange={handleLeftListItem}
                    count={state.completionDate}
                  />

                  <MenuItem
                    index={2}
                    label="End Date"
                    selectedMenu={state.selectedMenu === 2}
                    onChange={handleLeftListItem}
                    count={state.courseEndDate}
                  />

                  <MenuItem
                    index={3}
                    label="Course"
                    selectedMenu={state.selectedMenu === 3}
                    onChange={handleLeftListItem}
                    count={state.course}
                  />

                  <MenuItem
                    index={4}
                    label="Status"
                    selectedMenu={state.selectedMenu === 4}
                    onChange={handleLeftListItem}
                    count={state.status}
                  />
                </List>
              </Grid>
              <Divider orientation="vertical" />

              <Grid id="right" item xs={6}>
                <List component="nav">
                  {state.selectedMenu === 0 && (
                    <>
                      {[
                        { key: "yesterday", value: "Yesterday" },
                        { key: "today", value: "Today" },
                        { key: "thisWeek", value: "Weekly" },
                        { key: "thisMonth", value: "Monthly" },
                        { key: "thisQuarter", value: "Quarterly" },
                      ]?.map((courseStartDate) => (
                        <ListItemButton
                          key={courseStartDate.key}
                          selected={
                            !!state.courseStartDate.find(
                              (ele) => ele.key === courseStartDate.key
                            )
                          }
                          onClick={() =>
                            handleDateListItem(
                              "courseStartDate",
                              courseStartDate.key,
                              capitalize(courseStartDate.value)
                            )
                          }
                        >
                          <ListItemText primary={courseStartDate.value} />
                          <Checkbox
                            edge="end"
                            checked={
                              !!state.courseStartDate.find(
                                (ele) => ele.key === courseStartDate.key
                              )
                            }
                          />
                        </ListItemButton>
                      ))}
                      <Box marginTop={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            value={
                              state.courseStartDate[0]?.startDate
                                ? dayjs(state.courseStartDate[0]?.startDate)
                                : null
                            }
                            onChange={(e) => handleDateSubmission(e, "start")}
                            label="Start Date"
                            format="LL"
                          />
                        </LocalizationProvider>
                        <div className="mt-3" />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            value={
                              state.courseStartDate[0]?.endDate
                                ? dayjs(state.courseStartDate[0]?.endDate)
                                : null
                            }
                            onChange={(e) => handleDateSubmission(e, "end")}
                            label="End Date"
                            format="LL"
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  )}
                  {state.selectedMenu === 1 && (
                    <>
                      {[
                        { key: "yesterday", value: "Yesterday" },
                        { key: "today", value: "Today" },
                        { key: "thisWeek", value: "Weekly" },
                        { key: "thisMonth", value: "Monthly" },
                        { key: "thisQuarter", value: "Quarterly" },
                      ]?.map((completionDate) => (
                        <ListItemButton
                          key={completionDate.key}
                          selected={
                            !!state.completionDate.find(
                              (ele) => ele.key === completionDate.key
                            )
                          }
                          onClick={() =>
                            handleDateListCompletion(
                              "completionDate",
                              completionDate.key,
                              capitalize(completionDate.value)
                            )
                          }
                        >
                          <ListItemText primary={completionDate.value} />
                          <Checkbox
                            edge="end"
                            checked={
                              !!state.completionDate.find(
                                (ele) => ele.key === completionDate.key
                              )
                            }
                          />
                        </ListItemButton>
                      ))}
                      <Box marginTop={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            value={
                              state.completionDate[0]?.startDate
                                ? dayjs(state.completionDate[0]?.startDate)
                                : null
                            }
                            onChange={(e) => handleDateCompletion(e, "start")}
                            label="Start Date"
                            format="LL"
                          />
                        </LocalizationProvider>
                        <div className="mt-3" />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            value={
                              state.completionDate[0]?.endDate
                                ? dayjs(state.completionDate[0]?.endDate)
                                : null
                            }
                            onChange={(e) => handleDateCompletion(e, "end")}
                            label="End Date"
                            format="LL"
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  )}
                  {state.selectedMenu === 2 && (
                    <>
                      {[
                        { key: "yesterday", value: "Yesterday" },
                        { key: "today", value: "Today" },
                        { key: "thisWeek", value: "Weekly" },
                        { key: "thisMonth", value: "Monthly" },
                        { key: "thisQuarter", value: "Quarterly" },
                      ]?.map((courseEndDate) => (
                        <ListItemButton
                          key={courseEndDate.key}
                          selected={
                            !!state.courseEndDate.find(
                              (ele) => ele.key === courseEndDate.key
                            )
                          }
                          onClick={() =>
                            handleDateListEnd(
                              "courseEndDate",
                              courseEndDate.key,
                              capitalize(courseEndDate.value)
                            )
                          }
                        >
                          <ListItemText primary={courseEndDate.value} />
                          <Checkbox
                            edge="end"
                            checked={
                              !!state.courseEndDate.find(
                                (ele) => ele.key === courseEndDate.key
                              )
                            }
                          />
                        </ListItemButton>
                      ))}
                      <Box marginTop={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            value={
                              state.courseEndDate[0]?.startDate
                                ? dayjs(state.courseEndDate[0]?.startDate)
                                : null
                            }
                            onChange={(e) => handleEndDate(e, "start")}
                            label="Start Date"
                            format="LL"
                          />
                        </LocalizationProvider>
                        <div className="mt-3" />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            value={
                              state.courseEndDate[0]?.endDate
                                ? dayjs(state.courseEndDate[0]?.endDate)
                                : null
                            }
                            onChange={(e) => handleEndDate(e, "end")}
                            label="End Date"
                            format="LL"
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  )}
                  {state.selectedMenu === 3 &&
                    coursePartial?.data?.data.map((course) => (
                      <ListItemButton
                        key={course._id}
                        selected={
                          !!state.course.find(
                            (ele) => ele.key === course._id
                          )
                        }
                        onClick={() =>
                          handleRightListItem(
                            "course",
                            course._id,
                            capitalize(course.name)
                          )
                        }
                      >
                        <ListItemText primary={course.name} />
                        <Checkbox
                          edge="end"
                          checked={
                            !!state.course.find(
                              (ele) => ele.key === course._id
                            )
                          }
                        />
                      </ListItemButton>
                    ))}
                  {state.selectedMenu === 4 &&
                    statuses.map((status) => (
                      <ListItemButton
                        key={status.key}
                        selected={
                          !!state.status.find((ele) => ele.key === status.key)
                        }
                        onClick={() =>
                          handleRightListItem(
                            "status",
                            status.key,
                            capitalize(status.name)
                          )
                        }
                      >
                        <ListItemText primary={capitalize(status.name)} />
                        <Checkbox
                          edge="end"
                          checked={
                            !!state.status.find((ele) => ele.key === status.key)
                          }
                        />
                      </ListItemButton>
                    ))}
                </List>
              </Grid>
            </Grid>
            <Box className="actions-btn" marginTop="8px" textAlign="end">
              <Button
                variant="outlined"
                color="error"
                onClick={() => resetFilter()}
              >
                Clear All
              </Button>
              <Button className="ml-2" onClick={onApply}>
                Apply
              </Button>
            </Box>
          </Box>
        </Menu>
      </>
    );
};

export default SkillDevelopmentFilter;