import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { OfferedService } from "../../../../services";
import { capitalize, displayName } from "../../../../utilities/helper";
import { validateEmail } from "../../../../validations/shared";
import { FC, KeyboardEvent, useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { offeredMailValidation } from "../../../../validations";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { toIndianRupee, replaceHtmlKeyword, formatDateTime } from "../../../../utilities/helper";
import { Box, Button, Chip, Divider, FormHelperText, Grid, MenuItem, Slider, TextField } from "@mui/material";
import { IOfferedMailField, IOfferedMail, ITemplate, IErrorResponse, IUser, IJobPosition, ICandidateDetails } from "../../../../interfaces";
import dayjs from "dayjs";
import useUser from "../../../../hooks/useUser";
import Select from "../../../../components/mui/select";
import useSnackbar from "../../../../hooks/useSnackbar";
import TinyEditor from "../../../../components/text-editor";
import EmailSuggestion from "../../../../components/mui/email-suggestion";
interface props {
    templates: ITemplate[],
    candidate: ICandidateDetails | undefined;
    keywords: { [key: string]: string | number }
    candidatesRefetch: () => void;
    isTabChanged: number
}

export const stipendMarks = [
    {
        value: 1000,
        label: "1,000",
    },
    {
        value: 50000,
        label: "50,000",
    },
    {
        value: 90000,
        label: "90,000",
    },
];

const Mail: FC<props> = ({ templates, candidate, keywords, candidatesRefetch, isTabChanged }) => {
    const { sendOfferedEmail } = OfferedService();
    const navigate = useNavigate();
    const { snackbar } = useSnackbar();
    const { user } = useUser();
    const [searchParam] = useSearchParams();
    const users = useSelector<{ user: { list: IUser[] } }, IUser[]>(state => state.user.list);
    const jobPositions = useSelector<{ jobPosition: { list: IJobPosition[] } }, IJobPosition[]>(state => state.jobPosition.list);
    const { control, getValues, setValue, resetField, trigger, setError, clearErrors, watch, handleSubmit, reset, formState: { errors } } = useForm<IOfferedMail>({
        resolver: joiResolver(offeredMailValidation),
        defaultValues: {
            to: "",
            template: "",
            ccText: "",
            bccText: "",
            businessTitle: "",
            reportingManager: "",
            stipend: "",
            doj: "",
            subject: "",
            content: "",
            amount: 1000
        }
    });
    const [state, setState] = useState({
        stipendRange: 1000
    });

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "template") {
                const template = templates.find(template => template._id === value.template);
                setValue("subject", String(template?.email.subject));
                setValue("content", String(template?.email.content));
                trigger("content");
            } else if (name === "amount") {
                if (value.amount && (Number(value.amount) > Number(state.stipendRange))) {
                    setError("amount", { message: "The amount must be between the given range" });
                } else {
                    clearErrors("amount");
                }
            } else if (name && ["to", "bccText", "ccText"].includes(name)) {
                trigger(name);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, state.stipendRange]);

    useEffect(() => {
        if (candidate) {
            setValue("to", candidate.email);
        }

        if (user) {
            const cc = getValues("cc") || [];
            cc.push(user?.email);
            setValue("cc", [...new Set(cc)]);
            trigger("cc");
        }
    }, [candidate, user, isTabChanged]);

    const addEmail = (e: KeyboardEvent<HTMLDivElement>, key: string) => {
        let payload: string[] = [];

        if (key === "ccText" || key === "bccText") {
            const err = validateEmail(getValues(key));
            if (err.error) {
                return;
            }

            const enteredEmail = getValues(key) ? String(getValues(key)) : "";

            if (enteredEmail.trim() !== "" && e.key === "Enter") {
                if (key === "ccText") {
                    const prev = getValues("cc") ? getValues("cc") : [];
                    payload = [...prev, enteredEmail];
                    setValue("cc", [...new Set(payload)]);
                    resetField(key);
                } else if (key === "bccText") {
                    const prev = getValues("bcc") ? getValues("bcc") : [];
                    payload = [...prev, enteredEmail];
                    setValue("bcc", [...new Set(payload)]);
                    resetField(key);
                }
            }
        }
    };

    const handleEmailSelect = (email: string, name: string) => {
        let payload: string[] = [];
        if (name === "to") {
            setValue("to", email);
        } else if (["ccText", "bccText"].includes(name)) {
            if (name === "ccText") {
                const prev = getValues("cc") ? getValues("cc") : [];
                payload = [...prev, email];
                setValue("cc", [...new Set(payload)]);
                resetField(name);

            } else if (name === "bccText") {
                const prev = getValues("bcc") ? getValues("bcc") : [];
                payload = [...prev, email];
                setValue("bcc", [...new Set(payload)]);
                resetField(name);
            }
        }
    };

    const removeEmail = (key: string, value: string) => {
        if (key === "ccText") {
            let payload = getValues("cc");
            payload = payload.filter(email => email !== value);
            setValue("cc", payload);
            trigger("cc");
        } else if (key === "bccText") {
            let payload = getValues("bcc");
            payload = payload.filter(email => email !== value);
            setValue("bcc", payload);
            trigger("bcc");
        }
    };

    // eslint-disable-next-line
    const selectDate = (value: any, name: string) => {
        const date = value && dayjs(value).toString() !== "Invalid Date" ? dayjs(value)?.toISOString() : undefined;
        const keyExist = name === "doj";
        if (keyExist) {
            setValue(name, date);
            trigger(name);
        }
    };

    const onSubmit = async (data: IOfferedMail) => {
        const moreKeywords = {
            ...keywords,
            stipend: selectedType === "manual" ? data.amount || "" : data.stipend || "",
            reportingManager: capitalize(data?.reportingManager || ""),
            businessTitle: capitalize(data?.businessTitle || ""),
            DOJ: formatDateTime(data?.doj || ""),
        };
        const payload = {
            ...data,
            subject: replaceHtmlKeyword(data.subject, moreKeywords),
            content: replaceHtmlKeyword(data.content, moreKeywords)
        };
        if (selectedType === "manual") {
            payload.stipend = data.amount;
            delete payload.amount;
        } else {
            delete payload.amount;
        }

        delete payload.ccText;
        delete payload.bccText;
        delete payload.template;

        try {
            const emailSent = await sendOfferedEmail({
                type: "candidate_email",
                _lead: candidate?._id,
                ...payload,
                to: [payload.to],
            });
            navigate({
                pathname: "/offered",
                search: searchParam.toString()
            });
            snackbar(emailSent?.message, "info");
            candidatesRefetch();
        } catch (error) {
            const err = error as IErrorResponse;
            snackbar(err?.data?.message, "warning");
            console.log(error);
        }
    };

    const selectedType = watch("stipend");

    const fields: IOfferedMailField[] = [
        {
            type: "input",
            name: "to",
            label: "To*",
        },
        {
            type: "select",
            name: "template",
            label: "Template",
            children: templates.map(template => <MenuItem key={template._id} value={template._id}>{capitalize(template.title)}</MenuItem>)
        },
        {
            type: "input",
            name: "ccText",
            label: "CC",
            placeholder: "Type email address and press enter"
        },
        {
            type: "input",
            name: "bccText",
            label: "BCC",
            placeholder: "Type email address and press enter"
        },
        {
            type: "select",
            name: "businessTitle",
            label: "Business Title*",
            children: [
                ...jobPositions.map((jobPosition, i) => <MenuItem key={i} value={jobPosition.name}>{capitalize(jobPosition.name)}</MenuItem>)
            ]
        },
        {
            type: "select",
            name: "reportingManager",
            label: "Reporting Manager*",
            children: [
                ...users.map((user, i) => <MenuItem key={i} value={`${displayName(user)}`}>{`${capitalize(displayName(user))}`}</MenuItem>)
            ]
        },
        {
            type: "select",
            name: "stipend",
            label: "Stipend*",
            children: [
                <MenuItem key={"5000"} value={5000} > {toIndianRupee(5000)}</MenuItem>,
                <MenuItem key={"10000"} value={10000} > {toIndianRupee(10000)}</MenuItem>,
                <MenuItem key={"15000"} value={15000} > {toIndianRupee(15000)}</MenuItem>,
                <MenuItem key={"20000"} value={20000} > {toIndianRupee(20000)}</MenuItem>,
                <MenuItem key={"25000"} value={25000} > {toIndianRupee(25000)}</MenuItem>,
                <MenuItem key={"30000"} value={30000} > {toIndianRupee(30000)}</MenuItem>,
                <MenuItem key={"manual"} value="manual" >Manual</MenuItem>,
            ]
        },
        {
            label: "Amount*",
            name: "amount",
            type: "slider",
        },
        {
            label: "DOJ*",
            name: "doj",
            type: "date",
        },
        {
            type: "input",
            name: "subject",
            label: "Subject*",
            placeholder: "Type your subject here",
            width: 12
        },
        {
            type: "text-editor",
            name: "content",
            label: "Content*"
        },
    ];

    const filteredFields = fields.filter((field) => {
        if (selectedType === "manual") {
            return field.name === "to" || field.name === "template" || field.name === "ccText" ||
                field.name === "bccText" || field.name === "businessTitle" || field.name === "reportingManager" ||
                field.name === "stipend" || field.name === "amount" || field.name === "doj" || field.name === "subject"
                || field.name === "content";
        } else {
            return field.name === "to" || field.name === "template" || field.name === "ccText" ||
                field.name === "bccText" || field.name === "businessTitle" || field.name === "reportingManager" ||
                field.name === "stipend" || field.name === "doj" || field.name === "subject" || field.name === "content";
        }
    });

    const onCopy = (url:string,label:string) => {
        navigator.clipboard.writeText(url);
        snackbar(`${capitalize(label)} ID copied to clipboard`, "info");
    };

    return (
        <Box paddingTop="10px">
            <Box sx={{ height: "37vh", overflow: "auto", padding: "10px 4px 0px" }}>
                <form onSubmit={handleSubmit(onSubmit)} onKeyDown={e => e.key === "Enter" && e.preventDefault()}>
                    <Grid container spacing={4}>
                        {
                            filteredFields.map(field => {
                                if (field.type === "input" && field.name === "to" || field.name === "ccText" || field.name === "bccText") {
                                    return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                        <EmailSuggestion
                                            control={control}
                                            label={field.label}
                                            name={field.name}
                                            value={getValues(field.name)}
                                            placeholder={field.placeholder}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                            onEmailSelect={handleEmailSelect}
                                            onKeyUp={addEmail}
                                        />
                                        {
                                            <Box>
                                                {
                                                    field.name === "ccText" && getValues("cc") &&
                                                    getValues("cc").map(email => <Chip
                                                        key={email}
                                                        label={email}
                                                        onDelete={() => removeEmail(field.name, email)}
                                                        color="primary"
                                                        variant="outlined"
                                                        sx={{ margin: "5px" }}
                                                        onClick = {() => onCopy(email,"email")}
                                                    />)
                                                    ||
                                                    field.name === "bccText" && getValues("bcc") &&
                                                    getValues("bcc").map(email => <Chip
                                                        key={email}
                                                        label={email}
                                                        onDelete={() => removeEmail(field.name, email)}
                                                        color="primary"
                                                        variant="outlined"
                                                        sx={{ margin: "5px" }}
                                                        onClick = { () =>  onCopy(email,"email")}
                                                    />)
                                                }
                                            </Box>
                                        }
                                    </Grid>
                                    );
                                } else if (field.type === "input") {
                                    return (<Grid key={field.label} item xs={12} md={field.width ? field.width : 6}>
                                        <Controller
                                            control={control}
                                            name={field.name}
                                            render={(prop) => <TextField
                                                label={field.label}
                                                className="disable-text"
                                                variant={"outlined"}
                                                size={"small"}
                                                placeholder={field.placeholder}
                                                error={errors[field.name] ? true : false}
                                                helperText={errors[field.name]?.message}
                                                {...prop.field}

                                            />}
                                        />
                                    </Grid>
                                    );
                                } else if (field.type === "text-editor") {
                                    return (<Grid key={field.label} item xs={12}>
                                        <Box className={errors[field.name] ? "tiny-error" : ""}>
                                            <TinyEditor value={getValues("content")} onChange={(e: string) => setValue("content", e)} height="375px" />
                                            {errors[field.name] && <FormHelperText sx={{ margin: "4px 14px 0px 14px" }} error={errors[field.name] ? true : false}>{errors[field.name]?.message}</FormHelperText>}
                                        </Box>
                                    </Grid>
                                    );
                                } else if (field.type === "slider") {
                                    return (<Grid key={field.label} item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Slider
                                                    getAriaLabel={() => "Temperature range"}
                                                    value={state.stipendRange}
                                                    min={1000}
                                                    max={100000}
                                                    onChange={(e, n) => {
                                                        setState(prevState => ({
                                                            ...prevState,
                                                            stipendRange: typeof n === "number" ? n : 10000
                                                        }));
                                                    }}
                                                    marks={stipendMarks}
                                                    valueLabelDisplay="auto"
                                                    valueLabelFormat={toIndianRupee}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Controller
                                                    control={control}
                                                    name={field.name}
                                                    render={(prop) => <TextField
                                                        label={field.label}
                                                        type="number"
                                                        className="disable-text"
                                                        variant={"outlined"}
                                                        size={"small"}
                                                        placeholder={field.placeholder}
                                                        error={errors[field.name] ? true : false}
                                                        helperText={errors[field.name]?.message}
                                                        {...prop.field}
                                                        inputProps={{ onWheel: e => e.currentTarget.blur() }}
                                                        onKeyDown={(e: any) => { // eslint-disable-line
                                                            const symbol = ["backspace"];
                                                            const exceptThisSymbols = ["e", "E", "+", "-", "."];
                                                            if (
                                                                (!symbol.includes(e.key.toLowerCase()) && e.target.value.length > 5) ||
                                                                exceptThisSymbols.includes(e.key.toLowerCase())
                                                            ) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    );
                                } else if (field.type === "date") {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                label={field.label}
                                                onChange={(e) => selectDate(e, field.name)}
                                                value={dayjs(getValues(field.name))}
                                                slotProps={{
                                                    textField: {
                                                        error: errors[field.name] ? true : false,
                                                        helperText: errors[field.name]?.message
                                                    }
                                                }}
                                                format="LL"
                                            />
                                        </LocalizationProvider>

                                    </Grid>
                                    );
                                }
                                else {
                                    return (<Grid key={field.label} item xs={12} md={6}>
                                        <Select
                                            control={control}
                                            className="disable-text"
                                            name={field.name}
                                            label={field.label}
                                            size={"small"}
                                            variant={"outlined"}
                                            error={errors[field.name] ? true : false}
                                            helperText={errors[field.name]?.message}
                                        >
                                            {field.children}
                                        </Select>
                                    </Grid>
                                    );
                                }
                            })
                        }
                    </Grid>
                    <Box className="action-box">
                        <Divider sx={{ marginBottom: "16px" }} />
                        <Button variant="outlined" onClick={() => reset()}>clear</Button>
                        <Button type="submit">Send</Button>
                    </Box>
                </form>
            </Box>
        </Box >
    );
};

export default Mail;