import { Route, Routes } from "react-router-dom";
import GlossaryList from "./glossary/list";
import ImportGlossary from "./glossary/import";
import ManageGlossary from "./glossary/manage";
import ScheduleGlossary from "./glossary/manage/schedule";
import ChecklistPublish from "./glossary/manage/checklistPublish";
import ChecklistWriter from "./glossary/manage/content/checkListWriter";
import ChecklistReviewer from "./glossary/manage/score/checkListReviewer";
import ViewVersionContent from "./glossary/manage/content-modal";

const GlossaryRouting = () => (
  <Routes>
    <Route path="/" element={<GlossaryList />}>
      <Route path="/import" element={<ImportGlossary />} />
    </Route>
    <Route path="/manage/:id" element={<ManageGlossary />}>
      <Route path="/manage/:id/schedule" element={<ScheduleGlossary />} />
      <Route
        path="/manage/:id/checklistPublish"
        element={<ChecklistPublish />}
      />
      <Route path="/manage/:id/checklistWriter" element={<ChecklistWriter />} />
      <Route
        path="/manage/:id/checklistReviewer"
        element={<ChecklistReviewer />}
      />
      <Route
        path="/manage/:id/view-content/:contentId"
        element={<ViewVersionContent />}
      />
    </Route>
  </Routes>
);
export default GlossaryRouting;
