import "../../../../components/shared/filter/style.scss";
import { FC, useEffect, useState } from "react";
import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, Typography } from "@mui/material";
import { IBlogTrashFilterState } from "../../../../interfaces";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { filterInitialState } from "../../../content-marketing-layout/blog/common/helper";
import { MenuItem } from "../../../../components/shared/filter";
import ProjectsService from "../../../../services/content/projects-products";
import { useQuery } from "@tanstack/react-query";
import CategoryService from "../../../../services/content/category";
import SubCategoryService from "../../../../services/content/sub-category";
import plannerService from "../../../../services/planner";


interface props {
    anchorEl: null | HTMLElement;
    isOpen: boolean;
    OnClose: () => void;
}

const TrashCmsFilters: FC<props> = ({ anchorEl, isOpen, OnClose }) => {
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getContentProjects } = ProjectsService();
    const { getContentCategorys } = CategoryService();
    const { getContentSubCategorys } = SubCategoryService();
    const { getPlannerList} = plannerService();
    const productListData = useQuery({ queryKey: ["allCmsProject"], queryFn: () => getContentProjects({}) });
    const getCmsCategory = useQuery({ queryKey: ["allCmsCategories"], queryFn: () => getContentCategorys({}) });
    const getCmsSubCategory = useQuery({ queryKey: ["allCmsSubcategories"], queryFn: () => getContentSubCategorys({}) });
    const getCmsQuarter = useQuery({ queryKey: ["allCmsQuarter"], queryFn: () => getPlannerList({}) });
    
    const productList = productListData?.data?.data || [];
    const categoryList = getCmsCategory?.data?.data || [];
    const subCategoryList = getCmsSubCategory?.data?.data || [];
    const quarterList = getCmsQuarter?.data?.data || [];

    const [state, setState] = useState<IBlogTrashFilterState>({ ...filterInitialState });

    useEffect(() => {
        resetFilter();
    }, []);

    useEffect(() => {
        const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
        const product: { key: string, value: string }[] = searchParams.get("product") ? JSON.parse(String(searchParams.get("product"))) : [];
        const subCategory: { key: string, value: string }[] = searchParams.get("subCategory") ? JSON.parse(String(searchParams.get("subCategory"))) : [];
        const quarter: { key: string, value: string }[] = searchParams.get("quarter") ? JSON.parse(String(searchParams.get("quarter"))) : [];

        setState(prevState => ({
            ...prevState,
            category,
            product,
            subCategory,
            quarter
        }));
    }, [searchParams]);


    const handleLeftListItem = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setState(prevState => ({
            ...prevState,
            selectedMenu: index
        }));
    };


    const handleRightListItem = (name: "category" | "product" | "subCategory" | "quarter", key: string, value: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];
        const isExist = state[name].find(ele => ele.key === key) ? true : false;
        if (isExist) {
            payload = state[name].filter(ele => ele.key !== key);
        } else {
            payload = state[name];
            payload.push({
                key,
                value
            });
        }


        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };

    const deleteChip = (name:  "category" | "subCategory" | "product" | "quarter", key: string) => {
        let payload: Array<{
            key: string;
            value: string;
        }> = [];

        payload = state[name].filter(ele => ele.key !== key);
        setState(prevState => ({
            ...prevState,
            [name]: payload
        }));
    };


    const resetFilter = () => {
        setState({
            selectedMenu: 0,
            status: [],
            category: [],
            product: [],
            subCategory: [],
            quarter: []
        });
    };

    const onApply = () => {
        const path = location.pathname.split("/")[2];
        let navigatePath = "";
        searchParams.set("category", JSON.stringify(state.category));
        searchParams.set("product", JSON.stringify(state.product));
        searchParams.set("subCategory", JSON.stringify(state.subCategory));
        searchParams.set("quarter", JSON.stringify(state.quarter));
        searchParams.set("page", "1");
        if(path === "blog-trash"){
          navigatePath = "/trash/blog-trash";
        }else if(path === "comparison-trash"){
            navigatePath = "/trash/comparison-trash";
        }else{
          navigatePath = "/trash/glossary-trash";
        }
        navigate({
            pathname: navigatePath,
            search: searchParams.toString()
        });
        OnClose();
    };

    const onClose = () => {
        const category: { key: string, value: string }[] = searchParams.get("category") ? JSON.parse(String(searchParams.get("category"))) : [];
        const product: { key: string, value: string }[] = searchParams.get("product") ? JSON.parse(String(searchParams.get("product"))) : [];
        const subCategory: { key: string, value: string }[] = searchParams.get("subCategory") ? JSON.parse(String(searchParams.get("subCategory"))) : [];
        const quarter: { key: string, value: string }[] = searchParams.get("quarter") ? JSON.parse(String(searchParams.get("quarter"))) : [];
        setState(prevState => ({
            ...prevState,
            category,
            product,
            subCategory,
            quarter
        }));
        OnClose();
    };

    return <>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={OnClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            <Box id="filters-container">
                <Box className="center mb-3" justifyContent="space-between" alignItems="start">
                    <div className="active-filter mb-1">
                        {
                            (state.subCategory.length || state.quarter.length || state.product.length || state.category.length) ?
                                <>

                                    {state.category.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("category", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.subCategory.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("subCategory", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.product.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("product", ele.key)} label={ele.value} variant="outlined" />)}
                                    {state.quarter.map(ele => <Chip key={ele.key} className="m-1" color="primary" onDelete={() => deleteChip("quarter", ele.key)} label={ele.value} variant="outlined" />)}
                                </>
                                :
                                <Box className="mt-2" display="flex" alignItems="center">
                                    <FilterAltOffIcon />
                                    <Typography className="ml-2">No filters are applied</Typography>
                                </Box>
                        }
                    </div>
                    <IconButton 
                        onClick={onClose}
                        style={{ marginRight: "-10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Grid className="filter-box" container>
                    <Grid id="left" item xs={5}>
                        <List component="nav">
                            {
                                path !== "comparison-trash" &&  path !== "glossary-trash" &&
                                    <MenuItem
                                        index={0}
                                        label="Category"
                                        selectedMenu={state.selectedMenu === 0}
                                        onChange={handleLeftListItem}
                                        count={state.category}
                                    />
                            }
                            <MenuItem
                                index={1}
                                label="Product"
                                selectedMenu={state.selectedMenu === 1}
                                onChange={handleLeftListItem}
                                count={state.product}
                            />
                            {
                                path !== "comparison-trash" && path !== "glossary-trash" &&
                                    <MenuItem
                                        index={2}
                                        label="Subcategory"
                                        selectedMenu={state.selectedMenu === 2}
                                        onChange={handleLeftListItem}
                                        count={state.subCategory}
                                    />
                            }
                            <MenuItem
                                index={3}
                                label="Quarter"
                                selectedMenu={state.selectedMenu === 3}
                                onChange={handleLeftListItem}
                                count={state.quarter}
                            />
                        </List>
                    </Grid>
                    <Divider orientation="vertical" />

                    <Grid id="right" item xs={6}>
                        <List component="nav">
                            {
                                state.selectedMenu === 0 &&
                                categoryList.map(category => <ListItemButton
                                    key={category?._id}
                                    selected={state.category.find(ele => ele.key === category._id) ? true : false}
                                    onClick={() => handleRightListItem("category", category._id, category.name)}
                                >
                                    <ListItemText primary={category.name} />
                                    <Checkbox edge="end" checked={state.category.find(ele => ele.key === category._id) ? true : false} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 1 &&
                                productList.map((product) => <ListItemButton
                                    key={product?._id}
                                    selected={state.product.find(ele => ele.key === product._id) ? true : false}
                                    onClick={() => handleRightListItem("product", product._id, product.name)}
                                >
                                    <ListItemText primary={product.name} />
                                    <Checkbox edge="end" checked={state.product.find(ele => ele.key === product._id) ? true : false} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 2 &&
                                subCategoryList.map((data) => <ListItemButton
                                    key={data?._id}
                                    selected={state.subCategory.find(ele => ele.key === data._id) ? true : false}
                                    onClick={() => handleRightListItem("subCategory", data._id, data.name)}
                                >
                                    <ListItemText primary={data.name} />
                                    <Checkbox edge="end" checked={state.subCategory.find(ele => ele.key === data._id) ? true : false} />
                                </ListItemButton>)
                            }
                            {
                                state.selectedMenu === 3 &&
                                quarterList.map((data) => <ListItemButton
                                    key={data?._id}
                                    selected={state.quarter.find(ele => ele.key === data.name) ? true : false}
                                    onClick={() => handleRightListItem("quarter", data.name, data.name)}
                                >
                                    <ListItemText primary={data.name} />
                                    <Checkbox edge="end" checked={state.quarter.find(ele => ele.key === data.name) ? true : false} />
                                </ListItemButton>)
                            }
                        </List>

                    </Grid>
                </Grid>

                <Box className="actions-btn" marginTop="8px" textAlign="end">
                    <Button variant="outlined" color="error" onClick={() => resetFilter()}>Clear All</Button>
                    <Button className="ml-2" onClick={onApply}>Apply</Button>
                </Box>
            </Box>
        </Menu>
    </>;
};

export default TrashCmsFilters;