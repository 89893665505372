import ImportFile from "../../../../components/mui/import-candidate";
import useSnackbar from "../../../../hooks/useSnackbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { ChangeEvent, useState } from "react";
import ImportFileError from "../../../../components/mui/import-candidate/erros";
import { useOutletContext } from "react-router-dom";
import { IErrorResponse } from "../../../../interfaces";
import usevalidateBlog from "./validate-import";
import { blogImportService } from "../../../../services/blog/import";
import { ICategory } from "../../../../interfaces/content/category";
interface IOutletProps {
  reFetch: () => void;
}

const ImportBlog = () => {
  const { validateBlog } = usevalidateBlog();
  const { snackbar } = useSnackbar();
  const outlet = useOutletContext<IOutletProps>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { getBlogSample ,importBlog} = blogImportService();

  const [state, setState] = useState({
    fileName: "",
    data: [],
    showError: false,
    errorMessage: [],
  });

  const closeImport = () => {
    navigate({ pathname: "/blog", search: searchParams.toString() });
  };

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    const fileType = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    const selectedFile = event.target.files ? event.target.files[0] : false;

    if (selectedFile && fileType.includes(selectedFile.type)) {
      formData.append("file", selectedFile);
      const reader = new FileReader();
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = async (e) => {
        if (e.target) {
          const data = e.target.result;
          const readedData = XLSX.read(data, { type: "binary" });
          const wsname = readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];
          let dataParse: (string | number)[][] = XLSX.utils.sheet_to_json(ws, {
            header: 1,
          });

          dataParse = dataParse?.slice(1);

          const blogData = await validateBlog(dataParse) as ICategory[];

          setState((prev) => ({
            ...prev,
            ...blogData,
            fileName: selectedFile.name,
          }));
        }
      };
    } else {
      snackbar("Upload excel or xls file", "warning");
    }
  };
  const onExport = async () => {
    try {
      const response = await getBlogSample({});
      const byteArray = new Uint8Array(response?.data?.data);
    
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    
      const file = new File([blob], "temp.xlsx", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    
      const link = document.createElement("a");
      document.body.appendChild(link);
    
      link.href = window.URL.createObjectURL(file);
      link.download = "temp.xlsx";
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error in downloading sample", error);
      snackbar("Error in downloading sample", "error");
    }
  };
  

  const onSubmit = async () => {
    try {
      if (state.data.length) {
        const skillItems = await importBlog(state.data);
        snackbar(skillItems.message, "info");
        outlet?.reFetch && outlet.reFetch();
        navigate(-1);
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }

    if (state.errorMessage.length) {
      setState((prev) => ({
        ...prev,
        showError: true,
      }));
    } else {
      closeErrorDialog();
    }
  };

  const closeErrorDialog = () => {
    setState((prev) => ({
      ...prev,
      fileName: "",
      data: [],
      showError: false,
      errorMessage: [],
    }));

    navigate(-1);
  };

  return (
    <>
      <ImportFile
        isOpen={true}
        onClose={closeImport}
        onUpload={onUpload}
        onsubmit={onSubmit}
        fileName={state.fileName}
        onDownload={onExport}
        title="Before you start upload, please make sure:"
        content={[
          "Download the sample excel file.",
          "Read all the fields in the 1st row and do not change the text.",
          "Start uploading the data from 2nd row and onwards.",
          "In each row, there will be data for one Blog.",
          "All variants are mapped to their field by field title.",
          "Once sheet is prepared, upload it.",
          "Now sit back and relax!",
        ]}
      />

      <ImportFileError
        title="Excel file Errors"
        isOpen={state.showError}
        onClose={closeErrorDialog}
        errorMessage={state.errorMessage}
      />
    </>
  );
};

export default ImportBlog;
