import "./style.scss";
import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Add, Delete, DragIndicator } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import {
  CheckMark,
  Dropdown,
  IAlternativeContent,
  Section,
} from "../../../../interfaces/alternative-comparison/content";
import { joiResolver } from "@hookform/resolvers/joi";
import alternativeContentValidation from "../../../../validations/content-marketing/alternatives/content";
import { CMS_ERROR_MESSAGES } from "../../../../utilities/messages";
import { IErrorResponse } from "../../../../interfaces";
import HttpService from "../../../../services/http";
import useResource from "../../../../hooks/useResource";
import useSnackbar from "../../../../hooks/useSnackbar";
import CustomLabel from "../../../../components/mui/custom-label";
import AlternativeComment from "../../../../components/alternative-comments";
import { AlternativeContentService } from "../../../../services/alternative-comparison/content";
import { areEqual, defaultValuesAlternativeContent, isFormEmpty } from "../../../../utilities/helper";

interface props {
  activeAction: boolean;
  setActiveAction: (value: boolean) => void;
  commentActiveAction: boolean;
  setCommentActiveAction: (value: boolean) => void;
}
const AlternativeContent: FC<props> = ({
  activeAction,
  commentActiveAction,
  setCommentActiveAction,
}) => {
  const [jsonInput, setJsonInput] = useState("");
  const { id } = useParams();
  const [error, setError] = useState("");

  const validateJson = (input: string) => {
    try {
      JSON.parse(input);
      setError("");
      return true;
    } catch (e) {
      setError(CMS_ERROR_MESSAGES.en.invalid_json);
      return false;
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    setValue(`sections.${index}.tableData.data`, e.target.value);
    setJsonInput(e.target.value);
    validateJson(e.target.value);
    trigger(`sections.${index}.tableData.data`);
  };

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) return;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    move(sourceIndex, destinationIndex);
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    getValues,
    formState: { errors },
    reset,
  } = useForm<IAlternativeContent>({
    resolver: joiResolver(alternativeContentValidation),
    defaultValues: {
      ...defaultValuesAlternativeContent
    }
  });

  const [saveDisabled, setSaveDisabled] = useState<boolean | null>(true);
  const [submitDisabled, setSubmitDisabled] = useState<boolean | null>(true);

  const formValues = watch();

  const { move } = useFieldArray({
    control,
    name: "sections",
  });
  const watchSection = watch("sections");

  const { saveAlternativeContent, getAlternativeContent } =
    AlternativeContentService();

  const alternativeContent = useQuery({
    queryKey: ["alternative-content", id],
    queryFn: () => getAlternativeContent({ _alternativeComparison: id }),
    enabled: id !== "new",
  });

  useEffect(() => {
    const edited = JSON.stringify(defaultValuesAlternativeContent) !== JSON.stringify(formValues);
    if(edited){
      setSubmitDisabled(!saveDisabled);
    }
  }, [saveDisabled]);

  const alternativeContentData =
    alternativeContent &&
    alternativeContent.data &&
    alternativeContent.data.data;

  const onSubmit = async (data: IAlternativeContent) => {
    try {
      if (
        !validateJson(
          data.sections.find((item) => item.type === "tableData")?.tableData
            ?.data || ""
        )
      ) {
        snackbar(error, "error");
        return;
      }
      if (id !== "new") {
        setSaveDisabled(true);
        const payload = {
          ...data,
          _alternativeComparison: id,
        };
        const response = await saveAlternativeContent(payload);
        snackbar(response.message, "info");
        navigate(`/comparison/manage/${id}?type=content`);
        alternativeContent.refetch();
      } else {
        snackbar(CMS_ERROR_MESSAGES.en.fill_basic_details, "error");
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err?.data?.message, "error");
      navigate(`/comparison/manage/${id}?type=content`);
    }
  };

  useEffect(() => {
    if (alternativeContentData) {
      setValue("title", alternativeContentData.title || "");
      setValue("description", alternativeContentData.description || "");
      setValue("buttonLink", alternativeContentData.buttonLink || "");
      setValue("buttonText", alternativeContentData.buttonText || "");
      setValue("metaTitle", alternativeContentData.metaTitle || "");
      setValue("metaDescription", alternativeContentData.metaDescription || "");
      setValue("sections", alternativeContentData.sections || []);
      const tableData = alternativeContentData.sections.find(
        (section) => section.type === "tableData"
      )?.tableData?.data;
      if (tableData) {
        setJsonInput(JSON.stringify(JSON.parse(tableData), null, 2));
      }
  
    }
  }, [alternativeContentData]);
  
  useEffect(() => {
      const subscription = watch(() => {
      const currentValues = getValues();
      const isEmpty = isFormEmpty(currentValues);
      const hasChanges = !areEqual(currentValues, alternativeContentData);
      setSaveDisabled(isEmpty || !hasChanges);
    });
    return () => subscription.unsubscribe();
  }, [watch, getValues, alternativeContentData]);

  const handleSubmitClick = () => {
    navigate({
      pathname: `/comparison/manage/${id}/checklistWriter`,
      search: searchParam.toString(),
    });
  };

  const handleDiscard = () => {
    reset();
    setJsonInput("");
    setError("");
  };

  const handleAddDropdown = () => {
    const currentSections = watchSection || [];
    const newDropdown: Dropdown = {
      type: "dropdowns",
      heading: "",
      paragraph: "",
      buttonText: "",
      buttonLink: "",
      items: [{ heading: "", paragraph: "" }],
    };
    setValue("sections", [...currentSections, newDropdown]);
  };

  const handleRemoveDropdown = (index: number) => {
    const currentSections = [...(watchSection || [])];
    currentSections.splice(index, 1);
    setValue("sections", currentSections);
  };

  const handleAddDropdownItem = (dropdownIndex: number) => {
    const currentSections = [...(watchSection || [])];
    if (!currentSections[dropdownIndex].items) {
      currentSections[dropdownIndex].items = [
        {
          heading: "",
          paragraph: "",
        },
      ];
    } else {
      currentSections[dropdownIndex].items?.push({
        heading: "",
        paragraph: "",
      });
    }
    setValue("sections", currentSections);
  };

  const handleRemoveDropdownItem = (
    sectionIndex: number,
    itemIndex: number
  ) => {
    const currentSections = [...(watchSection || [])] as Section[];
    const section = currentSections[sectionIndex];
    if (
      section &&
      "items" in section &&
      Array.isArray(section.items) &&
      itemIndex >= 0 &&
      itemIndex < section.items.length
    ) {
      const updatedItems = [
        ...section.items.slice(0, itemIndex),
        ...section.items.slice(itemIndex + 1),
      ];
      currentSections[sectionIndex] = {
        ...section,
        items: updatedItems,
      };
      setValue("sections", currentSections);
    }
  };

  const handleRemoveCheckMarkPoint = (
    sectionIndex: number,
    pointIndex: number
  ) => {
    const currentSections = [...(watchSection || [])] as Section[];

    const section = currentSections[sectionIndex];
    if (
      section?.type === "checkMarks" &&
      "points" in section &&
      Array.isArray(section.points) &&
      pointIndex >= 0 &&
      pointIndex < section.points.length
    ) {
      const updatedItems = [
        ...section.points.slice(0, pointIndex),
        ...section.points.slice(pointIndex + 1),
      ];
      currentSections[sectionIndex] = {
        ...section,
        points: updatedItems,
      };
      setValue("sections", currentSections);
    }
  };

  const addCardsData = (cardIndex: number) => {
    const currentSections = [...(watchSection || [])];
    if (!currentSections[cardIndex].cards) {
      currentSections[cardIndex].cards = [
        {
          heading: "",
          paragraph: "",
          image: "",
          buttonLink: "",
          buttonText: "",
        },
      ];
    } else {
      currentSections[cardIndex].cards?.push({
        heading: "",
        paragraph: "",
        image: "",
        buttonLink: "",
        buttonText: "",
      });
    }
    setValue("sections", currentSections);
  };

  const removeCardData = (cardIndex: number, itemIndex: number) => {
    const currentSection = [...(watchSection || [])];
    if (currentSection[cardIndex]?.cards) {
      currentSection[cardIndex].cards?.splice(itemIndex, 1);
      setValue("sections", currentSection);
    }
  };

  const addFaqData = (cardIndex: number) => {
    const currentSections = [...(watchSection || [])];
    if (!currentSections[cardIndex].faqs) {
      currentSections[cardIndex].faqs = [
        {
          heading: "",
          paragraph: "",
        },
      ];
    } else {
      currentSections[cardIndex].faqs?.push({
        heading: "",
        paragraph: "",
      });
    }
    setValue("sections", currentSections);
  };

  const removeFaqData = (cardIndex: number, itemIndex: number) => {
    const currentSection = [...(watchSection || [])];
    if (currentSection[cardIndex]?.faqs) {
      currentSection[cardIndex].faqs?.splice(itemIndex, 1);
      setValue("sections", currentSection);
    }
  };

  const handleAddCheckMark = () => {
    const currentCheckMark = watchSection || [];
    const newCheckMark: CheckMark = {
      type: "checkMarks",
      heading: "",
      paragraph: "",
      buttonText: "",
      buttonLink: "",
      points: [""],
    };
    setValue("sections", [...currentCheckMark, newCheckMark]);
  };

  const handleRemoveCheckMark = (index: number) => {
    const currentCheckMark = watchSection || [];
    currentCheckMark.splice(index, 1);
    setValue("sections", currentCheckMark);
  };

  const handleAddCheckMarkPoint = (checkMarkIndex: number) => {
    const currentCheckMark = [...(watchSection || [])];
    if (!currentCheckMark[checkMarkIndex].points) {
      currentCheckMark[checkMarkIndex].points = [""];
    } else {
      currentCheckMark[checkMarkIndex].points?.push("");
    }
    setValue("sections", currentCheckMark);
  };

  const { snackbar } = useSnackbar();
  const navigate = useNavigate();
  const { httpFormRequest } = HttpService();
  const { resourceAllocate } = useResource();
  const [searchParam] = useSearchParams();

  const uploadFile = async (
    e: ChangeEvent<HTMLInputElement>,
    sectionIndex: number,
    index: number
  ) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const uploaded = await httpFormRequest<{ data: string }>(
          e.target.files,
          e.target.files[0].name,
          ["webp"],
          1
        );
        setValue(
          `sections.${sectionIndex}.cards.${index}.image`,
          uploaded?.data
        );
        trigger(`sections.${sectionIndex}.cards.${index}.image`);
      }
    } catch (error) {
      const err = error as IErrorResponse;
      snackbar(err.data.message, "warning");
    }
  };

  return (
    <div className="alternative-content">
      <Grid container spacing={4}>
        <Grid
          item
          {...(activeAction || commentActiveAction ? { xs: 8 } : { xs: 12 })}
        >
          <div className="content-box">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} marginTop={2}>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={<CustomLabel required label="Title" />}
                        fullWidth
                        placeholder="Enter Title"
                        error={!!errors["title"]}
                        helperText={errors["title"]?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4} mt={2}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={<CustomLabel required label="Description" />}
                        placeholder="Enter Description"
                        fullWidth
                        minRows={3}
                        maxRows={3}
                        multiline
                        error={!!errors["description"]}
                        helperText={errors["description"]?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4} mt={2}>
                  <Controller
                    name="buttonLink"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={<CustomLabel label="Button Link" />}
                        placeholder="Enter Button Link"
                        fullWidth
                        error={!!errors["buttonLink"]}
                        helperText={errors["buttonLink"]?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Controller
                    name="buttonText"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={<CustomLabel label="Button Text" />}
                        placeholder="Enter Button Text"
                        fullWidth
                        error={!!errors["buttonText"]}
                        helperText={errors["buttonText"]?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="sections">
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      marginTop={4}
                      className="drag-container"
                    >
                      {watchSection.map((section, sectionIndex) => (
                        <Draggable
                          key={sectionIndex}
                          draggableId={sectionIndex.toString()}
                          index={sectionIndex}
                        >
                          {(provided, snapshot) => (
                            <Box
                              marginBottom={2}
                              padding={2}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={
                                snapshot.isDragging ? "dragging" : "drag-item"
                              }
                            >
                              <Box
                                {...provided.dragHandleProps}
                                padding={1}
                                marginBottom={1}
                                className="drag-handle"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <Typography variant="h6">
                                    Section {sectionIndex + 1}
                                  </Typography>
                                  <DragIndicator />
                                </Box>
                              </Box>

                              {section.type === "dropdowns" && (
                                <Box key={sectionIndex} className="dropdown">
                                  <Typography variant="h6">Dropdown</Typography>
                                  <Grid container spacing={2} mt={1}>
                                    <Grid item xs={12} md={4}>
                                      <Controller
                                        name={`sections.${sectionIndex}.heading`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            label={
                                              <CustomLabel
                                                required
                                                label="Heading"
                                              />
                                            }
                                            error={
                                              !!errors.sections?.[sectionIndex]
                                                ?.heading
                                            }
                                            helperText={
                                              errors.sections?.[sectionIndex]
                                                ?.heading?.message
                                            }
                                            value={getValues(
                                              `sections.${sectionIndex}.heading`
                                            )}
                                            placeholder="Enter Heading"
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                      <Controller
                                        name={`sections.${sectionIndex}.paragraph`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            label={
                                              <CustomLabel
                                                required
                                                label="Sub Heading"
                                              />
                                            }
                                            value={getValues(
                                              `sections.${sectionIndex}.paragraph`
                                            )}
                                            placeholder="Enter Sub Heading"
                                            fullWidth
                                            error={
                                              !!errors.sections?.[sectionIndex]
                                                ?.paragraph
                                            }
                                            helperText={
                                              errors.sections?.[sectionIndex]
                                                ?.paragraph?.message
                                            }
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Box
                                    className="dropdown-items"
                                    padding={2}
                                    marginTop={2}
                                  >
                                    {section.items &&
                                      section.items.map((_, itemIndex) => (
                                        <Grid
                                          container
                                          spacing={2}
                                          key={itemIndex}
                                          mt={1}
                                        >
                                          <Grid item xs={12} md={5}>
                                            <Controller
                                              name={`sections.${sectionIndex}.items.${itemIndex}.heading`}
                                              control={control}
                                              render={({ field }) => (
                                                <TextField
                                                  {...field}
                                                  label={
                                                    <CustomLabel
                                                      required
                                                      label="Title"
                                                    />
                                                  }
                                                  value={getValues(
                                                    `sections.${sectionIndex}.items.${itemIndex}.heading`
                                                  )}
                                                  error={
                                                    !!errors.sections?.[
                                                      sectionIndex
                                                    ]?.items?.[itemIndex]
                                                      ?.heading
                                                  }
                                                  helperText={
                                                    errors.sections?.[
                                                      sectionIndex
                                                    ]?.items?.[itemIndex]
                                                      ?.heading?.message
                                                  }
                                                  placeholder="Enter Title"
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          </Grid>

                                          <Grid item xs={12} md={5}>
                                            <Controller
                                              name={`sections.${sectionIndex}.items.${itemIndex}.paragraph`}
                                              control={control}
                                              render={({ field }) => (
                                                <TextField
                                                  {...field}
                                                  label={
                                                    <CustomLabel
                                                      required
                                                      label="Description"
                                                    />
                                                  }
                                                  placeholder="Enter Description"
                                                  fullWidth
                                                  value={getValues(
                                                    `sections.${sectionIndex}.items.${itemIndex}.paragraph`
                                                  )}
                                                  error={
                                                    !!errors.sections?.[
                                                      sectionIndex
                                                    ]?.items?.[itemIndex]
                                                      ?.paragraph
                                                  }
                                                  helperText={
                                                    errors.sections?.[
                                                      sectionIndex
                                                    ]?.items?.[itemIndex]
                                                      ?.paragraph?.message
                                                  }
                                                />
                                              )}
                                            />
                                          </Grid>

                                          {section.items &&
                                            section.items.length > 1 && (
                                              <Grid item xs={12} md={2}>
                                                <IconButton
                                                  color="error"
                                                  onClick={() =>
                                                    handleRemoveDropdownItem(
                                                      sectionIndex,
                                                      itemIndex
                                                    )
                                                  }
                                                >
                                                  <RemoveCircleOutlineIcon />
                                                </IconButton>
                                              </Grid>
                                            )}
                                        </Grid>
                                      ))}

                                    <Button
                                      variant="outlined"
                                      startIcon={<Add />}
                                      onClick={() =>
                                        handleAddDropdownItem(sectionIndex)
                                      }
                                      sx={{ mt: 2 }}
                                    >
                                      Add Item
                                    </Button>
                                  </Box>

                                  <Typography variant="body1" mt={1}>
                                    Button
                                  </Typography>
                                  <Box className="dropdown-box" padding={2}>
                                    <Grid container spacing={2} mt={0.5}>
                                      <Grid item xs={12} md={4}>
                                        <Controller
                                          name={`sections.${sectionIndex}.buttonText`}
                                          control={control}
                                          render={({ field }) => (
                                            <TextField
                                              {...field}
                                              label={
                                                <CustomLabel
                                                  label="Button Text"
                                                />
                                              }
                                              placeholder="Enter Button Text"
                                              fullWidth
                                              value={getValues(
                                                `sections.${sectionIndex}.buttonText`
                                              )}
                                              error={
                                                !!errors.sections?.[
                                                  sectionIndex
                                                ]?.buttonText
                                              }
                                              helperText={
                                                errors.sections?.[sectionIndex]
                                                  ?.buttonText?.message
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={12} md={6}>
                                        <Controller
                                          name={`sections.${sectionIndex}.buttonLink`}
                                          control={control}
                                          render={({ field }) => (
                                            <TextField
                                              {...field}
                                              label={
                                                <CustomLabel
                                                  label="Button Link"
                                                />
                                              }
                                              value={getValues(
                                                `sections.${sectionIndex}.buttonLink`
                                              )}
                                              placeholder="Enter Button Link"
                                              fullWidth
                                              error={
                                                !!errors.sections?.[
                                                  sectionIndex
                                                ]?.buttonLink
                                              }
                                              helperText={
                                                errors.sections?.[sectionIndex]
                                                  ?.buttonLink?.message
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  {
                                    watchSection.filter(
                                      (section) => section.type === "dropdowns"
                                    ).length > 1 &&
                                    <Box className="remove-section">
                                      <Button
                                        variant="outlined"
                                        color="error"
                                        startIcon={<Delete />}
                                        onClick={() =>
                                          handleRemoveDropdown(sectionIndex)
                                        }
                                      >
                                        Remove Dropdown Section
                                      </Button>
                                    </Box>
                                  }
                                </Box>
                              )}
                              {section.type === "checkMarks" && (
                                <Box key={sectionIndex} className="checkmark">
                                  <Typography variant="h6">
                                    Check Mark
                                  </Typography>
                                  <Grid container spacing={2} mt={1}>
                                    <Grid item xs={12} md={4}>
                                      <Controller
                                        name={`sections.${sectionIndex}.heading`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            label={
                                              <CustomLabel
                                                required
                                                label="Heading"
                                              />
                                            }
                                            value={getValues(
                                              `sections.${sectionIndex}.heading`
                                            )}
                                            placeholder="Enter Heading"
                                            fullWidth
                                            error={
                                              !!errors.sections?.[sectionIndex]
                                                ?.heading
                                            }
                                            helperText={
                                              errors.sections?.[sectionIndex]
                                                ?.heading?.message
                                            }
                                          />
                                        )}
                                      />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                      <Controller
                                        name={`sections.${sectionIndex}.paragraph`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            label={
                                              <CustomLabel
                                                label="Sub Heading"
                                              />
                                            }
                                            placeholder="Enter Sub Heading"
                                            fullWidth
                                            value={getValues(
                                              `sections.${sectionIndex}.paragraph`
                                            )}
                                            error={
                                              !!errors.sections?.[sectionIndex]
                                                ?.paragraph
                                            }
                                            helperText={
                                              errors.sections?.[sectionIndex]
                                                ?.paragraph?.message
                                            }
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Box
                                    className="checkmark-points"
                                    padding={2}
                                    marginTop={2}
                                  >
                                    {section.points &&
                                      section.points.map((item, itemIndex) => (
                                        <Grid
                                          container
                                          spacing={2}
                                          key={itemIndex}
                                          mt={1}
                                        >
                                          <Grid item xs={12} md={10}>
                                            <Controller
                                              name={`sections.${sectionIndex}.points.${itemIndex}`}
                                              control={control}
                                              render={({ field }) => (
                                                <TextField
                                                  {...field}
                                                  label={
                                                    <CustomLabel
                                                      required
                                                      label="Point"
                                                    />
                                                  }
                                                  placeholder="Enter Point"
                                                  fullWidth
                                                  value={getValues(
                                                    `sections.${sectionIndex}.points.${itemIndex}`
                                                  )}
                                                  error={
                                                    !!errors.sections?.[
                                                      sectionIndex
                                                    ]?.points?.[itemIndex]
                                                  }
                                                  helperText={
                                                    errors.sections?.[
                                                      sectionIndex
                                                    ]?.points?.[itemIndex]
                                                      ?.message
                                                  }
                                                />
                                              )}
                                            />
                                          </Grid>

                                          {section.points &&
                                            section.points.length > 1 && (
                                              <Grid item xs={12} md={2}>
                                                <IconButton
                                                  color="error"
                                                  onClick={() =>
                                                    handleRemoveCheckMarkPoint(
                                                      sectionIndex,
                                                      itemIndex
                                                    )
                                                  }
                                                >
                                                  <RemoveCircleOutlineIcon />
                                                </IconButton>
                                              </Grid>
                                            )}
                                        </Grid>
                                      ))}

                                      {
                                        section.points &&
                                          section.points.length < 6 && (
                                            <Button
                                              variant="outlined"
                                              startIcon={<Add />}
                                              onClick={() =>
                                                handleAddCheckMarkPoint(sectionIndex)
                                              }
                                              sx={{ mt: 2 }}
                                            >
                                              Add Point
                                            </Button>
                                          )
                                      }
                                  </Box>

                                  <Typography variant="body1" mt={1}>
                                    Button
                                  </Typography>
                                  <Box className="checkmark-points" padding={2}>
                                    <Grid container spacing={2} mt={0.5}>
                                      <Grid item xs={12} md={4}>
                                        <Controller
                                          name={`sections.${sectionIndex}.buttonText`}
                                          control={control}
                                          render={({ field }) => (
                                            <TextField
                                              {...field}
                                              label={
                                                <CustomLabel
                                                  required
                                                  label="Button Text"
                                                />
                                              }
                                              value={getValues(
                                                `sections.${sectionIndex}.buttonText`
                                              )}
                                              placeholder="Enter Button Text"
                                              fullWidth
                                              error={
                                                !!errors.sections?.[
                                                  sectionIndex
                                                ]?.buttonText
                                              }
                                              helperText={
                                                errors.sections?.[sectionIndex]
                                                  ?.buttonText?.message
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={12} md={6}>
                                        <Controller
                                          name={`sections.${sectionIndex}.buttonLink`}
                                          control={control}
                                          render={({ field }) => (
                                            <TextField
                                              {...field}
                                              label={
                                                <CustomLabel
                                                  label="Button Link"
                                                />
                                              }
                                              placeholder="Enter Button Link"
                                              fullWidth
                                              value={getValues(
                                                `sections.${sectionIndex}.buttonLink`
                                              )}
                                              error={
                                                !!errors.sections?.[
                                                  sectionIndex
                                                ]?.buttonLink
                                              }
                                              helperText={
                                                errors.sections?.[sectionIndex]
                                                  ?.buttonLink?.message
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  {
                                     watchSection.filter(
                                      (section) => section.type === "checkMarks"
                                    ).length > 1 &&
                                    <Box marginTop={2}>
                                      <Button
                                        variant="outlined"
                                        color="error"
                                        startIcon={<Delete />}
                                        onClick={() =>
                                          handleRemoveCheckMark(sectionIndex)
                                        }
                                      >
                                        Remove Checkmark Section
                                      </Button>
                                    </Box>
                                  }
                                </Box>
                              )}
                              {section.type === "tableData" && (
                                <Box
                                  className="table-data"
                                  key={`${sectionIndex}`}
                                  marginTop={2}
                                  padding={2}
                                  border={"1px solid #ccc"}
                                  borderRadius={"8px"}
                                >
                                  <Typography variant="h6" gutterBottom>
                                    Table Data
                                  </Typography>

                                  <Grid container spacing={2} mt={1} mb={2}>
                                    <Grid item xs={12} md={4}>
                                      <Controller
                                        name={`sections.${sectionIndex}.tableData.heading`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            label={
                                              <CustomLabel
                                                required
                                                label="Heading"
                                              />
                                            }
                                            error={
                                              !!errors.sections?.[sectionIndex]
                                                ?.tableData?.heading
                                            }
                                            helperText={
                                              errors.sections?.[sectionIndex]
                                                ?.tableData?.heading?.message
                                            }
                                            value={getValues(
                                              `sections.${sectionIndex}.tableData.heading`
                                            )}
                                            placeholder="Enter Heading"
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                      <Controller
                                        name={`sections.${sectionIndex}.tableData.paragraph`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            label={
                                              <CustomLabel
                                                required
                                                label="Sub Heading"
                                              />
                                            }
                                            value={getValues(
                                              `sections.${sectionIndex}.tableData.paragraph`
                                            )}
                                            placeholder="Enter Sub Heading"
                                            fullWidth
                                            error={
                                              !!errors.sections?.[sectionIndex]
                                                ?.tableData?.paragraph
                                            }
                                            helperText={
                                              errors.sections?.[sectionIndex]
                                                ?.tableData?.paragraph?.message
                                            }
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Grid>

                                  <TextField
                                    id="json-input"
                                    multiline
                                    rows={10}
                                    value={jsonInput}
                                    onChange={(e) => {
                                      handleInputChange(e, sectionIndex);
                                    }}
                                    name={`sections.${sectionIndex}.tableData`}
                                    placeholder='Enter JSON like {"name": "example"}'
                                    error={
                                      !!errors?.sections?.[sectionIndex]
                                        ?.tableData?.data
                                    }
                                    helperText={
                                      errors?.sections?.[sectionIndex]
                                        ?.tableData?.data?.message
                                    }
                                    variant="outlined"
                                    sx={{
                                      borderRadius: "8px",
                                      backgroundColor: "#000",
                                      color: "white",
                                      "& .MuiOutlinedInput-root": {
                                        "& textarea": {
                                          color: "white",
                                        },
                                      },
                                    }}
                                  />

                                  <Typography variant="body1" mt={1}>
                                    Buttons
                                  </Typography>
                                  <Box className="table-buttons" padding={2}>
                                    <Grid container spacing={2} mt={0.5}>
                                      <Grid item xs={12} md={4}>
                                        <Controller
                                          name={`sections.${sectionIndex}.tableData.buttonTextOne`}
                                          control={control}
                                          render={({ field }) => (
                                            <TextField
                                              {...field}
                                              label={
                                                <CustomLabel
                                                  label="Button One Text"
                                                />
                                              }
                                              value={getValues(
                                                `sections.${sectionIndex}.tableData.buttonTextOne`
                                              )}
                                              placeholder="Enter Button One Text"
                                              fullWidth
                                              error={
                                                !!errors.sections?.[
                                                  sectionIndex
                                                ]?.tableData?.buttonTextOne
                                              }
                                              helperText={
                                                errors.sections?.[sectionIndex]
                                                  ?.tableData?.buttonTextOne?.message
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={12} md={6}>
                                        <Controller
                                          name={`sections.${sectionIndex}.tableData.buttonLinkOne`}
                                          control={control}
                                          render={({ field }) => (
                                            <TextField
                                              {...field}
                                              label={
                                                <CustomLabel
                                                  label="Button One Link"
                                                />
                                              }
                                              placeholder="Enter Button One Link"
                                              fullWidth
                                              value={getValues(
                                                `sections.${sectionIndex}.tableData.buttonLinkOne`
                                              )}
                                              error={
                                                !!errors.sections?.[
                                                  sectionIndex
                                                ]?.tableData?.buttonLinkOne
                                              }
                                              helperText={
                                                errors.sections?.[sectionIndex]
                                                  ?.tableData?.buttonLinkOne?.message
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={2} mt={0.5}>
                                      <Grid item xs={12} md={4}>
                                        <Controller
                                          name={`sections.${sectionIndex}.tableData.buttonTextTwo`}
                                          control={control}
                                          render={({ field }) => (
                                            <TextField
                                              {...field}
                                              label={
                                                <CustomLabel
                                                  label="Button Two Text"
                                                />
                                              }
                                              value={getValues(
                                                `sections.${sectionIndex}.tableData.buttonTextTwo`
                                              )}
                                              placeholder="Enter Button Two Text"
                                              fullWidth
                                              error={
                                                !!errors.sections?.[
                                                  sectionIndex
                                                ]?.tableData?.buttonTextTwo
                                              }
                                              helperText={
                                                errors.sections?.[sectionIndex]
                                                  ?.tableData?.buttonTextTwo?.message
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>

                                      <Grid item xs={12} md={6}>
                                        <Controller
                                          name={`sections.${sectionIndex}.tableData.buttonLinkTwo`}
                                          control={control}
                                          render={({ field }) => (
                                            <TextField
                                              {...field}
                                              label={
                                                <CustomLabel
                                                  label="Button Two Link"
                                                />
                                              }
                                              placeholder="Enter Button Two Link"
                                              fullWidth
                                              value={getValues(
                                                `sections.${sectionIndex}.tableData.buttonLinkTwo`
                                              )}
                                              error={
                                                !!errors.sections?.[
                                                  sectionIndex
                                                ]?.tableData?.buttonLinkTwo
                                              }
                                              helperText={
                                                errors.sections?.[sectionIndex]
                                                  ?.tableData?.buttonLinkTwo?.message
                                              }
                                            />
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Box>
                              )}
                              {section.type === "cards" && (
                                <Box
                                  className="cards"
                                  key={`${sectionIndex}`}
                                  marginTop={2}
                                  padding={2}
                                  border={"1px solid #ccc"}
                                  borderRadius={"8px"}
                                >
                                  <Typography variant="h6" gutterBottom>
                                    Cards
                                  </Typography>

                                  {section.cards &&
                                    section.cards.map((field, index) => (
                                      <Box
                                        key={`${index}`}
                                        className="section-cards"
                                      >
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} md={4}>
                                            <Controller
                                              name={`sections.${sectionIndex}.cards.${index}.heading`}
                                              control={control}
                                              render={({ field }) => (
                                                <TextField
                                                  {...field}
                                                  label={
                                                    <CustomLabel
                                                      required
                                                      label="Title"
                                                    />
                                                  }
                                                  value={getValues(
                                                    `sections.${sectionIndex}.cards.${index}.heading`
                                                  )}
                                                  placeholder="Enter Title"
                                                  fullWidth
                                                  variant="outlined"
                                                  error={
                                                    !!errors.sections?.[
                                                      sectionIndex
                                                    ]?.cards?.[index]?.heading
                                                  }
                                                  helperText={
                                                    errors.sections?.[
                                                      sectionIndex
                                                    ]?.cards?.[index]?.heading
                                                      ?.message
                                                  }
                                                />
                                              )}
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Controller
                                              name={`sections.${sectionIndex}.cards.${index}.paragraph`}
                                              control={control}
                                              render={({ field }) => (
                                                <TextField
                                                  {...field}
                                                  value={getValues(
                                                    `sections.${sectionIndex}.cards.${index}.paragraph`
                                                  )}
                                                  label={
                                                    <CustomLabel
                                                      required
                                                      label="Description"
                                                    />
                                                  }
                                                  placeholder="Enter Description"
                                                  fullWidth
                                                  variant="outlined"
                                                  error={
                                                    !!errors.sections?.[
                                                      sectionIndex
                                                    ]?.cards?.[index]?.paragraph
                                                  }
                                                  helperText={
                                                    errors.sections?.[
                                                      sectionIndex
                                                    ]?.cards?.[index]?.paragraph
                                                      ?.message
                                                  }
                                                />
                                              )}
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Box
                                              display="flex"
                                              alignItems="start"
                                            >
                                              <div
                                                className="w-100"
                                                onClick={() => {
                                                  const fileInput =
                                                    document.getElementById(
                                                      `sections.${sectionIndex}.cards.${index}.image`
                                                    );
                                                  if (fileInput) {
                                                    fileInput.click();
                                                  }
                                                }}
                                              >
                                                <Controller
                                                  control={control}
                                                  name={`sections.${sectionIndex}.cards.${index}.image`}
                                                  render={() => (
                                                    <TextField
                                                      label={
                                                        <CustomLabel
                                                          required
                                                          label="Image Url"
                                                        />
                                                      }
                                                      value={getValues(
                                                        `sections.${sectionIndex}.cards.${index}.image`
                                                      )}
                                                      placeholder="Upload Image"
                                                      className="disable-text"
                                                      variant="outlined"
                                                      size="small"
                                                      error={
                                                        !!errors.sections?.[
                                                          sectionIndex
                                                        ]?.cards?.[index]?.image
                                                      }
                                                      helperText={
                                                        errors.sections?.[
                                                          sectionIndex
                                                        ]?.cards?.[index]?.image
                                                          ?.message
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      InputProps={{
                                                        endAdornment: (
                                                          <>
                                                            <Tooltip title="Upload">
                                                              <IconButton
                                                                color="primary"
                                                                component="label"
                                                              >
                                                                <UploadIcon color="action" />
                                                              </IconButton>
                                                            </Tooltip>
                                                          </>
                                                        ),
                                                      }}
                                                    />
                                                  )}
                                                />
                                                <input
                                                  id={`sections.${sectionIndex}.cards.${index}.image`}
                                                  type="file"
                                                  accept="image/*"
                                                  style={{
                                                    display: "none",
                                                    cursor: "pointer",
                                                  }}
                                                  onChange={(e) => {
                                                    uploadFile(
                                                      e,
                                                      sectionIndex,
                                                      index
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </Box>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Controller
                                              name={`sections.${sectionIndex}.cards.${index}.buttonLink`}
                                              control={control}
                                              render={({ field }) => (
                                                <TextField
                                                  {...field}
                                                  label={
                                                    <CustomLabel
                                                      required
                                                      label="Button Link"
                                                    />
                                                  }
                                                  value={getValues(
                                                    `sections.${sectionIndex}.cards.${index}.buttonLink`
                                                  )}
                                                  placeholder="Enter Button Link"
                                                  fullWidth
                                                  variant="outlined"
                                                  error={
                                                    !!errors.sections?.[
                                                      sectionIndex
                                                    ]?.cards?.[index]
                                                      ?.buttonLink
                                                  }
                                                  helperText={
                                                    errors.sections?.[
                                                      sectionIndex
                                                    ]?.cards?.[index]
                                                      ?.buttonLink?.message
                                                  }
                                                />
                                              )}
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <Controller
                                              name={`sections.${sectionIndex}.cards.${index}.buttonText`}
                                              control={control}
                                              render={({ field }) => (
                                                <TextField
                                                  {...field}
                                                  label={
                                                    <CustomLabel
                                                      label="Button Text"
                                                    />
                                                  }
                                                  key={`sections.${index}.buttonText`}
                                                  placeholder="Enter Button Text"
                                                  fullWidth
                                                  variant="outlined"
                                                  value={getValues(
                                                    `sections.${sectionIndex}.cards.${index}.buttonText`
                                                  )}
                                                  error={
                                                    !!errors.sections?.[
                                                      sectionIndex
                                                    ]?.cards?.[index]
                                                      ?.buttonText
                                                  }
                                                  helperText={
                                                    errors.sections?.[
                                                      sectionIndex
                                                    ]?.cards?.[index]
                                                      ?.buttonText?.message
                                                  }
                                                />
                                              )}
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                              <IconButton
                                                color="primary"
                                                onClick={() =>
                                                  addCardsData(sectionIndex)
                                                }
                                              >
                                                <AddCircleOutlineIcon />
                                              </IconButton>
                                              {section.cards &&
                                                section.cards.length > 1 && (
                                                  <IconButton
                                                    color="error"
                                                    onClick={() =>
                                                      removeCardData(
                                                        sectionIndex,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <RemoveCircleOutlineIcon />
                                                  </IconButton>
                                                )}
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    ))}
                                </Box>
                              )}
                              {section.type === "faqs" && (
                                <Box
                                  marginBottom={2}
                                  marginTop={2}
                                  padding={2}
                                  border={"1px solid #ccc"}
                                  borderRadius={"8px"}
                                  className="faqs"
                                  key={`${sectionIndex}`}
                                >
                                  <Typography variant="h6" gutterBottom>
                                    FAQs
                                  </Typography>

                                  {section.faqs &&
                                    section.faqs.map((field, index) => (
                                      <Box
                                        key={index}
                                        className="faq"
                                      >
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} md={3}>
                                            <Controller
                                              name={`sections.${sectionIndex}.faqs.${index}.heading`}
                                              control={control}
                                              render={({ field }) => (
                                                <TextField
                                                  {...field}
                                                  label={
                                                    <CustomLabel
                                                      required
                                                      label="Question"
                                                    />
                                                  }
                                                  placeholder="Enter Question"
                                                  fullWidth
                                                  variant="outlined"
                                                  value={getValues(
                                                    `sections.${sectionIndex}.faqs.${index}.heading`
                                                  )}
                                                  error={
                                                    !!errors.sections?.[
                                                      sectionIndex
                                                    ]?.faqs?.[index]?.heading
                                                  }
                                                  helperText={
                                                    errors.sections?.[
                                                      sectionIndex
                                                    ]?.faqs?.[index]?.heading
                                                      ?.message
                                                  }
                                                />
                                              )}
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={6}>
                                            <Controller
                                              name={`sections.${sectionIndex}.faqs.${index}.paragraph`}
                                              control={control}
                                              render={({ field }) => (
                                                <TextField
                                                  {...field}
                                                  label={
                                                    <CustomLabel
                                                      required
                                                      label="Answer"
                                                    />
                                                  }
                                                  value={getValues(
                                                    `sections.${sectionIndex}.faqs.${index}.paragraph`
                                                  )}
                                                  placeholder="Enter Answer"
                                                  fullWidth
                                                  variant="outlined"
                                                  error={
                                                    !!errors.sections?.[
                                                      sectionIndex
                                                    ]?.faqs?.[index]?.paragraph
                                                  }
                                                  helperText={
                                                    errors.sections?.[
                                                      sectionIndex
                                                    ]?.faqs?.[index]?.paragraph
                                                      ?.message
                                                  }
                                                />
                                              )}
                                            />
                                          </Grid>
                                          <Grid item xs={12} md={3}>
                                            <IconButton
                                              color="primary"
                                              onClick={() =>
                                                addFaqData(sectionIndex)
                                              }
                                            >
                                              <AddCircleOutlineIcon />
                                            </IconButton>
                                            {section.faqs &&
                                              section.faqs.length > 1 && (
                                                <IconButton
                                                  color="error"
                                                  onClick={() =>
                                                    removeFaqData(
                                                      sectionIndex,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <RemoveCircleOutlineIcon />
                                                </IconButton>
                                              )}
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    ))}
                                </Box>
                              )}
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>

              <Box display={"flex"} gap={"10px"} justifyContent={"flex-end"}>
                <Box sx={{ mt: 4 }}>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleAddDropdown}
                  >
                    Add Dropdown Section
                  </Button>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={handleAddCheckMark}
                  >
                    Add Check Mark Section
                  </Button>
                </Box>
              </Box>

              <Box marginTop={2}>
                <Typography variant="h6" gutterBottom>
                  SEO
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} marginTop={1}>
                    <Controller
                      name="metaTitle"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={<CustomLabel required label="Meta Title" />}
                          fullWidth
                          placeholder="Enter Meta Title"
                          error={!!errors["metaTitle"]}
                          helperText={errors["metaTitle"]?.message}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} marginTop={1}>
                    <Controller
                      name="metaDescription"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={
                            <CustomLabel required label="Meta Description" />
                          }
                          fullWidth
                          placeholder="Enter Meta Description"
                          error={!!errors["metaDescription"]}
                          helperText={errors["metaDescription"]?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <div className="mt-4">
                <Button
                  variant="outlined"
                  onClick={handleDiscard}
                  disabled={!resourceAllocate("cms-alternative-comparison-content.write")}
                >
                  Discard
                </Button>
                <Button
                  type="submit"
                  className="ml-2"
                  disabled={
                    !(
                      resourceAllocate(
                        "cms-alternative-comparison-content.write"
                      ) && !saveDisabled
                    )
                  }
                >
                  Save Changes
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSubmitClick}
                  className="ml-2"
                  disabled={
                    !(
                      resourceAllocate(
                        "cms-alternative-comparison-content.write"
                      ) && !submitDisabled
                    )
                  }
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </Grid>
        {commentActiveAction && (
          <Grid item xs={4}>
            <AlternativeComment
              commentType="ALTERNATIVE_COMPARISON_CONTENT"
              activeAction={commentActiveAction}
              setActiveAction={setCommentActiveAction}
            />
          </Grid>
        )}
      </Grid>
      <Outlet />
    </div>
  );
};

export default AlternativeContent;
