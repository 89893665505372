import "./App.scss";
import "./assets/css/bootstrap/style.scss";
import { useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider, CircularProgress } from "@mui/material";
import { SnackbarKey } from "notistack";
import message from "./assets/json/message.json";
import routes from "./routes";
import useSnackbar from "./hooks/useSnackbar";
import { UserProvider } from "./hooks/useUser";
import { generateColorPalette } from "./utilities/helper";
import {IOrganisationConfigurationTheme } from "./interfaces/organisationConfiguration/theme";
import { useSelector } from "react-redux";
import DefaultLogo from "./assets/images/logo.png";

function App() {
  const { snackbar, closeSnackbar } = useSnackbar();
  const [snackbarId, setSnackbarId] = useState<SnackbarKey>();
  const [isThemeInitialized, setIsThemeInitialized] = useState(false);

  const OrganisationConfiguration = useSelector(
    (state: { themeConfiguration: { list: IOrganisationConfigurationTheme } }) =>
      state.themeConfiguration.list 
  );

  const [themeState, setThemeState] = useState<IOrganisationConfigurationTheme | null>(null);

  useEffect(() => {
    if (OrganisationConfiguration) {
      setThemeState(OrganisationConfiguration);
      setIsThemeInitialized(true);
    }
  }, [OrganisationConfiguration]);


  const defaultTheme = {
    primaryColor: "#118CFC",
    secondaryColor: "#118CFC",
    primaryBackgroundColor: "#fff",
    secondaryBackgroundColor: "#fff",
    fontFamily: "sans-serif",
    favicon: `${DefaultLogo}`,
    logo: `${DefaultLogo}`,
    metaTitle: "Admin Panel",
  };

  const activeTheme = isThemeInitialized ? themeState || defaultTheme : defaultTheme;
  const themeFonts = activeTheme.fontFamily;


  const theme = createTheme({
    typography: {
      fontFamily: themeFonts,
    },
    palette: {
      primary: generateColorPalette(
        activeTheme.primaryColor || "#ffffff"
      ),
      secondary: generateColorPalette(
        activeTheme.secondaryColor || "#ffffff"
      ),
      background: {
        default:
          activeTheme.secondaryBackgroundColor || "#fff",
        paper:
          activeTheme.primaryBackgroundColor || "#fff",
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          size: "small",
        },
        styleOverrides: {
          root: {
            width: "100%",
            fontFamily: themeFonts,
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
            fontFamily: themeFonts,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: "medium",
          variant: "contained",
        },
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
          },
        },
      },
      MuiAvatar:{
        styleOverrides:{
          root:{
            color:"white",
            backgroundColor: activeTheme.primaryColor 
          }
        }
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
          },
        },
      },
      MuiDialog: {
        defaultProps: {
          fullWidth: true,
        },
        styleOverrides: {
          paper: {
            backgroundColor: "white",
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            backgroundColor: "white",
            fontFamily: themeFonts,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: "bold",
            padding: "1rem",
            backgroundColor: "white",
            fontFamily: themeFonts,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: "0 1rem",
            backgroundColor: "white",
            fontFamily: themeFonts,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: "0 1rem 1rem 1rem",
            backgroundColor: "white",
            fontFamily: themeFonts,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
          },
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
            backgroundColor: "#fff",
          },
        }
      },
      MuiAccordionActions: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
            backgroundColor: "#fff",
          },
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
            backgroundColor: "#fff",
          },
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
          },
        }
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: "white",
          }
        }
      },
      
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
            backgroundColor: "#fff"
          },
        }
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
          },
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
          },
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
          },
        }
      },

      MuiTableBody: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
          },
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
          },
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily: themeFonts,
          },
        }
      },
      MuiDrawer: { styleOverrides: { paper: { backgroundColor: "white" } } }
    },
  });


  const dynamicTheme = {
    "--primary-color": activeTheme.primaryColor,
    "--secondary-color": activeTheme.secondaryColor,
    "--background-primary": activeTheme.primaryBackgroundColor,
    "--background-secondary": activeTheme.secondaryBackgroundColor,
    "--font-family": themeFonts,
  } as React.CSSProperties;

  const handleOnlineStatus = () => {
    if (!navigator.onLine) {
      const id = snackbar(message.EN.offline, "default", true);
      setSnackbarId(id);
    } else {
      closeSnackbar(snackbarId);
    }
  };

  useEffect(() => {
    if (themeState && themeState.favicon) {
      const favicon = document.getElementById("favicon");
      const metaTitle = document.getElementById("title");
      if (favicon) {
        favicon.setAttribute("href", `${process.env.REACT_APP_S3_BASE_URL}${themeState && themeState.favicon}`);
      }
      if (metaTitle) {
        document.title = themeState && themeState.metaTitle;
      }
    }
  }, [themeState && themeState.favicon, themeState && themeState.metaTitle]);

  useEffect(() => {
    if (!navigator.onLine) {
      snackbar(message.EN.offline, "default", true);
    }
    window.addEventListener("offline", handleOnlineStatus);
    window.addEventListener("online", handleOnlineStatus);
    return () => {
      window.removeEventListener("offline", handleOnlineStatus);
      window.removeEventListener("online", handleOnlineStatus);
    };
  }, []);

  if (!isThemeInitialized && OrganisationConfiguration) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={dynamicTheme}>
        <UserProvider>
          <RouterProvider router={routes} />
        </UserProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;