import Joi from "joi";
import { required } from "../../../shared";

const alternativeContentValidation = Joi.object({
  title: Joi.string()
    .required()
    .min(3)
    .max(60)
    .trim()
    .label("Title")
    .messages({
      "string.empty": required,
    }),
  description: Joi.string()
    .min(3)
    .trim()
    .required()
    .label("Description")
    .messages({
      "string.empty": required,
    }),
  buttonLink: Joi.string()
    .uri()
    .allow("")
    .label("Button Link"),
  buttonText: Joi.string()
    .label("Button Text")
    .allow(""),
  metaTitle: Joi.string()
    .min(3)
    .max(60)
    .trim()
    .required()
    .label("Meta Title")
    .messages({
      "string.empty": required,
    }),
  metaDescription: Joi.string()
    .min(3)
    .max(160)
    .trim()
    .required()
    .label("Meta Description")
    .messages({
      "string.empty": required,
    }),
  sections: Joi.array()
    .items(
      Joi.object({
        type: Joi.string()
          .valid("dropdowns", "checkMarks", "tableData", "cards", "faqs")
          .required()
          .label("Section Type")
          .messages({
            "string.empty": required,
          }),
          heading: Joi.when("type", {
            is: Joi.string().valid("dropdowns"),
            then: Joi.string()
              .required()
              .min(3)
              .max(60)
              .trim()
              .label("Heading")
              .messages({
                "string.empty": required,
              }),
            otherwise: Joi.when("type", {
              is: Joi.string().valid("checkMarks"),
              then: Joi.string()
                .required()
                .trim()
                .min(3)
                .max(60)
                .label("Heading")
                .messages({
                  "string.empty": required,
                }),
              otherwise: Joi.string().allow("").label("Heading"),
            }),
          }),
        paragraph: Joi.when("type", {
          is: Joi.string().valid("dropdowns"),
          then: Joi.string()
            .min(3)
            .trim()
            .required()
            .label("Paragraph")
            .messages({
              "string.empty": required,
            }),
          otherwise: Joi.string().allow("").label("Paragraph"),
        }),
        buttonText: Joi.when("type", {
          is: Joi.string().valid("checkMarks"),
          then: Joi.string()
            .required()
            .min(3)
            .max(60)
            .trim()
            .label("Button Text")
            .messages({
              "string.empty": required,
            }),
          otherwise: Joi.string().allow("").max(60).label("Button Text"),
        }),
        buttonLink: Joi
          .string()
          .allow("")
          .uri()
          .label("Button Link"),
        items: Joi.when("type", {
          is: "dropdowns",
          then: Joi.array()
            .min(1)
            .items(
              Joi.object({
                heading: Joi.string()
                  .required()
                  .min(3)
                  .max(60)
                  .trim()
                  .label("Dropdown Item Title")
                  .messages({
                    "string.empty": required,
                  }),
                paragraph: Joi.string()
                  .required()
                  .min(3)
                  .trim()
                  .label("Dropdown Item Description")
                  .messages({
                    "string.empty": required,
                  }),
              })
            )
            .required()
            .label("Dropdown Items")
            .messages({
              "array.min": required,
            }),
          otherwise: Joi.array().label("Dropdown Items"),
        }),
        points: Joi.when("type", {
          is: "checkMarks",
          then: Joi.array()
            .min(1)
            .items(
              Joi.string()
                .required()
                .min(3)
                .trim()
                .label("Checkmark Point")
                .messages({
                  "string.empty": required,
                })
            )
            .required()
            .label("Checkmark Points")
            .messages({
              "array.min": required,
            }),
          otherwise: Joi.array().label("Checkmark Points"),
        }),
        tableData: Joi.when("type", {
          is: "tableData",
          then: 
              Joi.object({
                heading: Joi.string()
                  .required()
                  .min(3)
                  .max(60)
                  .trim()
                  .label("Table Heading")
                  .messages({
                    "string.empty": required,
                  }),
                paragraph: Joi.string()
                  .required()
                  .min(3)
                  .trim()
                  .label("Table Sub Heading")
                  .messages({
                    "string.empty": required,
                  }),
                buttonLinkOne: Joi.string()
                  .allow("")
                  .uri()
                  .label("Button One Link"),
                buttonTextOne: Joi.string()
                  .allow("")
                  .max(60)
                  .label("Button One Text"),
                buttonLinkTwo: Joi.string()
                  .allow("")
                  .uri()
                  .label("Button Two Link"),
                buttonTextTwo: Joi.string()
                  .allow("")
                  .max(60)
                  .label("Button Two Text"),
                data: Joi.string()
                  .required()
                  .label("Table Data")
                  .messages({
                    "string.empty": required,
                  }),
              })
            .required()
            .label("Table Data")
            .messages({
              "any.required": required,
            }),
          otherwise: Joi.array().label("Table Data"),
        }),
        cards: Joi.when("type", {
          is: "cards",
          then: Joi.array()
            .min(1)
            .items(
              Joi.object({
                heading: Joi.string()
                  .required()
                  .min(3)
                  .max(60)
                  .trim()
                  .label("Card Title")
                  .messages({
                    "string.empty": required,
                  }),
                paragraph: Joi.string()
                  .required()
                  .min(3)
                  .trim()
                  .label("Card Description")
                  .messages({
                    "string.empty": required,
                  }),
                buttonLink: Joi.string()
                  .required()
                  .min(3)
                  .uri()
                  .trim()
                  .label("Card Button Link")
                  .messages({
                    "string.empty": required,
                  }),
                buttonText: Joi.string()
                  .allow("")
                  .max(60)
                  .label("Card Button Text"),
                image: Joi.string()
                  .required()
                  .label("Card Image")
                  .messages({
                    "string.empty": required,
                  }),
              })
            )
            .required()
            .label("Cards")
            .messages({
              "array.min": required,
            }),
          otherwise: Joi.array().label("Cards"),
        }),
        faqs: Joi.when("type", {
          is: "faqs",
          then: Joi.array()
            .min(1)
            .items(
              Joi.object({
                heading: Joi.string()
                  .required()
                  .min(3)
                  .trim()
                  .label("Question")
                  .messages({
                    "string.empty": required,
                  }),
                paragraph: Joi.string()
                  .required()
                  .min(3)
                  .trim()
                  .label("Answer")
                  .messages({
                    "string.empty": required,
                  }),
              })
            )
            .required()
            .label("FAQs")
            .messages({
              "array.min": required,
            }),
          otherwise: Joi.array().label("FAQs"),
        }),
      })
    )
    .required()
    .min(1)
    .label("Sections")
    .messages({
      "array.min": required,
    }),
});

export default alternativeContentValidation;
